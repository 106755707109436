import React, {useState} from "react"
// MUI
import {Box, Checkbox, FormControlLabel, Grid, Slider, Typography} from "@mui/material";
import {Article, FormatColorFill, HighlightAlt, Image} from "@mui/icons-material";
// UI
import {Accordion, ColorPicker, ColorPickerAlpha, DropzoneImage, PaperLabelled} from "@/components/UI";
// Data
import {useData} from "@/context/PackagingDataProvider";

export default function AppEditorDesignTab(props: any) {

    const {app, setApp, styles, t} = props
    const {handleDropImage} = useData()

    const [designAccordion, setDesignAccordion] = useState<number | boolean>(0)

    const handleAccordionFocus = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setDesignAccordion(isExpanded ? index : false)
    }

    const handleBackgroundImage = (dataDrop: any) => {
        handleDropImage(dataDrop).then((res: any) => {
            setApp({...app, layout: {
                ...app.layout, background: {
                        url: res.url, color: undefined

            }}})
        })
    }

    const handleBackgroundColor = (color: string, alpha: number) => {
        setApp({...app, layout: {
            ...app.layout, background: {
                url: undefined,
                color: color,
                alpha: alpha
        }}})
    }

    const handleLogo = (dataDrop: any) => {
        handleDropImage(dataDrop).then((res: any) => {
            setApp({...app, layout: {...app.layout, logo_url: res.url}})
        })
    }

    const handleButtonOptions = (button: any, key: any, color: any) => {
        let _button = app.layout.panel[button]
        _button = {..._button, [key]: color }
        setApp({ ...app, layout: { ...app.layout, panel: { ...app.layout.panel, [button] : _button }}})
    }

    if (!app?.layout) return <></>

    return (
        <Box sx={styles.paper}>
            <Accordion
                expanded={designAccordion === 0}
                onChange={handleAccordionFocus(0)}
                title={t("app_logo")}
                icon={<Image />}
            >
                <DropzoneImage onDrop={handleLogo} {...{t}} />
            </Accordion>

            <Accordion
                expanded={designAccordion === 1}
                onChange={handleAccordionFocus(1)}
                title={t("app_background")}
                icon={<FormatColorFill />}
            >
                <ColorPickerAlpha
                    buttonLabel={t("Color")}
                    buttonStyle={{textTransform: 'none', marginBottom: "16px"}}
                    buttonSize={'small'}
                    onChangeComplete={handleBackgroundColor}
                    color={app?.layout?.background?.color}
                    alpha={app?.layout?.background?.alpha}
                    {...{t}}
                />

                <DropzoneImage onDrop={handleBackgroundImage}  {...{t}} />
            </Accordion>

            <Accordion
                expanded={designAccordion === 2}
                onChange={handleAccordionFocus(2)}
                title={t("app_popup")}
                icon={<Article />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"} gutterBottom>
                            {t("box_shape")}
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <ColorPicker
                                    {...{t}}
                                    noMargin
                                    label={t("border_color")}
                                    color={app?.layout?.panel?.border_color}
                                    setColor={(val: any) => setApp({
                                        ...app, layout: {
                                            ...app.layout, panel: {
                                                ...app.layout.panel, border_color: val
                                            }}})}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography variant={"body1"} fontWeight={500}>{t("border_radius")}</Typography>
                                <Slider
                                    valueLabelDisplay={"auto"}
                                    value={app?.layout?.panel?.rounded || 0}
                                    step={1}
                                    min={0}
                                    max={25}
                                    defaultValue={5}
                                    onChange={(e: any) => { setApp({
                                        ...app, layout: {
                                            ...app.layout, panel: {
                                                ...app.layout.panel, rounded: e.target.value
                                            }
                                        }
                                    })}}
                                />
                            </Grid>
                        </Grid>


                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"}>
                            {t("box_content")}
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <ColorPicker
                                    {...{t}}
                                    label={t("background_color")}
                                    color={app?.layout?.panel?.background_color}
                                    setColor={(val: any) => setApp({
                                        ...app, layout: {
                                            ...app.layout, panel: {
                                                ...app.layout.panel, background_color: val
                                            }}})}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <ColorPicker
                                    {...{t}}
                                    label={t("text_color")}
                                    color={app?.layout?.panel?.text_color}
                                    setColor={(val: any) => setApp({
                                        ...app, layout: {
                                            ...app.layout, panel: {
                                                ...app.layout.panel, text_color: val
                                            }}})}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Accordion>

            <Accordion
                expanded={designAccordion === 3}
                onChange={handleAccordionFocus(3)}
                title={t("app_buttons")}
                icon={<HighlightAlt />}
            >
                <Typography variant={"h6"} component={"p"} color={"secondary"}>
                    {t("buttons_color")}
                </Typography>

                {["button_primary", "button_secondary"].map((button: any, index: number) => (
                    <PaperLabelled label={t(button)} sx={{ mt: 2 }} key={index}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <ColorPicker
                                    {...{t}}
                                    noMargin
                                    label={t("color")}
                                    color={app?.layout?.panel?.[button]?.color}
                                    setColor={(val: any) => handleButtonOptions(button, "color", val)}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <ColorPicker
                                    {...{t}}
                                    noMargin
                                    label={t("text_color")}
                                    color={app?.layout?.panel?.[button]?.text_color}
                                    setColor={(val: any) => handleButtonOptions(button, "text_color", val)}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant={"body1"} fontWeight={500}>{t("border_radius")}</Typography>
                                <Slider
                                    valueLabelDisplay={"auto"}
                                    value={app?.layout?.panel?.[button]?.rounded || 0}
                                    step={1} min={0} max={5}
                                    onChange={(e: any) => { handleButtonOptions(button, "rounded", e.target.value)} }
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={!!app?.layout?.panel?.[button]?.outline}
                                        onChange={() => handleButtonOptions(button, "outline", !app?.layout?.panel?.[button]?.outline)}
                                    />}
                                    label={t("outlined")}
                                    sx={{ mt: 2, ml: 2 }}
                                />
                            </Grid>
                        </Grid>
                    </PaperLabelled>
                ))}
            </Accordion>
        </Box>
    )

}