import React, {useState, useEffect} from "react"
import {TextField} from "@mui/material"
import {Autocomplete} from "@mui/material";
import {langsEN} from "@/utils";

export default function LanguageSelect(props: any) {

    const {language, setLanguage, t} = props

    const [languageCopy, setLanguageCopy] = useState(null)

    useEffect(() => {
        if (!!language) setLanguageCopy(language)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    return (
        <Autocomplete
            id="combo-lang"
            size={"small"}
            value={languageCopy}
            options={langsEN.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))}
            onChange={(event: any, newValue: any | null) => {
                if (!!newValue) setLanguage(newValue);
                else setLanguage({code: '', name: ''});
            }}
            getOptionLabel={(option) => option.name || ""}
            renderInput={(params) => (
                <TextField {...params} label={`${t("Language")}`} variant="outlined"/>
            )}
        />
    )
}