import {createTheme} from '@mui/material/styles';
// in the file where you are creating the theme (invoking the function `createTheme()`)
import { Theme } from '@mui/material/styles';

export const ThemeUnitag: Theme = createTheme({
    palette: {
        primary: {
            main: "#b4c74a",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#6192bd",
            contrastText: "#ffffff",
        },
        text: {
            secondary: "#525252",
        }
    },
    typography: {
        h1: {
            fontFamily: 'Roboto',
            fontSize: 48,
            lineHeight: "58px",
            letterSpacing: "0.00938em",
            fontWeight: 900,
        },
        h2: {
            fontSize: 24,
            lineHeight: '34px',
            fontWeight: 400,
        },
        h5: {
            fontWeight: '500',
        }
    },
});
