import {countryCodeToNameDict} from "@/utils/country_codes_en";
import {languageCodeToNameDict} from "@/utils/languages_codes_en";

function countryCodeToName(code: string) {
    return countryCodeToNameDict[code]
}

function languageCodeToName(code: string) {
    return languageCodeToNameDict[code]
}

export {countryCodeToName, languageCodeToName}