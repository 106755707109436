import React, {useEffect, useState} from "react"
// MUI
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
// Data
import {useData} from "@/context/PackagingDataProvider";
// UI
import {BrandMultipleSelect} from "@/components/UI";


export default function AssociatedBrandsDialog(props: any) {

    const {open, onClose, appUuid, t} = props
    const {brands, getBrands, getSingleApp, editAny} = useData()

    const [app, setApp] = useState<any>(null)

    useEffect(() => {
        if (open && !brands) getBrands()
        if (open && !!appUuid) getSingleApp(appUuid).then((res: any) => { setApp(res) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const handleSave = () => {
        editAny(app, "app").then((res: any) => {if (res) onClose()})
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>{t("manage_associated_brands")}</Box>
                    <IconButton onClick={onClose} aria-label={"close"}><Close /></IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <BrandMultipleSelect{...{app, setApp, brands, t}} />
            </DialogContent>
            <DialogActions>
                <Box sx={{ pb: 3, px: 3 }}>
                    <Button variant={"text"} className={"defaultButton"} onClick={onClose}>
                        {t("common:back")}
                    </Button>
                    &nbsp;
                    <Button variant={"contained"} disableElevation onClick={handleSave}>
                        {t("common:save")}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}