import React, {useEffect, useState} from "react"
// MUI
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField
} from "@mui/material";
import {Close, Save} from "@mui/icons-material";
// Ui
import {LanguageSelect} from "@/components/UI";
// Misc
import {languageCodeToName} from "@/utils";

export default function AppEditorTranslationsDialog(props: any) {

    const {
        open, onClose,
        app, setApp,
        translation,
        t
    } = props

    const [localTranslation, setLocalTranslation] = useState<any>(null)

    useEffect(() => {
        if (!!translation) setLocalTranslation({
            ...translation,
            language: {
                code: translation.language,
                name: languageCodeToName(translation.language)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const handleSave = () => {
        if (!app) return
        let appTranslations = app.translations
        // Check if translation is already there
        let previousTranslation = appTranslations.filter((trans: any) => (!!trans.id && trans.id === localTranslation.id) || trans.language === localTranslation.language)

        if (previousTranslation[0]) {
            appTranslations.splice(appTranslations.findIndex((trans: any) => trans.id === localTranslation.id || trans.language === localTranslation.language) ,1)
            appTranslations.push({
                ...localTranslation,
                language: localTranslation.language.code
            })
            setApp({...app, translations: appTranslations})
        } else {
            appTranslations.push({
                ...localTranslation,
                language: localTranslation.language.code
            })
            setApp({...app, translations: appTranslations})
        }
        onClose()
    }

    // Display
    const renderTranslationForm = () => {
        return (
            <>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t("translation_title")}
                        value={localTranslation?.content.title}
                        onChange={(e) => setLocalTranslation({...localTranslation, content: {
                            ...localTranslation.content, title: e.target.value
                        }})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t("translation_disclaimer")}
                        fullWidth
                        value={localTranslation?.content.disclaimer}
                        onChange={(e) => setLocalTranslation({...localTranslation, content: {
                                ...localTranslation.content, disclaimer: e.target.value
                        }})}
                        multiline
                        rows={5}
                    />

                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t("translation_accept")}
                        fullWidth
                        value={localTranslation?.content.accept}
                        onChange={(e) => setLocalTranslation({...localTranslation, content: {
                                ...localTranslation.content, accept: e.target.value
                        }})}
                    />

                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t("translation_refuse")}
                        fullWidth
                        value={localTranslation?.content.refuse}
                        onChange={(e) => setLocalTranslation({...localTranslation, content: {
                                ...localTranslation.content, refuse: e.target.value
                        }})}
                    />

                </Grid>
                <Grid item xs={12}>
                    <LanguageSelect
                        language={localTranslation?.language}
                        setLanguage={(val: any) => setLocalTranslation({...localTranslation, language: val})}
                        {...{t}}
                    />
                </Grid>
            </>
        )
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                            {t("edit_translation")}
                        </Box>
                        <IconButton onClick={onClose} aria-label={"close"}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container justifyContent={"center"} spacing={2} sx={{ my: 1 }}>
                        {renderTranslationForm()}
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Box>
                        <Button
                            variant={"text"}
                            className={"defaultButton"}
                            onClick={onClose}
                            sx={{ mr: 1 }}
                        >
                            {t("common:back")}
                        </Button>
                        <Button
                            startIcon={<Save />}
                            variant={"contained"}
                            disableElevation
                            onClick={() => handleSave()}
                        >
                            {t("common:save")}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )

}