import React, {useEffect, useState} from "react"
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Grid,
    Tooltip, Paper, TextField, Stack
} from "@mui/material";
import {Add, Check, Close, Delete, Edit} from "@mui/icons-material";
// data
import {useData} from "@/context/PackagingDataProvider";
// utils
import ReactCountryFlag from "react-country-flag";
import {countryCodeToName, languageCodeToName, regexValidUrl} from "@/utils";
// Primereact
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
// Components
import {CountrySelect, LanguageSelect, PaperLabelled} from "@/components/UI";

export default function ProductUrlDialog(props: any) {

    const {open, onClose, product, t} = props
    const {getSingleProductUrls, createAny, editAny, deleteAny} = useData()

    const styles = {
        actionWrapper: { my: 2 },
        flag: { marginTop: "-5px", marginRight: "10px" }
    }

    const [urls, setUrls] = useState<any>([])
    const [shortUrl, setShortURl] = useState<any>(null)
    // CRUD
    const [creation, setCreation] = useState<any>(null)
    const [selection, setSelection] = useState<any>(null)
    const [deletion, setDeletion] = useState<any>(null)
    // Pagination
    const [page, setPage] = useState<any>({first: 0, rows: 5, currentPage: 1,});

    useEffect(() => {
        setUrls([])
        if (!!product) updateUrls()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product])

    const updateUrls = () => getSingleProductUrls(product.id).then((res: any) => {
        if (!!res) {
            setUrls(!!res.records ? res.records : null)
            setShortURl(res.product_short_url)
        }
    })

    // CRUD

    const handleCreateUrl = async () => {
        if (!creation) return null
        await createAny(creation, "url")
        setCreation(null)
        setUrls([])
        updateUrls()
    }

    const handleDeleteUrl = async () => {
        if (!deletion) return null
        await deleteAny({ id: deletion.id, destination_url: deletion.destination_url}, "filter")
        setDeletion(null)
        setUrls([])
        updateUrls()
    }

    const handleEditUrl = async (uuid: any) => {
        if (!uuid) return null
        await editAny(selection, `url/${uuid}`)
        setSelection(null)
        setUrls([])
        updateUrls()
    }

    // Display
    const renderActionSection = () => {


        const initUrlCreation = () => {
            setCreation({
                destination_url: "",
                product_id: product.id,
                country: "",
                language: ""
            })
        }

        const renderNewUrlBlock = () => {
            if (!creation) return <></>
            return (
                <PaperLabelled label={t("new_redirection")} background={"#efefef"} bold sx={{ my: 2 }}>
                    <Grid container justifyContent={{ xs: "center", md: "space-between"}} spacing={2}>
                        <Grid item xs={12} sm={4} md={3}>
                            <CountrySelect
                                country={creation?.country}
                                setCountry={(val: any) => setCreation({...creation, country: val})}
                                {...{t}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <LanguageSelect
                                language={creation?.language}
                                setLanguage={(val: any) => setCreation({...creation, language: val})}
                                {...{t}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <TextField
                                value={creation?.destination_url}
                                onChange={(e) => setCreation({...creation, destination_url: e.target.value})}
                                fullWidth size={"small"}
                                label={t("destination_url")}
                                error={!creation.destination_url || !regexValidUrl(creation.destination_url)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <Stack direction={"row"} justifyContent={{ xs: "center", md: "flex-end"}}>
                                <div>
                                    <Tooltip title={t("common:cancel")} placement={"top"} arrow>
                                        <IconButton onClick={() => setCreation(null)}>
                                            <Close />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div>
                                    <Tooltip title={t("common:create")} placement={"top"} arrow>
                                        <span>
                                            <IconButton
                                                color={"success"}
                                                onClick={() => handleCreateUrl()}
                                                disabled={!creation.destination_url || !regexValidUrl(creation.destination_url) || (!creation.language && !creation.country)}
                                            >
                                            <Check />
                                        </IconButton>
                                        </span>
                                    </Tooltip>
                                </div>
                            </Stack>
                        </Grid>
                    </Grid>
                </PaperLabelled>
            )
        }

        return (
            <>
                <Box sx={styles.actionWrapper}>
                    <Button onClick={() => initUrlCreation()} variant={"contained"} disableElevation startIcon={<Add />}>
                        {t("create_redirection_url")}
                    </Button>
                </Box>
                {renderNewUrlBlock()}
            </>
        )
    }

    const renderUrlsDataTable = () => {

        // Pagination
        const onPageChange = (e: any) => {
            setPage({
                first: e.first,
                rows: e.rows,
                currentPage: e.page + 1
            });
        }

        const countryCodeBodyTemplate = (rowData: any) => {

            if (selection && selection.id === rowData.id) {
                return (
                    <CountrySelect
                        country={selection.country}
                        setCountry={(val: any) => setSelection({...selection, country: val})}
                        {...{t}}
                    />
                )
            }

            return (
                <Typography>
                    <span>
                      <ReactCountryFlag style={styles.flag} countryCode={rowData.country_code} svg />
                    </span>
                    {countryCodeToName(rowData.country_code)}
                </Typography>
            );
        }

        const languageCodeBodyTemplate = (rowData: any) => {
            if (selection && selection.id === rowData.id) {
                return (
                    <LanguageSelect
                        language={selection?.language}
                        setLanguage={(val: any) => setSelection({...selection, language: val})}
                        {...{t}}
                    />
                )
            }

            return <Typography>{languageCodeToName(rowData.language_code)}</Typography>
        }

        const defaultUrlBodyTemplate = (rowData: any) => {
            if (selection && selection.id === rowData.id) {
                return (
                    <TextField
                        value={selection?.destination_url}
                        onChange={(e) => setSelection({...selection, destination_url: e.target.value})}
                        fullWidth size={"small"}
                        label={t("destination_url")}
                        error={!selection.destination_url || !regexValidUrl(selection.destination_url)}
                    />
                )
            }
            return rowData.destination_url
        }

        const actionBodyTemplate = (rowData: any) => {

            const handleSelection = (rowData: any) => {
                setSelection({
                    ...rowData,
                    country: {
                        label: countryCodeToName(rowData.country_code),
                        code: rowData.country_code
                    },
                    language: {
                        name: languageCodeToName(rowData.language_code),
                        code: rowData.language_code
                    }
                })
            }

            if (deletion?.id === rowData.id) {
                return (
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item>
                            <Tooltip title={t("common:back")} placement={"top"} arrow>
                                <IconButton aria-label={"back"} onClick={() => setDeletion(null)}>
                                    <Close />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={t("common:delete")} placement={"top"} arrow>
                                <IconButton color={"error"} aria-label={"delete"} onClick={() => handleDeleteUrl()}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )
            }

            if (selection?.id === rowData.id) {
                return (
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item>
                            <Tooltip title={t("common:back")} placement={"top"} arrow>
                                <IconButton aria-label={"back"} onClick={() => setSelection(null)}>
                                    <Close />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={t("common:save")} placement={"top"} arrow>
                                <span>
                                    <IconButton
                                        color={"success"}
                                        aria-label={"save"}
                                        onClick={() => handleEditUrl(rowData.id)}
                                        disabled={!selection.destination_url || !regexValidUrl(selection.destination_url) || (!selection.language && !selection.country)}
                                    >
                                    <Check />
                                </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )
            }

            return (
                <Grid container justifyContent={"flex-end"}>
                    <Grid item>
                        <Tooltip title={t("common:edit")} placement={"top"} arrow>
                            <IconButton aria-label={"edit"} onClick={() => handleSelection(rowData)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={t("common:delete")} placement={"top"} arrow>
                            <IconButton aria-label={"delete"} onClick={() => setDeletion(rowData)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            )
        }

        return (
            <>
                <Paper variant={"outlined"} sx={{ borderRadius: 5, overflow: "hidden" }}>
                    <DataTable
                        style={{ borderRadius: "inherit!important" }}
                        dataKey={'id'}
                        paginator
                        rowHover
                        emptyMessage={t("no_elements_found")}
                        first={page.first}
                        rows={page.rows}
                        sortOrder={-1}
                        onPage={onPageChange}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                        value={urls}
                    >
                        <Column field={"country_code"} header={t("country")} body={countryCodeBodyTemplate}/>
                        <Column field={"language_code"} header={t("language")} body={languageCodeBodyTemplate}/>
                        <Column field={"destination_url"} header={t("destination_url")} body={defaultUrlBodyTemplate} />
                        <Column header={t("actions")} body={actionBodyTemplate} className={"p-justify-header-right"}/>
                    </DataTable>
                </Paper>
            </>
        )
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>{t("product_redirection_urls")}</Box>
                    <IconButton onClick={onClose} aria-label={"close"}><Close /></IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <Typography variant={"body2"}>{t("redirection_urls_intro")}</Typography>
                {renderActionSection()}

                <Paper variant={"outlined"} sx={{ p: 2, mb: 2, bgcolor: "#efefef" }}>
                    <Typography variant={"body2"}>
                        {t("products:short_url")}: <b>{!!shortUrl && shortUrl}</b>
                    </Typography>
                </Paper>

                {renderUrlsDataTable()}
            </DialogContent>

            <DialogActions>
                <Box sx={{ px: 3, pb: 3 }}>
                    <Button variant={"text"} size={"small"} className={"defaultButton"} onClick={onClose}>
                        {t("common:back")}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}