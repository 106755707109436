function hexToRgb(hex: string | undefined, alpha?: number) {
    if (hex !== undefined) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        if (result) {
            let r = parseInt(result[1], 16);
            let g = parseInt(result[2], 16);
            let b = parseInt(result[3], 16);
            let a = alpha ? alpha : 1
            return {
                r, g, b, a,
                rgba: `rgba(${r}, ${g}, ${b}, ${a})`
            };
        }
    }
    return {
        r: 0,
        g: 0,
        b: 0,
        a: 0
    };
}

function contrastColor(color: string) {

    if (!color) return "#fff"

    function luma(color: string) {
        let rgb = hexToRGBArray(color);
        return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]);
    }

    function hexToRGBArray(color: string) {
        if (color.length === 3)
            color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
        else if (color.length === 7) {
            color = color.charAt(1) + color.charAt(2) + color.charAt(3) + color.charAt(4) + color.charAt(5) + color.charAt(6);
        }

        let rgb = [];
        for (let i = 0; i <= 2; i++)
            rgb[i] = parseInt(color.substr(i * 2, 2), 16);
        return rgb;
    }

    return (luma(color) >= 165) ? '#000000' : '#ffffff';
}

function rgbToHex(r: number, g: number, b: number) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export {hexToRgb, contrastColor, rgbToHex}