function regexValidUrl(text: any) {
    if (text && text !== "") {
        // eslint-disable-next-line no-control-regex
        // let reg = new RegExp('^(http|https)://')
        // return reg.test(text)
        return text.startsWith("http://") || text.startsWith("https://")
    }
    return true
}

function isValidSimpleAlphanumeric(input: any) {
    let regex = /^[a-zA-Z0-9_/-]*$/i;
    return regex.test(input);
}

export {regexValidUrl, isValidSimpleAlphanumeric}