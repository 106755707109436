import React, {useEffect} from "react"
// mui
import {
    Alert,
    Box,
    Divider,
    Grid,
    Typography
} from "@mui/material";
// translation
import {useTranslation} from "react-i18next";
// data
import {useData} from "@/context/PackagingDataProvider";

export default function DownloadTab() {

    const {t} = useTranslation(["download", "common"])
    const {brands, ranges, getBrands, getRanges} = useData()

    // const [submitted, setSubmitted] = useState<boolean>(false)
    // const [filters, setFilters] = useState<any>({
    //     brand: "",
    //     range: ""
    // })
    // const [file, setFile] = useState<any>({
    //     format: "png",
    //     unit: "px",
    //     size: "300",
    //     resolution: "75"
    // })

    // let selectedBrand = brands.filter((brand: any) => brand.id === filters.brand)
    // let selectedRange = ranges.filter((range: any) => range.id === filters.range)

    useEffect(() => {
        if (!brands) getBrands()
        if (!ranges) getRanges()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const isCorrectlySized =
    //     (file.unit === "px" && (Number(file.size) >= 100 && Number(file.size) <= 2500)) ||
    //     (file.unit === "mm" && file.size >= 30 && (
    //         (file.resolution === "75" && Number(file.size) <= 847) || (file.resolution === "300" && Number(file.size) <= 212)
    //     ))
    //
    // // Download
    // const download = () => {
    //     const basePxRatio = 0.03937008;
    //     let convertedFileSize
    //
    //     if (file.unit === "mm") {
    //         convertedFileSize = Math.round(Number(file.size) * (basePxRatio * Number(file.resolution)))
    //     } else convertedFileSize = file.size
    // }

    // Sections (uncomment)
    // const renderBrandsRangesSelection = () => {
    //     return (
    //         <>
    //             <Collapse in={!submitted} sx={{ pt: "4px" }}>
    //                 <Grid container spacing={2}>
    //                     <Grid item xs={12}>
    //                         <BrandRangeSelect
    //                             small
    //                             label={t("common:brand")}
    //                             type={"brand"}
    //                             data={brands}
    //                             value={filters.brand}
    //                             setValue={(e: any) => setFilters({...filters, brand: e.target.value})}
    //                             error={!filters.brand}
    //                             {...{t}}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={12}>
    //                         <BrandRangeSelect
    //                             small
    //                             label={t("common:range")}
    //                             type={"range"}
    //                             data={ranges}
    //                             value={filters.range}
    //                             setValue={(e: any) => setFilters({...filters, range: e.target.value})}
    //                             error={!filters.range}
    //                             {...{t}}
    //                         />
    //                     </Grid>
    //                     <Grid item xs={"auto"}>
    //                         <Button
    //                             variant={"contained"}
    //                             size={"small"}
    //                             disableElevation
    //                             onClick={() => setSubmitted(true)}
    //                             disabled={!filters.brand || !filters.range}
    //                         >
    //                             {t("validate_filters")}
    //                         </Button>
    //                     </Grid>
    //                 </Grid>
    //             </Collapse>
    //             <Collapse in={submitted} >
    //                 <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
    //                     <Typography sx={{ mt: "5px" }}>
    //                         {t("download_from")}&nbsp;
    //                         {selectedBrand.length > 0 && selectedBrand[0].name}&nbsp;
    //                         {t("and_range")}&nbsp;
    //                         {selectedRange.length > 0 && selectedRange[0].name}
    //                     </Typography>
    //                     <Button onClick={() => setSubmitted(false)} startIcon={<Refresh />}>{t("change_filters")}</Button>
    //                 </Stack>
    //             </Collapse>
    //         </>
    //     )
    // }
    //
    // const renderFileFormatSelection = () => {
    //
    //     const renderTypeSelection = () => {
    //         return (
    //             <FormControl fullWidth>
    //                 <InputLabel id={"file-format"}>{t("file_format")}</InputLabel>
    //                 <Select
    //                     label={t("file_format")}
    //                     labelId={"file-format"}
    //                     value={file.format}
    //                     onChange={(e) => setFile({...file, format: e.target.value})}
    //                 >
    //                     <MenuItem value={"png"}>PNG</MenuItem>
    //                     <MenuItem value={"jpeg"}>JPEG</MenuItem>
    //                     <MenuItem value={"svg"}>SVG</MenuItem>
    //                     <MenuItem value={"pdf"}>PDF</MenuItem>
    //                 </Select>
    //             </FormControl>
    //         )
    //     }
    //
    //     const renderUnitSelection = () => {
    //         return (
    //             <FormControl fullWidth>
    //                 <InputLabel id={"file-unit"}>{t("file_unit")}</InputLabel>
    //                 <Select
    //                     label={t("file_unit")}
    //                     labelId={"file-unit"}
    //                     value={file.unit}
    //                     onChange={(e) => setFile({...file, unit: e.target.value})}
    //                 >
    //                     <MenuItem value={"px"}>Pixels</MenuItem>
    //                     <MenuItem value={"mm"}>Millimeters</MenuItem>
    //                 </Select>
    //             </FormControl>
    //         )
    //     }
    //
    //     const renderSizeSelection = () => {
    //         return (
    //             <>
    //                 <TextField
    //                     label={t("file_size")}
    //                     value={file.size}
    //                     onChange={(e) => setFile({...file, size: e.target.value})}
    //                     fullWidth
    //                     type={"number"}
    //                     error={!isCorrectlySized}
    //                     InputProps={{
    //                         endAdornment: <Typography variant={"body2"}>{file.unit}</Typography>,
    //                         inputProps: {
    //                             max:
    //                                 file.unit === "px" ? 2500 :
    //                                 (file.unit === "mm" && file.resolution === "300") ? 212 :
    //                                 847,
    //                             min: file.unit === "px" ? 100 : 30,
    //                         }
    //                     }}
    //                     helperText={
    //                         file.unit === "px" ? t("file_size_alert_px") :
    //                         (file.unit === "mm" && file.resolution === "75") ? t("file_size_alert_75") :
    //                         t("file_size_alert_300")
    //                     }
    //                 />
    //
    //                 <Collapse in={file.unit === "mm"} sx={{ pt: 2 }}>
    //                     <Stack direction={"row"} spacing={2}>
    //                         <Typography>{t("resolution")}</Typography>
    //                         {
    //                             ["75", "300"].map((resolution: any) => (
    //                                 <Button
    //                                     variant={file.resolution === resolution ? "contained" : "outlined"}
    //                                     size={"small"}
    //                                     onClick={() => setFile({...file, resolution: resolution})}
    //                                     disableElevation
    //                                     key={resolution}
    //                                 >
    //                                     {resolution} ppi
    //                                 </Button>
    //                             ))
    //                         }
    //                     </Stack>
    //                 </Collapse>
    //             </>
    //         )
    //     }
    //
    //     return (
    //         <Collapse in={submitted} sx={{ w: "100%" }}>
    //             <PaperLabelled label={t("file_size_and_format")}>
    //                 <Grid container spacing={2} sx={{ pt: "4px" }}>
    //                     <Grid item xs={12} md={7}>{renderTypeSelection()}</Grid>
    //                     <Grid item xs={12} md={7}>{renderUnitSelection()}</Grid>
    //                     <Grid item xs={12} md={7}>{renderSizeSelection()}</Grid>
    //                     <Grid item xs={12}><Divider /></Grid>
    //                     <Grid item xs={12}>
    //                         <Button
    //                             size={"large"}
    //                             disableElevation
    //                             variant={"contained"}
    //                             disabled={!isCorrectlySized}
    //                             fullWidth
    //                             startIcon={<Download />}
    //                             onClick={() => download()}
    //                         >
    //                             {t("download_my_qrcodes")}
    //                         </Button>
    //                     </Grid>
    //                 </Grid>
    //             </PaperLabelled>
    //         </Collapse>
    //     )
    // }

    return (
        <>
            <Box sx={{ p: 2 }}>
                <Grid container justifyContent={{ xs: "flex-start" }} rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"h4"} component={"p"}>
                            {t("download_qrcodes")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Typography sx={{ py: 2 }}>
                            {t("download_intro")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Alert severity={"info"}>
                            {t("coming_soon")}
                        </Alert>
                    </Grid>

                    {/*<Grid item xs={12} md={6}>*/}
                    {/*    <PaperLabelled label={t("download_chose_filters")} background={submitted ? "rgba(175,201,40,0.2)" : "#fff"}>*/}
                    {/*        {renderBrandsRangesSelection()}*/}
                    {/*    </PaperLabelled>*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sx={{ pt: "0!important" }} />*/}

                    {/*<Grid item xs={12} md={6}>*/}
                    {/*    {renderFileFormatSelection()}*/}
                    {/*</Grid>*/}
                </Grid>
            </Box>
        </>
    )
}