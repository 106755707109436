import React, {useState, useEffect} from "react";
// MUI
import {Alert, Box, Button, Collapse, Divider, Grid, Stack, TextField, Typography} from "@mui/material";
import {Add, CenterFocusStrong, Check, OpenInFull} from "@mui/icons-material";
// Components
import {PaperLabelled} from "@/components/UI";
import {useData} from "@/context/PackagingDataProvider";
import {Rnd} from "react-rnd";
import cloneDeep from "lodash.clonedeep"

const DraggableLogo = (props: any) => {

    const {
        onDragStart,
        catchPosition,
        handleResize,
        previewLogo,
        logo
    } = props;

    return (
        <React.Fragment>
            <Rnd
                bounds="parent"
                onDragStart={onDragStart}
                onDragStop={catchPosition}
                position={{ x: logo.x, y: logo.y }}
                lockAspectRatio
                onResizeStop={handleResize}
                size={{width: logo.width, height: 'auto'}}
                enableResizing={{
                    top: false,
                    right: false,
                    bottom:false,
                    left:false,
                    topRight:false,
                    bottomRight:true, // here
                    bottomLeft:false,
                    topLeft:false
                }}
                resizeHandleComponent={{ bottomRight: <OpenInFull sx={{ transform: "scaleX(-1)" }} /> }}
                minWidth={'25px'}
            >
                <img
                    draggable={false}
                    alt="preview-logoSrc"
                    src={previewLogo}
                    style={{
                        width: "100%",
                        height: "auto",
                        border: "1px solid black"
                    }}
                />
            </Rnd>
        </React.Fragment>
    );
}


const PreviewSection = (props: any) => {

    const {
        gs1,
        usedModule,
        qrcode, setQRCode,
        onCreate, canCreateQRCode,
        logo, setLogo,
        preview, previewWarning,
        t
    } = props
    const {getWIPQRCodePreview} = useData()

    const [previewLogo, setPreviewLogo] = useState<any>(null)
    const [previewClear, setPreviewClear] = useState<any>(preview)

    useEffect(() => {
        if (logo.replacing) {
            if (logo.file !== null) {
                setPreviewLogo(URL.createObjectURL(logo.file));
                fetchPreviewWithoutLogo().then();
            } else {
                setPreviewLogo(qrcode.settings.logo.url);
                fetchPreviewWithoutLogo().then();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const fetchPreviewWithoutLogo = async () => {
        let _qrcode = cloneDeep(qrcode)

        _qrcode.settings.logo = {
            excavate: false,
            x: 0,
            y: 0,
            x_norm: 0,
            y_norm: 0,
            width: 0,
            url: ''
        };

        _qrcode.settings.redundancy = "H";

        await getWIPQRCodePreview(_qrcode, "").then((res: any) => {
            if (!!res) setPreviewClear(res.preview)
        })
    }

    // Logo position methods
    const onValidateResize = () => {

        let _ratio = (!!logo.ogHeight && !!logo.ogWidth) ? logo.ogHeight / logo.ogWidth : logo.height / logo.width

        let _qrcode = {...qrcode};
        _qrcode.settings.logo.x_norm = logo.coordX;
        _qrcode.settings.logo.y_norm = logo.coordY;
        _qrcode.settings.logo.width = logo.width;
        _qrcode.settings.logo.height = logo.width * (_ratio)
        setQRCode(_qrcode);
        setLogo({...logo, replacing: false})
    }

    const onCancelResize = () => {
        setLogo({...logo, x: logo.startX, y: logo.startY, replacing: false})
    }

    const onDragStart = (e: any, ui: any) => {
        setLogo({...logo, x: ui.x, y: ui.y});
    }

    const catchPosition = (e: any, ui: any) => {
        if (e.target.offsetParent === null || e.target.offsetParent.offsetWidth === null) {
            return;
        }

        let xAdjusted = (300 - logo.width) / 2
        let yAdjusted = (300 - e.target.offsetHeight) / 2;
        const QRCodePreviewSize = 300;

        let ratio =  e.target.offsetParent.offsetParent.offsetWidth / QRCodePreviewSize,
            x = Math.round((ui.x - xAdjusted) / ratio),
            y = Math.round((ui.y - yAdjusted) / ratio);

        setLogo({
            ...logo,
            coordX: x,
            coordY: y,
            x: ui.x,
            y: ui.y,
            ratio: ratio
        })
    }

    const handleResize = (e: any, direction: any, ref: any) => {
        setLogo({...logo, width: ref.offsetWidth, height: ref.offsetHeight})
    }

    const handleCenter = () => {
        let x = (300 - logo.width) / 2;
        let y = (300 - logo.height) / 2;
        setLogo({...logo, x: x, y: y, coordX: 0, coordY: 0});
        let _qrcode = {...qrcode};
        _qrcode.settings.logo.x_norm = 0;
        _qrcode.settings.logo.y_norm = 0;
        setQRCode(_qrcode);
    }

    // Render
    const renderPreview = () => {
        return (
            <>
                <Grid item xs={12} sm={8} md={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Stack spacing={2}>
                        <Typography align={"center"}>
                            {usedModule === "GS1" ? t("products:qrcode_gs1_design_warning") : ""}
                        </Typography>
                        <Collapse in={previewWarning}>
                            <Alert severity={"warning"}>
                                {t("products:contrast_warning")}
                            </Alert>
                        </Collapse>
                        <TextField
                            value={qrcode.data.label || ""}
                            onChange={(e) => setQRCode({...qrcode, data: { ...qrcode.data, label: e.target.value}})}
                            label={t("products:qrcode_name")}
                            required
                            fullWidth
                            error={!qrcode.data.label}
                            sx={{ minWidth: 230 }}
                        />
                        <Divider />
                        <Button
                            onClick={onCreate}
                            variant={"contained"}
                            disableElevation
                            size={"large"}
                            fullWidth
                            startIcon={<Add />}
                            disabled={!canCreateQRCode}
                        >
                            {t("products:create_qrcode")}
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{ textAlign: "center" }}>
                        <img
                            src={preview}
                            height={300}
                            width={300}
                            alt={"qrcode-preview"}
                            unselectable={"on"}
                        />
                    </div>
                </Grid>
            </>
        )
    }

    const renderLogoReplacer = () => {
        return (
            <>
                <Grid item xs={12} sm={8} md={6} sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Stack spacing={2}>
                        <Alert severity={"info"}>
                            {t("products:logo_placement_info")}
                        </Alert>
                        <Button
                            onClick={() => handleCenter()}
                            variant={"contained"}
                            color={"secondary"}
                            disableElevation
                            size={"small"}
                            sx={{ px: 10, maxWidth: "100%" }}
                            startIcon={<CenterFocusStrong />}
                        >
                            {t("products:center_logo")}
                        </Button>
                        <Divider />
                        <Grid container spacing={0} justifyContent={"center"}>
                            <Grid item xs={12} md={4} sx={{ pr: {xs: 0, md: 1}, pb: { xs: 1, md: 0 } }}>
                                <Button
                                    variant={"outlined"}
                                    disableElevation
                                    size={"large"}
                                    fullWidth
                                    onClick={() => onCancelResize()}
                                >
                                    {t("common:cancel")}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Button
                                    onClick={() => onValidateResize()}
                                    variant={"contained"}
                                    color={"secondary"}
                                    disableElevation
                                    size={"large"}
                                    fullWidth
                                    startIcon={<Check />}
                                >
                                    {t("products:validate_logo_position")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{
                        position: "relative",
                        width: "100%",
                        maxWidth: 300,
                        maxHeight: 300,
                        margin: "auto",
                        textAlign: "center",
                        "& .preview": {
                            opacity: 0.5,
                            margin: "auto",
                            width: "100%"
                        }
                    }}>
                        <img
                            draggable={false}
                            alt="preview-logo-placement"
                            src={previewClear}
                            className={"preview"}
                        />
                        <DraggableLogo
                            {...{
                                logo,
                                previewLogo,
                                onDragStart, catchPosition, handleResize
                            }}
                        />
                    </Box>
                </Grid>
            </>
        )
    }

    return (
        <Grid item xs={12} md={gs1 ? 11 : 8} lg={gs1 ? 11 : 7}>
            <PaperLabelled label={t("products:qrcode_preview")} background={logo.replacing ? "#ebebeb" : null}>
                <Grid container justifyContent={"center"} spacing={2}>
                    {logo.replacing ? renderLogoReplacer() : renderPreview()}
                </Grid>
            </PaperLabelled>
        </Grid>
    )
}

export default PreviewSection