import React, {useState, useEffect} from 'react';
// RC
import {ChromePicker, ColorResult} from 'react-color';
// MUI
import {Button, Box, Typography} from '@mui/material';
// Utils
import {contrastColor, rgbToHex} from "@/utils";


const styles = {
    popoverAccordion: {
        position: 'absolute',
        zIndex: 10,
        top: '0px'
    },
    popover: {
        position: 'absolute',
        zIndex: 2,
        bottom: 50
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
    }
}

function ColorPicker(props: any) {

    const {color, setColor, label, alpha, t} = props

    const [displayPicker, setDisplayPicker] = useState(false);
    const [localColor, setLocalColor] = useState("#ffffff");

    useEffect(() => {

        if (color && alpha) {
            let _color = color
            if (_color) _color.a = alpha
            setLocalColor(_color)
            return
        }

        if (color === "" || color === undefined) setLocalColor("#ffffff")
        else if (color) setLocalColor(color === "white" ? "#ffffff" : color);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [color]);

    const onChange = (color: ColorResult) => {
        if (alpha) {
            setColor(color.hex, alpha)
        }
        setColor(color.hex);
        setLocalColor(color.hex)
    }

    return (
        <Box>
            {label && <Typography variant={"body1"} fontWeight={500}>{label}</Typography>}
            <Button
                id={`${label}-color-picker`}
                fullWidth
                variant='contained'
                disableElevation
                disabled={props.disabled}
                value={color}
                style={{
                    backgroundColor: localColor,
                    color: contrastColor(localColor),
                    border: contrastColor(localColor) === '#000000' ? '1px solid #575757' : '1px solid transparent',
                    width: 150
                }}
                onClick={() => setDisplayPicker(!displayPicker)}
            >
                {color ? color : t("chose_color")}
            </Button>

            {displayPicker &&
                <Box sx={styles.popover} id={`${label}-color-picker-palette`}>
                    <Box sx={styles.cover} onClick={() => setDisplayPicker(!displayPicker)}/>
                    <ChromePicker
                        disableAlpha
                        color={color!}
                        onChange={onChange}
                    />
                </Box>
            }
        </Box>
    );
}

function ColorPickerAlpha(props: any) {

    const {color, alpha, t} = props

    const initColor = props.initialColor !== undefined ? props.initialColor : '#5393c1';

    const [displayPicker, setDisplayPicker] = useState<boolean>(false);
    const [localColor, setLocalColor] = useState(initColor);
    const [localAlpha, setAlpha] = useState<number>(1);

    useEffect(() => {
        if (color !== undefined) {
            let m_color = color

            if (m_color === "white") m_color = localHexToRGB("#ffffff")
            else m_color = localHexToRGB(color)

            if (m_color !== null  && alpha !== undefined) m_color.a = alpha

            setLocalColor(m_color);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const onChangeComplete = (color: ColorResult) => {
        if (props.onChangeComplete !== undefined) props.onChangeComplete(color.hex, localAlpha)
    }

    const onChange = (color: ColorResult) => {
        setLocalColor(color.rgb);
        if (color.rgb.a)
            setAlpha(color.rgb.a);
    }

    function localHexToRGB(hex: string) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        if (result) {
            let r = parseInt(result[1], 16);
            let g = parseInt(result[2], 16);
            let b = parseInt(result[3], 16);
            return { r: r, g: g, b: b, a: 1 };
        }
        return null;
    }

    return (
        <>
            <Button
                id='color-btn'
                fullWidth
                variant='contained'
                disableElevation
                disabled={props.disabled}
                value={localColor}
                style={{
                    backgroundColor: rgbToHex(localColor.r, localColor.g, localColor.b),
                    color: contrastColor(rgbToHex(localColor.r, localColor.g, localColor.b)),
                    border: contrastColor(rgbToHex(localColor.r, localColor.g, localColor.b)) === '#000000' ? '1px solid #575757' : '1px solid transparent',
                    width: 150,
                    ...props.buttonStyle
                }}
                onClick={() => setDisplayPicker(!displayPicker)}
            >
                {(localColor.r || localColor.g || localColor.b) ? rgbToHex(localColor.r, localColor.g, localColor.b) : t("chose_color")}
            </Button>
            {displayPicker &&
                <Box sx={styles.popover}>
                    <Box sx={styles.cover} onClick={() => setDisplayPicker(false)}/>
                    <ChromePicker
                        color={localColor!}
                        onChange={onChange}
                        onChangeComplete={onChangeComplete}
                    />
                </Box>
            }
        </>
    );
}

export {ColorPicker, ColorPickerAlpha}