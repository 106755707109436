import React, {useState} from "react"
// MUI
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    IconButton,
    TextField
} from "@mui/material";
import {Archive, Close, Delete} from "@mui/icons-material";

export default function DeleteAnyDialog(props: any) {

    const {
        open, onClose, onDelete, assetName,
        text, t,
        onArchive, archived,
    } = props

    const [securedDeletion, setSecuredDeletion] = useState<string>("")

    // Handler made specifically for Products, managing archiving then deletion
    const handleDeletion = () => {
        // if archiving method isn't present, fallback to default method
        if (!onArchive) onDelete()
        else {
            // is already archived
            if (archived && securedDeletion === assetName) {
                onDelete()
                setSecuredDeletion("")
            }
            else onArchive()
        }
    }

    const renderDialogContent = () => {
        // if not a product
        if (!onArchive) return (
            <>
                <Typography variant={"body1"}>{text}</Typography>
                <Alert severity={"error"} sx={{ mt: 2 }}>{t("common:delete_irreversible_alert")}</Alert>
            </>
        )

        // render textField for product deletion
        if (Boolean(onArchive) && !archived) {
            return <Typography variant={"body1"}>{t("product_archive_alert")}</Typography>
        } else {
            return (
                <>
                    <Typography variant={"body1"}>{t("product_delete_alert")}</Typography>
                    <Alert severity={"error"} sx={{ my: 2 }}>{t("product_delete_alert_confirmation")}</Alert>

                    <TextField
                        label={t("common:label")}
                        placeholder={assetName || t("type_product_name")}
                        fullWidth
                        value={securedDeletion}
                        onChange={(e) => setSecuredDeletion(e.target.value)}
                    />
                </>
            )
        }

    }

    return (
        <>
            <Dialog
                open={open}
                maxWidth={"sm"}
                fullWidth
                PaperProps={{
                    sx: { position: "absolute", top: 80 }
                }}
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                            {
                                (!!onArchive && !archived) ? t("common:archive") + " " + assetName
                                : t("common:delete") + " " + assetName
                            }
                        </Box>
                        <IconButton onClick={onClose} aria-label={"close"}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {renderDialogContent()}
                </DialogContent>
                <DialogActions>
                    <Box sx={{ px: 3, pb: 3 }}>
                        <Button
                            variant={"text"}
                            size={"small"}
                            className={"defaultButton"}
                            onClick={onClose}
                            sx={{ mr: 1 }}
                        >
                            {t("common:back")}
                        </Button>
                        <Button
                            startIcon={Boolean(onArchive) && !archived ? <Archive /> : <Delete />}
                            color={"error"}
                            variant={"contained"}
                            size={"small"}
                            disableElevation
                            onClick={() => handleDeletion()}
                            disabled={Boolean(onArchive) && archived && securedDeletion !== assetName}
                        >
                            {(Boolean(onArchive) && !archived) ? t("archive_product") : t("common:delete")}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}