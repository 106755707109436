interface HttpResponse<T> extends Response {
    parsedBody?: T;
    rawBlob?: Blob;
}

async function httpAPI<T>(request: RequestInfo):Promise<HttpResponse<T>> {

    const response: HttpResponse<T> = await fetch(request, {credentials: 'include'});

    try {response.parsedBody = await response.json()}
    catch (e) {throw new Error(response.statusText)}
    return response;
}

async function httpBlob<T>(request: RequestInfo): Promise<HttpResponse<T>> {

    const response: HttpResponse<T> = await fetch(request);

    try {
        response.rawBlob = await response.blob()
    } catch (e) {
        throw new Error(response.statusText);
    }
    return response;
}

async function APIPostFile<T>(
    path: string,
    file: any,
    token: any,
    args = {
        method: 'post',
    }
): Promise<HttpResponse<T>>  {

    const data = new FormData();
    data.append('File', file);

    const req: RequestInit = {
        method: args.method,
        body: data,
        headers: [
            ['Authorization', token],
        ]
    }

    return await httpAPI<T>(new Request(path, req));
}

async function APIGet<T>(path: string, token?: any, args =
    { method: 'get' }): Promise<HttpResponse<T>> {

    const req: RequestInit = {
        method: args.method,
        headers: [
            ['Authorization', token],
        ]
    }

    return await httpAPI<T>(new Request(path, req));
}

async function APIPost<T>(
    path: string,
    body: any,
    token?: any,
    args = {
        method: 'post',
        body: JSON.stringify(body),
    }
): Promise<HttpResponse<T>> {

    const req: RequestInit = {
        method: args.method,
        body: args.body,
        headers: [
            ['Authorization', token],
        ]
    }
    return await httpAPI<T>(new Request(path, req));
}

async function APIPostBlob<T>(
    path: string,
    body: any,
    token?: any,
    args = {
        method: 'post',
        body: JSON.stringify(body),
    }
): Promise<HttpResponse<T>> {

    const req: RequestInit = {
        method: args.method,
        body: args.body,
        headers: [
            ['Authorization', token],
        ]
    }

    return await httpBlob<T>(new Request(path, req));
}

async function APIPut<T>(
    path: string,
    body: any,
    token?: any,
    args: RequestInit = {
        method: 'put',
        body: JSON.stringify(body),
    }
): Promise<HttpResponse<T>> {

    const req: RequestInit = {
        method: args.method,
        body: args.body,
        headers: [
            ['Authorization', token],
        ]
    }

    return await httpAPI<T>(new Request(path, req));
}

async function APIDelete<T>(
    path: string,
    body: any,
    token?: any,
    args: RequestInit = {
        method: 'delete',
        body: JSON.stringify(body),
    }
): Promise<HttpResponse<T>> {

    const req: RequestInit = {
        method: args.method,
        body: args.body,
        headers: [
            ['Authorization', token],
        ]
    }

    return await httpAPI<T>(new Request(path, req));
}

async function APIGetBlob<T>(
    path: string,
    token?: any,
    args = {
        method: 'get',
    }
): Promise<HttpResponse<T>> {

    const req: RequestInit = {
        method: args.method,
        headers: [
            ['Authorization', token],
        ]
    }

    return await httpBlob<T>(new Request(path, req));
}


export {APIGet, APIPostFile, APIPostBlob, APIPut, APIPost, APIDelete, APIGetBlob}