import React from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { CropDin } from "@mui/icons-material";

const GeneratorEyesList = (props: any) => {

    const { onChange, activeEyeStyle, StylesAssets } = props;

    const eyes = [
        { name: "simple", x: "-900px" },
        { name: "diamond", x: "-650px" },
        { name: "rounded", x: "-450px" },
        { name: "rounded_light", x: "-402px" },
        { name: "rounded_strong", x: "-500px" },
        { name: "eye_right", x: "-300px" },
        { name: "eye_left", x: "-350px" },
        { name: "shield", x: "-950px" },
        { name: "pillow", x: "-100px" },
        { name: "star", x: "-50px" },
        { name: "leaf", x: "-800px" },
        { name: "sieve", x: "-750px" },
        { name: "dots", x: "-250px" },
        { name: "wave", x: "-700px" },
        { name: "sharp", x: "0px" },
        { name: "curved", x: "-553px" },
        // { name: "tik_tak_toe", x: "-1102px" },
        { name: "octogonal", x: "-1152px" },
        { name: "alien", x: "-1002px" },
        { name: "grid", x: "-1052px" },

    ]

    const capitalize = (name: string) => {
        let label = name.replace(/_/g, " ")
        return label.charAt(0).toUpperCase() + label.slice(1);
    }

    return (
        <>
            <List className={"fancyScroll"} style={{maxHeight: '200px', overflow: 'auto'}}>
                {
                    eyes.map((eye, index) => {
                        return (
                            <ListItemButton
                                id={eye.name}
                                selected={activeEyeStyle === eye.name}
                                onClick={onChange}
                                key={index}
                            >
                                <ListItemIcon>
                                    <CropDin
                                        sx={{
                                            backgroundImage: `url(${StylesAssets})`,
                                            backgroundPositionX: eye.x,
                                            backgroundPositionY: "-100px",
                                            fill: "white !important",
                                            fillOpacity: "0.0 !important",
                                            width: "47px !important",
                                            height: "47px !important",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={capitalize(eye.name)} />
                            </ListItemButton>
                        )
                    })
                }
            </List>
        </>
    )
}

export default GeneratorEyesList