import React, {createContext, useContext, useState, useEffect} from "react"
import {useUser} from "@/context/UserProvider";

const ModuleContext = createContext<any>(null)
export const useModule = () => useContext(ModuleContext)

export default function ModuleProvider({children}: any) {

    const {credits, gs1Credits} = useUser()

    // modules
    const [modules, setModules] = useState<any>({
        geoloc: false,
        gs1: false
    })

    useEffect(() => {
        setModules({
            geoloc: Boolean(credits),
            gs1: Boolean(gs1Credits)
        })
    }, [credits, gs1Credits])

    return (
        <ModuleContext.Provider value={{modules, setModules,}}>
            {children}
        </ModuleContext.Provider>
    )
}