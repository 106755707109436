import React, {useEffect, useState, useRef} from "react"
import {useNavigate} from "react-router-dom"
// Primereact
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
// MUI
import {Grid, Tooltip, Typography, IconButton, Paper, Skeleton} from "@mui/material";
import {Edit, Delete, Link, QueryStats, Unarchive, Download, Inventory, Downloading} from "@mui/icons-material";
// Components
import {DeleteAnyDialog} from "@/components/UI";
// Data
import {useData} from "@/context/PackagingDataProvider";
// Utils
import {formatJSIso8601ToHumanString} from "@/utils/timeFunctions";
import ProductUrlDialog from "@/components/UI/Dialogs/ProductUrlDialog/ProductUrlDialog";
import {ContextMenu} from "primereact/contextmenu";
// @ts-ignore
import QRCodePlaceholder from "@/assets/png/qrcode-preview.png";
import {useModule} from "@/context/ModuleProvider";
import {DownloadDialog} from "@/components/Download";

export default function ProductsDataTable(props: any) {

    const {
        products,
        page, onPageChange,
        selection, setSelection,
        multipleSelection, setMultipleSelection,
        seeInAnalytics,
        t
    } = props

    const {
        deleteAny,
        getProducts,
        getSingleQRCodePreview,
        getGS1QRCodePreview,
        totalProducts,
        archiveProducts, restoreProducts,
        quickDownloadSingleQRCode
    } = useData()

    const {modules} = useModule()

    const navigate = useNavigate()
    const contextMenu = useRef<ContextMenu>(null)

    // Previews
    const [qrcodePreviews, setQRCodePreviews] = useState<any[]>([])
    // Deletion
    const [deletion, setDeletion] = useState<any>(null)
    // Delete dialog
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    // Download Dialog
    const [downloading, setDownloading] = useState<any>(null)
    // URLs
    const [openUrlsDialog, setOpenUrlsDialog] = useState<any>({ open: false, product: null})

    useEffect(() => {
        if (!!products && products.length > 0) {
            hydratePreviews().then(() => null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])

    const hydratePreviews = async () => {
        for (let obj of products) {
            if (!obj.archived) {
                if (obj.type === "" || obj.type === "Standard") {
                    await getSingleQRCodePreview(obj.qr_code.id).then((res: any) => {
                        setQRCodePreviews((prev: any) => ({...prev, [obj.qr_code.id]: res}))
                    })
                } else if (obj.type === "GS1") {
                    await getGS1QRCodePreview(obj.qr_code.id).then((res: any) => {
                        setQRCodePreviews((prev: any) => ({...prev, [obj.qr_code.id]: res}))
                    })
                }
            }
        }
    }

    const contextMenuModel = [
        {
            label: t("common:edit"),
            icon: <Edit fontSize={"small"} sx={{ mr: 1 }} />,
            command: () => handleEdit(selection),
            disabled: Boolean((multipleSelection && multipleSelection.length > 1) || selection?.archived)
        },
        {
            label: t("redirection_urls"),
            icon: <Link fontSize={"small"} sx={{ mr: 1 }} />,
            command: () => handleOpenUrl(selection),
            disabled: Boolean((multipleSelection && multipleSelection.length > 1) || selection?.archived)
        },
        {
            label: t("product_see_in_analytics"),
            icon: <QueryStats fontSize={"small"} sx={{ mr: 1 }} />,
            command: () => seeInAnalytics(selection),
            disabled: Boolean((multipleSelection && multipleSelection.length > 1) || selection?.archived)
        },
        { separator: true },
        {
            label: t("quick_download"),
            icon: <Downloading fontSize={"small"} sx={{ mr: 1 }} />,
            command: () => quickDownloadSingleQRCode(selection.qr_code.id, selection.name),
            disabled: Boolean((multipleSelection && multipleSelection.length > 1) || selection?.archived)
        },
        {
            label: t("export_as"),
            icon: <Download fontSize={"small"} sx={{ mr: 1 }} />,
            command: () => setDownloading(selection),
            disabled: Boolean((multipleSelection && multipleSelection.length > 1) || selection?.archived)
        },
        { separator: true },
        {
            label: selection?.archived ? t("common:delete") : t("archive"),
            icon: selection?.archived ? <Delete fontSize={"small"} sx={{ mr: 1 }} /> : <Inventory fontSize={"small"} sx={{ mr: 1 }} />,
            command: () => handleDelete(selection),
            disabled: Boolean((multipleSelection && multipleSelection.length > 1))
        },
    ]

    // CRUD
    const archiveProduct = () => {
        archiveProducts([deletion.id]).then((archived: any) => { if (!!archived) getProducts()})
        setOpenDeleteDialog(false)
        setDeletion(null)
        setSelection(null)
        setMultipleSelection(null)
    }

    const restoreProduct = (uuid: any) => {
        restoreProducts([uuid]).then((restored: any) => { if (!!restored) getProducts() })
        setSelection(null)
        setMultipleSelection(null)
    }

    const deleteProduct = () => {
        deleteAny(deletion, "product").then((deleted: any) => { if (!!deleted) getProducts() })
        setOpenDeleteDialog(false)
        setDeletion(null)
        setSelection(null)
        setMultipleSelection(null)
    }

    // Handlers
    const handleEdit = (rowData: any) => {
        if (rowData.type === "GS1") navigate("/products/gs1/edit", { state: { ...rowData } })
        else navigate("/products/edit", { state: { ...rowData } })
    }

    const handleDelete = (rowData: any) => {
        setDeletion(rowData)
        setOpenDeleteDialog(true)
    }

    const handleOpenUrl = (rowData: any) => {
        setOpenUrlsDialog({ open: true, product: rowData })
    }

    // Columns
    const logoTemplate = (rowData: any) => {

        if (!rowData || rowData.qr_code.id === "" || !rowData.qr_code.id) return <></>

        if (rowData.archived) return (
            <Tooltip title={t("archived_tooltip")} placement={"top"} arrow>
                <div style={{ position: "relative" }}>
                    <img height={60} width={60} src={QRCodePlaceholder} alt={"preview"} />
                    <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                        <i>{t("archived")}</i>
                    </div>
                </div>
            </Tooltip>
        )

        if (qrcodePreviews) {
            if (!qrcodePreviews[rowData.qr_code.id]) {
                return (
                    <Skeleton
                        variant={"rectangular"}
                        height={60} width={60}
                        sx={{ display: "inline-block", borderRadius: "6px" }}
                    />
                )
            } else return <img height={60} width={60} src={qrcodePreviews[rowData.qr_code.id]} alt={"preview"} />
        }

        return <></>
    }

    const dateBodyTemplate = (rowData: any) => {
        return <Typography>{formatJSIso8601ToHumanString(rowData.created_at)}</Typography>
    }

    const actionBodyTemplate = (rowData: any) => {

        if (rowData.archived) return (
            <Grid container justifyContent={"flex-end"} style={{minWidth: '100px'}}>
                <Grid item>
                    <Tooltip placement={"top"} arrow title={`${t("restore")}`}>
                        <IconButton aria-label="restore" onClick={() => restoreProduct(rowData.id)}>
                            <Unarchive />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip placement={"top"} arrow title={`${t("delete")}`}>
                        <IconButton
                            aria-label="delete"
                            onClick={() => handleDelete(rowData)}
                        ><Delete/></IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        )

        return (
            <Grid container justifyContent={"flex-end"} style={{minWidth: '100px'}}>
                <Grid item>
                    <Tooltip placement={"top"} arrow title={`${t("edit")}`}>
                        <IconButton
                            aria-label="edit"
                            onClick={() => handleEdit(rowData)}
                        ><Edit /></IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip placement={"top"} arrow title={`${t("redirection_urls")}`}>
                        <IconButton
                            aria-label="redirection urls"
                            onClick={() => handleOpenUrl(rowData)}
                        ><Link/></IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip placement={"top"} arrow title={`${t("export_as")}`}>
                        <IconButton
                            aria-label="download qrcode"
                            onClick={() => setDownloading(rowData)}
                        ><Download /></IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip placement={"top"} arrow title={`${t("archive")}`}>
                        <IconButton
                            aria-label="archive"
                            onClick={() => handleDelete(rowData)}
                        ><Inventory /></IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <Paper variant={"outlined"} sx={{ borderRadius: 5, overflow: "hidden" }}>
                <ContextMenu
                    model={contextMenuModel}
                    ref={contextMenu}
                    onHide={() => setSelection(null)}
                    breakpoint={"600px"}
                />
                <DataTable
                    dataKey={"id"}
                    lazy
                    paginator
                    rowHover
                    first={page.first}
                    rows={page.max_rows}
                    totalRecords={totalProducts}
                    onPage={onPageChange}
                    rowsPerPageOptions={[10, 20, 50, 100, 150, 200]}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                    emptyMessage={t("common:no_elements_found")}
                    selection={multipleSelection}
                    onSelectionChange={(event: any) => setMultipleSelection(event.value)}
                    value={products}
                    onContextMenu={(e) => contextMenu.current?.show(e.originalEvent)}
                    contextMenuSelection={selection}
                    onContextMenuSelectionChange={(e) => setSelection(e.value)}
                >
                    <Column selectionMode="multiple" bodyStyle={{textAlign: 'center'}} className={"p-justify-header-center"}/>
                    {(!!modules && modules.gs1) && <Column header={t("type")} field={"type"} />}
                    <Column header={t("logo")} bodyStyle={{textAlign: 'center', justifyContent: "center"}} className={"p-justify-header-center"} body={logoTemplate}/>
                    <Column field="name" header={t("label")} />
                    <Column field="created_at" header={t("created_at")} body={dateBodyTemplate}/>
                    <Column field="range.brand.name" header={t("brand")} />
                    <Column field="range.name" header={t("range")} />
                    <Column header={t("actions")} body={actionBodyTemplate} className={"p-justify-header-right"} />
                </DataTable>
            </Paper>

            <DeleteAnyDialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                onDelete={() => deleteProduct()}
                onArchive={() => archiveProduct()}

                assetName={deletion?.name}
                archived={deletion?.archived}
                text={t("delete_product_alert")}
                {...{t}}
            />

            <ProductUrlDialog
                open={openUrlsDialog.open}
                onClose={() => setOpenUrlsDialog({ open: false, product: null })}
                product={openUrlsDialog.product}
                {...{t}}
            />

            <DownloadDialog
                open={!!downloading}
                onClose={() => setDownloading(null)}
                product={downloading}
                {...{t}}
            />
        </>
    )
}