import React, {useEffect, useState} from "react"
// MUI
import {Box, ButtonBase, Grid, Paper, Stack, Typography} from "@mui/material";
import {Image, Palette, Style, Visibility, QrCode, Settings} from "@mui/icons-material";
// Components
import {PaperLabelled} from "@/components/UI";
import {
    GeneratorColorsPanel,
    GeneratorEyesPanel,
    GeneratorLogoPanel,
    GeneratorOptionsPanel,
    GeneratorStylesPanel,
    GeneratorTemplatesPanel
} from "./GeneratorCustomisationPanels";

const GeneratorCustomisationSection = (props: any) => {

    const {
        gs1,
        qrcode, setQRCode,
        onSubmit, submitted,
        logo, setLogo,
        qrcodeTemplates,
        t
    } = props

    const [selectedCustomisationPanel, setSelectedCustomisationPanel] = useState<number>(0)

    useEffect(() => {
        if (!submitted) return
        onSubmit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qrcode])

    const commonProps: any = {
       t, qrcode, setQRCode
    }

    const customisationPanelsList = [
        { label: "templates", icon: <QrCode />, panel: <GeneratorTemplatesPanel {...commonProps} {...{logo, setLogo, qrcodeTemplates}} />},
        { label: "colors", icon: <Palette />, panel: <GeneratorColorsPanel {...commonProps} /> },
        { label: "styles", icon: <Style />, panel: <GeneratorStylesPanel {...commonProps} /> },
        { label: "logo", icon: <Image />, panel: <GeneratorLogoPanel{...commonProps}{...{logo, setLogo}} />},
        { label: "eyes", icon: <Visibility />, panel: <GeneratorEyesPanel {...commonProps} /> },
        { label: "options", icon: <Settings />, panel: <GeneratorOptionsPanel {...commonProps} /> },
    ]

    const returnCustomisationButtonStyles = (index: number) => {
        return {
            py: 1,
            px: 2,
            borderRadius: "6px",
            justifyContent: "flex-start",
            transition: "0.25s ease-in-out",
            border: "1px solid",
            borderColor: selectedCustomisationPanel === index ? "primary.main" : "transparent",
            backgroundColor: selectedCustomisationPanel === index ? "rgba(0,0,0,0.06)" : "transparent",
            filter: logo.replacing ? "grayscale(100%)" : "none",
            opacity: logo.replacing ? "0.5" : "1",
            "&:hover": {
                backgroundColor: "rgba(0,0,0,0.15)"
            }
        }
    }

    if (gs1) return <></>

    return (
        <>
            <Grid item xs={12} lg={3}>
                <PaperLabelled label={t("products:qrcode_options")}>
                    <Stack direction={"column"} justifyContent={"flex-start"}>
                        {customisationPanelsList.map((item: any, index: number) => (
                            <ButtonBase
                                key={index}
                                sx={returnCustomisationButtonStyles(index)}
                                onClick={() => setSelectedCustomisationPanel(index)}
                                disabled={item.disabled || logo.replacing}
                            >
                                <Stack direction={"row"} spacing={2} justifyContent={"flex-start"}>
                                    {item.icon}
                                    <Typography variant={"body2"}>{t(`products:${item.label}`)}</Typography>
                                </Stack>
                            </ButtonBase>
                        ))}
                    </Stack>
                </PaperLabelled>
            </Grid>
            <Grid item xs={12} lg={9}>
                <Paper variant={"outlined"} className={"fancyScroll"} sx={{ p: 2, minHeight: 283, maxHeight: 283, overflowY: "scroll" }}>
                    {
                        submitted ?
                            customisationPanelsList.map((item: any, index: number) => {
                                if (index === selectedCustomisationPanel) return <div key={index}>{item.panel}</div>
                                else return <div key={index}></div>
                            })
                            :
                            <Box sx={{ width: "100%", height: 249, display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center" }}>
                                <Typography align={"center"} fontStyle={"italic"}>{t("products:submit_before_customizing")}</Typography>
                            </Box>
                    }
                </Paper>
            </Grid>
        </>
    )
}

export default GeneratorCustomisationSection