import React, {useEffect, useState} from "react"
// MUI
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
// Context
import {useUser} from "@/context/UserProvider";

export default function OrganisationFilterSelect(props: any) {

    const {organisationFilter, setOrganisationFilter, small, t} = props
    const {
        fullOrganisation: organisation, getFullOrganisation: getOrganisation,
        user, getUser,
        userRoles
    } = useUser()

    const [organisationsMenuItems, setOrganisationMenuItems] = useState<any[]>([])

    useEffect(() => {
        if (!user) getUser()
        if (!organisation) getOrganisation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!!user && !!organisation && organisation.is_root_org) mapSubOrganisations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation])

    const mapSubOrganisations = () => {
        let _organisationMenuItems: any[] = []

        _organisationMenuItems.push(<MenuItem value={''} key={0} disabled>{t('all_organisations')}</MenuItem>);
        _organisationMenuItems.push(
            <MenuItem value={organisation.organisation_id} key={organisation.organisation_id}>
                {organisation.organisation_name!}
            </MenuItem>
        );

        if (!!user && user.organisation_role !== userRoles.member) {
            const _currentSubOrgs = organisation.sub_organisations;
            if (!!_currentSubOrgs) {
                _currentSubOrgs.forEach((subOrg: any, index: number) => {
                    _organisationMenuItems.push(
                        <MenuItem value={subOrg.organisation_id} key={index}>
                            {subOrg.organisation_name}
                        </MenuItem>
                    );
                })
            }
        }

        setOrganisationMenuItems(_organisationMenuItems)
    }

    return (
        <>
            <FormControl fullWidth size={small ? "small" : "medium"}>
                <InputLabel id="packaging-filter-by-org" shrink>
                    {t("organisation")}
                </InputLabel>
                <Select
                    size={small ? "small" : "medium"}
                    labelId="packaging-filter-by-org"
                    id="packaging-filter"
                    label="Organisation"
                    value={organisationFilter}
                    displayEmpty
                    notched
                    onChange={(e) => setOrganisationFilter(e.target.value as string)}
                >
                    {organisationsMenuItems}
                </Select>
            </FormControl>
        </>
    )
}