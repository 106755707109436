import React, {useState} from "react"
import {
    Box,
    Button, Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, Grid,
    IconButton,
    InputLabel, MenuItem, Select, Stack, TextField, Typography
} from "@mui/material";
import {Close} from "@mui/icons-material";
import {PaperLabelled} from "@/components/UI";
import {useData} from "@/context/PackagingDataProvider";

export default function DownloadDialog(props: any) {

    const {open, onClose, product, t} = props
    const {downloadSingleQRCode} = useData()

    const [file, setFile] = useState<any>({
        format: "png",
        unit: "px",
        size: "300",
        resolution: "75"
    })

    const isCorrectlySized =
        (file.unit === "px" && (Number(file.size) >= 100 && Number(file.size) <= 2500)) ||
        (file.unit === "mm" && file.size >= 30 && (
            (file.resolution === "75" && Number(file.size) <= 847) || (file.resolution === "300" && Number(file.size) <= 212)
        ))


    // Download
    const downloadQRCode = async () => {
        await downloadSingleQRCode(product.qr_code.id, product.name, file).then(() => {
            onClose()
        })
    }

    // Sections
    const renderFileFormatSelection = () => {

        const renderTypeSelection = () => {
            return (
                <FormControl fullWidth>
                    <InputLabel id={"file-format"}>{t("file_format")}</InputLabel>
                    <Select
                        label={t("file_format")}
                        labelId={"file-format"}
                        value={file.format}
                        onChange={(e) => setFile({...file, format: e.target.value})}
                    >
                        <MenuItem value={"png"}>PNG</MenuItem>
                        <MenuItem value={"jpeg"}>JPEG</MenuItem>
                        <MenuItem value={"svg"}>SVG</MenuItem>
                        <MenuItem value={"pdf"}>PDF</MenuItem>
                    </Select>
                </FormControl>
            )
        }

        const renderUnitSelection = () => {
            return (
                <FormControl fullWidth>
                    <InputLabel id={"file-unit"}>{t("file_unit")}</InputLabel>
                    <Select
                        label={t("file_unit")}
                        labelId={"file-unit"}
                        value={file.unit}
                        onChange={(e) => setFile({...file, unit: e.target.value})}
                    >
                        <MenuItem value={"px"}>Pixels</MenuItem>
                        <MenuItem value={"mm"}>Millimeters</MenuItem>
                    </Select>
                </FormControl>
            )
        }

        const renderSizeSelection = () => {
            return (
                <>
                    <TextField
                        label={t("file_size")}
                        value={file.size}
                        onChange={(e) => setFile({...file, size: e.target.value})}
                        fullWidth
                        type={"number"}
                        error={!isCorrectlySized}
                        InputProps={{
                            endAdornment: <Typography variant={"body2"}>{file.unit}</Typography>,
                            inputProps: {
                                max:
                                    file.unit === "px" ? 2500 :
                                        (file.unit === "mm" && file.resolution === "300") ? 212 :
                                            847,
                                min: file.unit === "px" ? 100 : 30,
                            }
                        }}
                        helperText={
                            file.unit === "px" ? t("file_size_alert_px") :
                                (file.unit === "mm" && file.resolution === "75") ? t("file_size_alert_75") :
                                    t("file_size_alert_300")
                        }
                    />

                    <Collapse in={file.unit === "mm"} sx={{ pt: 2 }}>
                        <Stack direction={"row"} spacing={2}>
                            <Typography>{t("resolution")}</Typography>
                            {
                                ["75", "300"].map((resolution: any) => (
                                    <Button
                                        variant={file.resolution === resolution ? "contained" : "outlined"}
                                        size={"small"}
                                        onClick={() => setFile({...file, resolution: resolution})}
                                        disableElevation
                                        key={resolution}
                                    >
                                        {resolution} ppi
                                    </Button>
                                ))
                            }
                        </Stack>
                    </Collapse>
                </>
            )
        }

        return (
            <PaperLabelled label={t("file_size_and_format")} sx={{ mt: 2 }}>
                <Grid container spacing={2} sx={{ pt: "4px" }}>
                    <Grid item xs={12} md={7}>{renderTypeSelection()}</Grid>
                    <Grid item xs={12} md={7}>{renderUnitSelection()}</Grid>
                    <Grid item xs={12} md={7}>{renderSizeSelection()}</Grid>
                </Grid>
            </PaperLabelled>
        )
    }


    return (
        <Dialog {...{open, onClose}} maxWidth={"md"} fullWidth>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>{t("products:download_qrcodes")}</Box>
                    <IconButton onClick={onClose} aria-label={"close"}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                {renderFileFormatSelection()}
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                <Button className={"defaultButton"} onClick={onClose}>
                    {t("common:back")}
                </Button>
                <Button
                    variant={"contained"}
                    disableElevation
                    onClick={() => downloadQRCode()}
                >
                    {t("products:download")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}