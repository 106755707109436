
const dataAttributes: any[] = [
    { key: "02", label: "[02] GTIN of contained trade items" },
    { key: "11", label: "[11] Production date (YYMMDD)" },
    { key: "12", label: "[12] Due date (YYMMDD)" },
    { key: "13", label: "[13] Packaging date (YYMMDD)" },
    { key: "15", label: "[15] Best before date (YYMMDD)" },
    { key: "16", label: "[16] Sell by date (YYMMDD)" },
    { key: "17", label: "[17] Expiration date (YYMMDD)" },
    { key: "20", label: "[20] Internal product variant" },
    { key: "235", label: "[235] Third Party Controlled, Serialised Extension of GTIN" },
    { key: "240", label: "[240] Additional product identification assigned by the manufacturer" },
    { key: "241", label: "[241] Customer part number" },
    { key: "242", label: "[242] Made-to-Order variation number" },
    { key: "243", label: "[243] Packaging component number" },
    { key: "250", label: "[250] Secondary serial number" },
    { key: "251", label: "[251] Reference to source entity" },
    { key: "30", label: "[30] Variable count of items (variable measure trade item)" },
    { key: "3100", label: "[3100] Net weight, kilograms (variable measure trade item)" },
    { key: "3101", label: "[3101] Net weight, kilograms (variable measure trade item)" },
    { key: "3102", label: "[3102] Net weight, kilograms (variable measure trade item)" },
    { key: "3103", label: "[3103] Net weight, kilograms (variable measure trade item)" },
    { key: "3104", label: "[3104] Net weight, kilograms (variable measure trade item)" },
    { key: "3105", label: "[3105] Net weight, kilograms (variable measure trade item)" },
    { key: "3110", label: "[3110] Length or first dimension, metres (variable measure trade item)" },
    { key: "3111", label: "[3111] Length or first dimension, metres (variable measure trade item)" },
    { key: "3112", label: "[3112] Length or first dimension, metres (variable measure trade item)" },
    { key: "3113", label: "[3113] Length or first dimension, metres (variable measure trade item)" },
    { key: "3114", label: "[3114] Length or first dimension, metres (variable measure trade item)" },
    { key: "3115", label: "[3115] Length or first dimension, metres (variable measure trade item)" },
    { key: "3120", label: "[3120] Width, diameter, or second dimension, metres (variable measure trade item)" },
    { key: "3121", label: "[3121] Width, diameter, or second dimension, metres (variable measure trade item)" },
    { key: "3122", label: "[3122] Width, diameter, or second dimension, metres (variable measure trade item)" },
    { key: "3123", label: "[3123] Width, diameter, or second dimension, metres (variable measure trade item)" },
    { key: "3124", label: "[3124] Width, diameter, or second dimension, metres (variable measure trade item)" },
    { key: "3125", label: "[3125] Width, diameter, or second dimension, metres (variable measure trade item)" },
    { key: "3130", label: "[3130] Depth, thickness, height, or third dimension, metres (variable measure trade item)" },
    { key: "3131", label: "[3131] Depth, thickness, height, or third dimension, metres (variable measure trade item)" },
    { key: "3132", label: "[3132] Depth, thickness, height, or third dimension, metres (variable measure trade item)" },
    { key: "3133", label: "[3133] Depth, thickness, height, or third dimension, metres (variable measure trade item)" },
    { key: "3134", label: "[3134] Depth, thickness, height, or third dimension, metres (variable measure trade item)" },
    { key: "3135", label: "[3135] Depth, thickness, height, or third dimension, metres (variable measure trade item)" },
    { key: "3140", label: "[3140] Area, square metres (variable measure trade item)" },
    { key: "3141", label: "[3141] Area, square metres (variable measure trade item)" },
    { key: "3141", label: "[3142] Area, square metres (variable measure trade item)" },
    { key: "3143", label: "[3143] Area, square metres (variable measure trade item)" },
    { key: "3144", label: "[3144] Area, square metres (variable measure trade item)" },
    { key: "3145", label: "[3145] Area, square metres (variable measure trade item)" },
    { key: "3150", label: "[3150] Net volume, litres (variable measure trade item)" },
    { key: "3151", label: "[3151] Net volume, litres (variable measure trade item)" },
    { key: "3152", label: "[3152] Net volume, litres (variable measure trade item)" },
    { key: "3153", label: "[3153] Net volume, litres (variable measure trade item)" },
    { key: "3154", label: "[3154] Net volume, litres (variable measure trade item)" },
    { key: "3155", label: "[3155] Net volume, litres (variable measure trade item)" },
    { key: "3160", label: "[3160] Net volume, cubic metres (variable measure trade item)" },
    { key: "3161", label: "[3161] Net volume, cubic metres (variable measure trade item)" },
    { key: "3162", label: "[3162] Net volume, cubic metres (variable measure trade item)" },
    { key: "3163", label: "[3163] Net volume, cubic metres (variable measure trade item)" },
    { key: "3164", label: "[3164] Net volume, cubic metres (variable measure trade item)" },
    { key: "3165", label: "[3165] Net volume, cubic metres (variable measure trade item)" },
    { key: "3200", label: "[3200] Net weight, pounds (variable measure trade item)" },
    { key: "3201", label: "[3201] Net weight, pounds (variable measure trade item)" },
    { key: "3202", label: "[3202] Net weight, pounds (variable measure trade item)" },
    { key: "3203", label: "[3203] Net weight, pounds (variable measure trade item)" },
    { key: "3204", label: "[3204] Net weight, pounds (variable measure trade item)" },
    { key: "3205", label: "[3205] Net weight, pounds (variable measure trade item)" },
    { key: "3210", label: "[3210] Length or first dimension, inches (variable measure trade item)" },
    { key: "3211", label: "[3211] Length or first dimension, inches (variable measure trade item)" },
    { key: "3212", label: "[3212] Length or first dimension, inches (variable measure trade item)" },
    { key: "3213", label: "[3213] Length or first dimension, inches (variable measure trade item)" },
    { key: "3214", label: "[3214] Length or first dimension, inches (variable measure trade item)" },
    { key: "3215", label: "[3215] Length or first dimension, inches (variable measure trade item)" },
    { key: "3220", label: "[3220] Length or first dimension, feet (variable measure trade item)" },
    { key: "3221", label: "[3221] Length or first dimension, feet (variable measure trade item)" },
    { key: "3222", label: "[3222] Length or first dimension, feet (variable measure trade item)" },
    { key: "3223", label: "[3223] Length or first dimension, feet (variable measure trade item)" },
    { key: "3224", label: "[3224] Length or first dimension, feet (variable measure trade item)" },
    { key: "3225", label: "[3225] Length or first dimension, feet (variable measure trade item)" },
    { key: "3230", label: "[3230] Length or first dimension, yards (variable measure trade item)" },
    { key: "3231", label: "[3231] Length or first dimension, yards (variable measure trade item)" },
    { key: "3232", label: "[3232] Length or first dimension, yards (variable measure trade item)" },
    { key: "3233", label: "[3233] Length or first dimension, yards (variable measure trade item)" },
    { key: "3234", label: "[3234] Length or first dimension, yards (variable measure trade item)" },
    { key: "3235", label: "[3235] Length or first dimension, yards (variable measure trade item)" },
    { key: "3240", label: "[3240] Width, diameter, or second dimension, inches (variable measure trade item)" },
    { key: "3241", label: "[3241] Width, diameter, or second dimension, inches (variable measure trade item)" },
    { key: "3242", label: "[3242] Width, diameter, or second dimension, inches (variable measure trade item)" },
    { key: "3243", label: "[3243] Width, diameter, or second dimension, inches (variable measure trade item)" },
    { key: "3244", label: "[3244] Width, diameter, or second dimension, inches (variable measure trade item)" },
    { key: "3245", label: "[3245] Width, diameter, or second dimension, inches (variable measure trade item)" },
    { key: "3250", label: "[3250] Width, diameter, or second dimension, feet (variable measure trade item)" },
    { key: "3251", label: "[3251] Width, diameter, or second dimension, feet (variable measure trade item)" },
    { key: "3252", label: "[3252] Width, diameter, or second dimension, feet (variable measure trade item)" },
    { key: "3253", label: "[3253] Width, diameter, or second dimension, feet (variable measure trade item)" },
    { key: "3254", label: "[3254] Width, diameter, or second dimension, feet (variable measure trade item)" },
    { key: "3255", label: "[3255] Width, diameter, or second dimension, feet (variable measure trade item)" },
    { key: "3260", label: "[3260] Width, diameter, or second dimension, yards (variable measure trade item)" },
    { key: "3261", label: "[3261] Width, diameter, or second dimension, yards (variable measure trade item)" },
    { key: "3262", label: "[3262] Width, diameter, or second dimension, yards (variable measure trade item)" },
    { key: "3263", label: "[3263] Width, diameter, or second dimension, yards (variable measure trade item)" },
    { key: "3264", label: "[3264] Width, diameter, or second dimension, yards (variable measure trade item)" },
    { key: "3265", label: "[3265] Width, diameter, or second dimension, yards (variable measure trade item)" },
    { key: "3270", label: "[3270] Depth, thickness, height, or third dimension, inches (variable measure trade item)" },
    { key: "3271", label: "[3271] Depth, thickness, height, or third dimension, inches (variable measure trade item)" },
    { key: "3272", label: "[3272] Depth, thickness, height, or third dimension, inches (variable measure trade item)" },
    { key: "3273", label: "[3273] Depth, thickness, height, or third dimension, inches (variable measure trade item)" },
    { key: "3274", label: "[3274] Depth, thickness, height, or third dimension, inches (variable measure trade item)" },
    { key: "3275", label: "[3275] Depth, thickness, height, or third dimension, inches (variable measure trade item)" },
    { key: "3280", label: "[3280] Depth, thickness, height, or third dimension, feet (variable measure trade item)" },
    { key: "3281", label: "[3281] Depth, thickness, height, or third dimension, feet (variable measure trade item)" },
    { key: "3282", label: "[3282] Depth, thickness, height, or third dimension, feet (variable measure trade item)" },
    { key: "3283", label: "[3283] Depth, thickness, height, or third dimension, feet (variable measure trade item)" },
    { key: "3284", label: "[3284] Depth, thickness, height, or third dimension, feet (variable measure trade item)" },
    { key: "3285", label: "[3285] Depth, thickness, height, or third dimension, feet (variable measure trade item)" },
    { key: "3290", label: "[3290] Depth, thickness, height, or third dimension, yards (variable measure trade item)" },
    { key: "3291", label: "[3291] Depth, thickness, height, or third dimension, yards (variable measure trade item)" },
    { key: "3292", label: "[3292] Depth, thickness, height, or third dimension, yards (variable measure trade item)" },
    { key: "3293", label: "[3293] Depth, thickness, height, or third dimension, yards (variable measure trade item)" },
    { key: "3294", label: "[3294] Depth, thickness, height, or third dimension, yards (variable measure trade item)" },
    { key: "3295", label: "[3295] Depth, thickness, height, or third dimension, yards (variable measure trade item)" },
    { key: "3300", label: "[3300] Logistic weight, kilograms" },
    { key: "3301", label: "[3301] Logistic weight, kilograms" },
    { key: "3302", label: "[3302] Logistic weight, kilograms" },
    { key: "3303", label: "[3303] Logistic weight, kilograms" },
    { key: "3304", label: "[3304] Logistic weight, kilograms" },
    { key: "3305", label: "[3305] Logistic weight, kilograms" },
    { key: "3310", label: "[3310] Length or first dimension, metres" },
    { key: "3311", label: "[3311] Length or first dimension, metres" },
    { key: "3312", label: "[3312] Length or first dimension, metres" },
    { key: "3313", label: "[3313] Length or first dimension, metres" },
    { key: "3314", label: "[3314] Length or first dimension, metres" },
    { key: "3315", label: "[3315] Length or first dimension, metres" },
    { key: "3320", label: "[3320] Width, diameter, or second dimension, metres" },
    { key: "3321", label: "[3321] Width, diameter, or second dimension, metres" },
    { key: "3322", label: "[3322] Width, diameter, or second dimension, metres" },
    { key: "3323", label: "[3323] Width, diameter, or second dimension, metres" },
    { key: "3324", label: "[3324] Width, diameter, or second dimension, metres" },
    { key: "3325", label: "[3325] Width, diameter, or second dimension, metres" },
    { key: "3330", label: "[3330] Depth, thickness, height, or third dimension, metres" },
    { key: "3331", label: "[3331] Depth, thickness, height, or third dimension, metres" },
    { key: "3332", label: "[3332] Depth, thickness, height, or third dimension, metres" },
    { key: "3333", label: "[3333] Depth, thickness, height, or third dimension, metres" },
    { key: "3334", label: "[3334] Depth, thickness, height, or third dimension, metres" },
    { key: "3335", label: "[3335] Depth, thickness, height, or third dimension, metres" },
    { key: "3340", label: "[3340] Area, square metres" },
    { key: "3341", label: "[3341] Area, square metres" },
    { key: "3342", label: "[3342] Area, square metres" },
    { key: "3343", label: "[3343] Area, square metres" },
    { key: "3344", label: "[3344] Area, square metres" },
    { key: "3345", label: "[3345] Area, square metres" },
    { key: "3350", label: "[3350] Logistic volume, litres" },
    { key: "3351", label: "[3351] Logistic volume, litres" },
    { key: "3352", label: "[3352] Logistic volume, litres" },
    { key: "3353", label: "[3353] Logistic volume, litres" },
    { key: "3354", label: "[3354] Logistic volume, litres" },
    { key: "3355", label: "[3355] Logistic volume, litres" },
    { key: "3360", label: "[3360] Logistic volume, cubic metres" },
    { key: "3361", label: "[3361] Logistic volume, cubic metres" },
    { key: "3362", label: "[3362] Logistic volume, cubic metres" },
    { key: "3363", label: "[3363] Logistic volume, cubic metres" },
    { key: "3364", label: "[3364] Logistic volume, cubic metres" },
    { key: "3365", label: "[3365] Logistic volume, cubic metres" },
    { key: "3370", label: "[3370] Kilograms per square metre" },
    { key: "3371", label: "[3371] Kilograms per square metre" },
    { key: "3372", label: "[3372] Kilograms per square metre" },
    { key: "3373", label: "[3373] Kilograms per square metre" },
    { key: "3374", label: "[3374] Kilograms per square metre" },
    { key: "3375", label: "[3375] Kilograms per square metre" },
    { key: "3400", label: "[3400] Logistic weight, pounds" },
    { key: "3401", label: "[3401] Logistic weight, pounds" },
    { key: "3402", label: "[3402] Logistic weight, pounds" },
    { key: "3403", label: "[3403] Logistic weight, pounds" },
    { key: "3404", label: "[3404] Logistic weight, pounds" },
    { key: "3405", label: "[3405] Logistic weight, pounds" },
    { key: "3410", label: "[3410] Length or first dimension, inches" },
    { key: "3411", label: "[3411] Length or first dimension, inches" },
    { key: "3412", label: "[3412] Length or first dimension, inches" },
    { key: "3413", label: "[3413] Length or first dimension, inches" },
    { key: "3414", label: "[3414] Length or first dimension, inches" },
    { key: "3415", label: "[3415] Length or first dimension, inches" },
    { key: "3420", label: "[3420] Length or first dimension, feet" },
    { key: "3421", label: "[3421] Length or first dimension, feet" },
    { key: "3422", label: "[3422] Length or first dimension, feet" },
    { key: "3423", label: "[3423] Length or first dimension, feet" },
    { key: "3424", label: "[3424] Length or first dimension, feet" },
    { key: "3425", label: "[3425] Length or first dimension, feet" },
    { key: "3430", label: "[3430] Length or first dimension, yards" },
    { key: "3431", label: "[3431] Length or first dimension, yards" },
    { key: "3432", label: "[3432] Length or first dimension, yards" },
    { key: "3433", label: "[3433] Length or first dimension, yards" },
    { key: "3434", label: "[3434] Length or first dimension, yards" },
    { key: "3435", label: "[3435] Length or first dimension, yards" },
    { key: "3440", label: "[3440] Width, diameter, or second dimension, inches" },
    { key: "3441", label: "[3441] Width, diameter, or second dimension, inches" },
    { key: "3442", label: "[3442] Width, diameter, or second dimension, inches" },
    { key: "3443", label: "[3443] Width, diameter, or second dimension, inches" },
    { key: "3444", label: "[3444] Width, diameter, or second dimension, inches" },
    { key: "3445", label: "[3445] Width, diameter, or second dimension, inches" },
    { key: "3450", label: "[3450] Width, diameter, or second dimension, feet" },
    { key: "3451", label: "[3451] Width, diameter, or second dimension, feet" },
    { key: "3452", label: "[3452] Width, diameter, or second dimension, feet" },
    { key: "3453", label: "[3453] Width, diameter, or second dimension, feet" },
    { key: "3454", label: "[3454] Width, diameter, or second dimension, feet" },
    { key: "3455", label: "[3455] Width, diameter, or second dimension, feet" },
    { key: "3460", label: "[3460] Width, diameter, or second dimension, yard" },
    { key: "3461", label: "[3461] Width, diameter, or second dimension, yard" },
    { key: "3462", label: "[3462] Width, diameter, or second dimension, yard" },
    { key: "3463", label: "[3463] Width, diameter, or second dimension, yard" },
    { key: "3464", label: "[3464] Width, diameter, or second dimension, yard" },
    { key: "3465", label: "[3465] Width, diameter, or second dimension, yard" },
    { key: "3470", label: "[3470] Depth, thickness, height, or third dimension, inches" },
    { key: "3471", label: "[3471] Depth, thickness, height, or third dimension, inches" },
    { key: "3472", label: "[3472] Depth, thickness, height, or third dimension, inches" },
    { key: "3743", label: "[3473] Depth, thickness, height, or third dimension, inches" },
    { key: "3474", label: "[3474] Depth, thickness, height, or third dimension, inches" },
    { key: "3475", label: "[3475] Depth, thickness, height, or third dimension, inches" },
    { key: "3480", label: "[3480] Depth, thickness, height, or third dimension, feet" },
    { key: "3481", label: "[3481] Depth, thickness, height, or third dimension, feet" },
    { key: "3482", label: "[3482] Depth, thickness, height, or third dimension, feet" },
    { key: "3483", label: "[3483] Depth, thickness, height, or third dimension, feet" },
    { key: "3484", label: "[3484] Depth, thickness, height, or third dimension, feet" },
    { key: "3485", label: "[3485] Depth, thickness, height, or third dimension, feet" },
    { key: "3490", label: "[3490] Depth, thickness, height, or third dimension, yards" },
    { key: "3491", label: "[3491] Depth, thickness, height, or third dimension, yards" },
    { key: "3492", label: "[3492] Depth, thickness, height, or third dimension, yards" },
    { key: "3493", label: "[3493] Depth, thickness, height, or third dimension, yards" },
    { key: "3494", label: "[3494] Depth, thickness, height, or third dimension, yards" },
    { key: "3495", label: "[3495] Depth, thickness, height, or third dimension, yards" },
    { key: "3500", label: "[3500] Area, square inches (variable measure trade item)" },
    { key: "3501", label: "[3501] Area, square inches (variable measure trade item)" },
    { key: "3502", label: "[3502] Area, square inches (variable measure trade item)" },
    { key: "3503", label: "[3503] Area, square inches (variable measure trade item)" },
    { key: "3504", label: "[3504] Area, square inches (variable measure trade item)" },
    { key: "3505", label: "[3505] Area, square inches (variable measure trade item)" },
    { key: "3510", label: "[3510] Area, square feet (variable measure trade item)" },
    { key: "3511", label: "[3511] Area, square feet (variable measure trade item)" },
    { key: "3512", label: "[3512] Area, square feet (variable measure trade item)" },
    { key: "3513", label: "[3513] Area, square feet (variable measure trade item)" },
    { key: "3514", label: "[3514] Area, square feet (variable measure trade item)" },
    { key: "3515", label: "[3515] Area, square feet (variable measure trade item)" },
    { key: "3520", label: "[3520] Area, square yards (variable measure trade item)" },
    { key: "3521", label: "[3521] Area, square yards (variable measure trade item)" },
    { key: "3522", label: "[3522] Area, square yards (variable measure trade item)" },
    { key: "3523", label: "[3523] Area, square yards (variable measure trade item)" },
    { key: "3524", label: "[3524] Area, square yards (variable measure trade item)" },
    { key: "3525", label: "[3525] Area, square yards (variable measure trade item)" },
    { key: "3530", label: "[3530] Area, square inches" },
    { key: "3531", label: "[3531] Area, square inches" },
    { key: "3532", label: "[3532] Area, square inches" },
    { key: "3533", label: "[3533] Area, square inches" },
    { key: "3534", label: "[3534] Area, square inches" },
    { key: "3535", label: "[3535] Area, square inches" },
    { key: "3540", label: "[3540] Area, square feet" },
    { key: "3541", label: "[3541] Area, square feet" },
    { key: "3542", label: "[3542] Area, square feet" },
    { key: "3543", label: "[3543] Area, square feet" },
    { key: "3544", label: "[3544] Area, square feet" },
    { key: "3545", label: "[3545] Area, square feet" },
    { key: "3550", label: "[3550] Area, square yards" },
    { key: "3551", label: "[3551] Area, square yards" },
    { key: "3552", label: "[3552] Area, square yards" },
    { key: "3553", label: "[3553] Area, square yards" },
    { key: "3554", label: "[3554] Area, square yards" },
    { key: "3555", label: "[3555] Area, square yards" },
    { key: "3560", label: "[3560] Net weight, troy ounces (variable measure trade item)" },
    { key: "3561", label: "[3561] Net weight, troy ounces (variable measure trade item)" },
    { key: "3562", label: "[3562] Net weight, troy ounces (variable measure trade item)" },
    { key: "3563", label: "[3563] Net weight, troy ounces (variable measure trade item)" },
    { key: "3564", label: "[3564] Net weight, troy ounces (variable measure trade item)" },
    { key: "3565", label: "[3565] Net weight, troy ounces (variable measure trade item)" },
    { key: "3570", label: "[3570] Net weight (or volume), ounces (variable measure trade item)" },
    { key: "3571", label: "[3571] Net weight (or volume), ounces (variable measure trade item)" },
    { key: "3572", label: "[3572] Net weight (or volume), ounces (variable measure trade item)" },
    { key: "3573", label: "[3573] Net weight (or volume), ounces (variable measure trade item)" },
    { key: "3574", label: "[3574] Net weight (or volume), ounces (variable measure trade item)" },
    { key: "3575", label: "[3575] Net weight (or volume), ounces (variable measure trade item)" },
    { key: "3600", label: "[3600] Net volume, quarts (variable measure trade item)" },
    { key: "3601", label: "[3601] Net volume, quarts (variable measure trade item)" },
    { key: "3602", label: "[3602] Net volume, quarts (variable measure trade item)" },
    { key: "3603", label: "[3603] Net volume, quarts (variable measure trade item)" },
    { key: "3604", label: "[3604] Net volume, quarts (variable measure trade item)" },
    { key: "3605", label: "[3605] Net volume, quarts (variable measure trade item)" },
    { key: "3610", label: "[3610] Net volume, gallons U.S. (variable measure trade item)" },
    { key: "3611", label: "[3611] Net volume, gallons U.S. (variable measure trade item)" },
    { key: "3612", label: "[3612] Net volume, gallons U.S. (variable measure trade item)" },
    { key: "3613", label: "[3613] Net volume, gallons U.S. (variable measure trade item)" },
    { key: "3614", label: "[3614] Net volume, gallons U.S. (variable measure trade item)" },
    { key: "3615", label: "[3615] Net volume, gallons U.S. (variable measure trade item)" },
    { key: "3620", label: "[3620] Logistic volume, quarts" },
    { key: "3621", label: "[3621] Logistic volume, quarts" },
    { key: "3622", label: "[3622] Logistic volume, quarts" },
    { key: "3623", label: "[3623] Logistic volume, quarts" },
    { key: "3624", label: "[3624] Logistic volume, quarts" },
    { key: "3625", label: "[3625] Logistic volume, quarts" },
    { key: "3630", label: "[3630] Logistic volume, gallons U.S." },
    { key: "3631", label: "[3631] Logistic volume, gallons U.S." },
    { key: "3632", label: "[3632] Logistic volume, gallons U.S." },
    { key: "3633", label: "[3633] Logistic volume, gallons U.S." },
    { key: "3634", label: "[3634] Logistic volume, gallons U.S." },
    { key: "3635", label: "[3635] Logistic volume, gallons U.S." },
    { key: "3640", label: "[3640] Net volume, cubic inches (variable measure trade item)" },
    { key: "3641", label: "[3641] Net volume, cubic inches (variable measure trade item)" },
    { key: "3642", label: "[3642] Net volume, cubic inches (variable measure trade item)" },
    { key: "3643", label: "[3643] Net volume, cubic inches (variable measure trade item)" },
    { key: "3644", label: "[3644] Net volume, cubic inches (variable measure trade item)" },
    { key: "3645", label: "[3645] Net volume, cubic inches (variable measure trade item)" },
    { key: "3650", label: "[3650] Net volume, cubic feet (variable measure trade item)" },
    { key: "3651", label: "[3651] Net volume, cubic feet (variable measure trade item)" },
    { key: "3652", label: "[3652] Net volume, cubic feet (variable measure trade item)" },
    { key: "3653", label: "[3653] Net volume, cubic feet (variable measure trade item)" },
    { key: "3654", label: "[3654] Net volume, cubic feet (variable measure trade item)" },
    { key: "3655", label: "[3655] Net volume, cubic feet (variable measure trade item)" },
    { key: "3660", label: "[3660] Net volume, cubic yards (variable measure trade item)" },
    { key: "3661", label: "[3661] Net volume, cubic yards (variable measure trade item)" },
    { key: "3662", label: "[3662] Net volume, cubic yards (variable measure trade item)" },
    { key: "3663", label: "[3663] Net volume, cubic yards (variable measure trade item)" },
    { key: "3664", label: "[3664] Net volume, cubic yards (variable measure trade item)" },
    { key: "3665", label: "[3665] Net volume, cubic yards (variable measure trade item)" },
    { key: "3670", label: "[3670] Logistic volume, cubic inches" },
    { key: "3671", label: "[3671] Logistic volume, cubic inches" },
    { key: "3672", label: "[3672] Logistic volume, cubic inches" },
    { key: "3673", label: "[3673] Logistic volume, cubic inches" },
    { key: "3674", label: "[3674] Logistic volume, cubic inches" },
    { key: "3675", label: "[3675] Logistic volume, cubic inches" },
    { key: "3680", label: "[3680] Logistic volume, cubic feet" },
    { key: "3681", label: "[3681] Logistic volume, cubic feet" },
    { key: "3682", label: "[3682] Logistic volume, cubic feet" },
    { key: "3683", label: "[3683] Logistic volume, cubic feet" },
    { key: "3684", label: "[3684] Logistic volume, cubic feet" },
    { key: "3685", label: "[3685] Logistic volume, cubic feet" },
    { key: "3690", label: "[3690] Logistic volume, cubic yards" },
    { key: "3691", label: "[3691] Logistic volume, cubic yards" },
    { key: "3692", label: "[3692] Logistic volume, cubic yards" },
    { key: "3693", label: "[3693] Logistic volume, cubic yards" },
    { key: "3694", label: "[3694] Logistic volume, cubic yards" },
    { key: "3695", label: "[3695] Logistic volume, cubic yards" },
    { key: "37", label: "[37] Count of trade items" },
    { key: "3900", label: "[3900] Applicable amount payable or Coupon value, local currency" },
    { key: "3901", label: "[3901] Applicable amount payable or Coupon value, local currency" },
    { key: "3902", label: "[3902] Applicable amount payable or Coupon value, local currency" },
    { key: "3903", label: "[3903] Applicable amount payable or Coupon value, local currency" },
    { key: "39004", label: "[3904] Applicable amount payable or Coupon value, local currency" },
    { key: "3905", label: "[3905] Applicable amount payable or Coupon value, local currency" },
    { key: "3906", label: "[3906] Applicable amount payable or Coupon value, local currency" },
    { key: "3907", label: "[3907] Applicable amount payable or Coupon value, local currency" },
    { key: "3908", label: "[3908] Applicable amount payable or Coupon value, local currency" },
    { key: "3909", label: "[3909] Applicable amount payable or Coupon value, local currency" },
    { key: "3910", label: "[3910] Applicable amount payable with ISO currency code" },
    { key: "3911", label: "[3911] Applicable amount payable with ISO currency code" },
    { key: "3912", label: "[3912] Applicable amount payable with ISO currency code" },
    { key: "3913", label: "[3913] Applicable amount payable with ISO currency code" },
    { key: "3914", label: "[3914] Applicable amount payable with ISO currency code" },
    { key: "3915", label: "[3915] Applicable amount payable with ISO currency code" },
    { key: "3916", label: "[3916] Applicable amount payable with ISO currency code" },
    { key: "3917", label: "[3917] Applicable amount payable with ISO currency code" },
    { key: "3918", label: "[3918] Applicable amount payable with ISO currency code" },
    { key: "3919", label: "[3919] Applicable amount payable with ISO currency code" },
    { key: "3920", label: "[3920] Applicable amount payable, single monetary area (variable measure trade item)" },
    { key: "3921", label: "[3921] Applicable amount payable, single monetary area (variable measure trade item)" },
    { key: "3922", label: "[3922] Applicable amount payable, single monetary area (variable measure trade item)" },
    { key: "3923", label: "[3923] Applicable amount payable, single monetary area (variable measure trade item)" },
    { key: "3924", label: "[3924] Applicable amount payable, single monetary area (variable measure trade item)" },
    { key: "3925", label: "[3925] Applicable amount payable, single monetary area (variable measure trade item)" },
    { key: "3926", label: "[3926] Applicable amount payable, single monetary area (variable measure trade item)" },
    { key: "3927", label: "[3927] Applicable amount payable, single monetary area (variable measure trade item)" },
    { key: "3928", label: "[3928] Applicable amount payable, single monetary area (variable measure trade item)" },
    { key: "3929", label: "[3929] Applicable amount payable, single monetary area (variable measure trade item)" },
    { key: "3930", label: "[3930] Applicable amount payable with ISO currency code (variable measure trade item)" },
    { key: "3931", label: "[3931] Applicable amount payable with ISO currency code (variable measure trade item)" },
    { key: "3932", label: "[3932] Applicable amount payable with ISO currency code (variable measure trade item)" },
    { key: "3933", label: "[3933] Applicable amount payable with ISO currency code (variable measure trade item)" },
    { key: "3934", label: "[3934] Applicable amount payable with ISO currency code (variable measure trade item)" },
    { key: "3935", label: "[3935] Applicable amount payable with ISO currency code (variable measure trade item)" },
    { key: "3936", label: "[3936] Applicable amount payable with ISO currency code (variable measure trade item)" },
    { key: "3937", label: "[3937] Applicable amount payable with ISO currency code (variable measure trade item)" },
    { key: "3938", label: "[3938] Applicable amount payable with ISO currency code (variable measure trade item)" },
    { key: "3939", label: "[3939] Applicable amount payable with ISO currency code (variable measure trade item)" },
    { key: "3940", label: "[3940] Percentage discount of a coupon" },
    { key: "3941", label: "[3941] Percentage discount of a coupon" },
    { key: "3942", label: "[3942] Percentage discount of a coupon" },
    { key: "3943", label: "[3943] Percentage discount of a coupon" },
    { key: "3950", label: "[3950] Amount payable per unit of measure single monetary area (variable measure trade item). No decimal point" },
    { key: "3951", label: "[3951] Amount payable per unit of measure single monetary area (variable measure trade item). One decimal place" },
    { key: "3952", label: "[3952] Amount payable per unit of measure single monetary area (variable measure trade item). Two decimal places" },
    { key: "3953", label: "[3953] Amount payable per unit of measure single monetary area (variable measure trade item). Three decimal places" },
    { key: "400", label: "[400] Customer's purchase order number" },
    { key: "403", label: "[403] Routing code" },
    { key: "410", label: "[410] Ship to - Deliver to Global Location Number" },
    { key: "411", label: "[411] Bill to - Invoice to Global Location Number" },
    { key: "412", label: "[412] Purchased from Global Location Number" },
    { key: "413", label: "[413] Ship for - Deliver for - Forward to Global Location Number" },
    { key: "416", label: "[416] GLN of the production or service location" },
    { key: "420", label: "[420] Ship to - Deliver to postal code within a single postal authority" },
    { key: "421", label: "[421] Ship to - Deliver to postal code with ISO country code" },
    { key: "422", label: "[422] Country of origin of a trade item" },
    { key: "423", label: "[423] Country of initial processing" },
    { key: "424", label: "[424] Country of processing" },
    { key: "425", label: "[425] Country of disassembly" },
    { key: "426", label: "[426] Country covering full process chain" },
    { key: "427", label: "[427] Country subdivision Of origin" },
    { key: "4300", label: "[4300] Ship-to / Deliver-to Company name" },
    { key: "4301", label: "[4301] Ship-to / Deliver-to contact name" },
    { key: "4302", label: "[4302] Ship-to / Deliver-to address line 1" },
    { key: "4303", label: "[4303] Ship-to / Deliver-to address line 2" },
    { key: "4304", label: "[4304] Ship-to / Deliver-to suburb" },
    { key: "4305", label: "[4305] Ship-to / Deliver-to locality" },
    { key: "4306", label: "[4306] Ship-to / Deliver-to region" },
    { key: "4307", label: "[4307] Ship-to / Deliver-to country code" },
    { key: "4308", label: "[4308] Ship-to / Deliver-to telephone number" },
    { key: "4309", label: "[4309] Ship-to / Deliver-to GEO location" },
    { key: "4310", label: "[4310] Return-to company name" },
    { key: "4311", label: "[4311] Return-to contact name" },
    { key: "4312", label: "[4312] Return-to address line 1" },
    { key: "4313", label: "[4313] Return-to address line 2" },
    { key: "4314", label: "[4314] Return-to suburb" },
    { key: "4315", label: "[4315] Return-to locality" },
    { key: "4316", label: "[4316] Return-to region" },
    { key: "4317", label: "[4317] Return-to country code" },
    { key: "4318", label: "[4318] Return-to postal code" },
    { key: "4319", label: "[4319] Return-to telephone number" },
    { key: "4320", label: "[4320] Service code description" },
    { key: "4321", label: "[4321] Dangerous goods flag" },
    { key: "4322", label: "[4322] Authority to leave flag" },
    { key: "4323", label: "[4323] Signature required flag" },
    { key: "4324", label: "[4324] Not before delivery date/time" },
    { key: "4325", label: "[4325] Not after delivery date/time" },
    { key: "4326", label: "[4326] Release date" },
    { key: "7001", label: "[7001] NATO Stock Number (NSN)" },
    { key: "7002", label: "[7002] UN/ECE meat carcasses and cuts classification" },
    { key: "7003", label: "[7003] Expiration date and time" },
    { key: "7004", label: "[7004] Active potency" },
    { key: "7005", label: "[7005] Catch area" },
    { key: "7006", label: "[7006] First freeze date" },
    { key: "7007", label: "[7007] Harvest date" },
    { key: "7008", label: "[7008] Species for fishery purposes" },
    { key: "7009", label: "[7009] Fishing gear type" },
    { key: "7010", label: "[7010] Production method" },
    { key: "7020", label: "[7020] Refurbishment lot ID" },
    { key: "7021", label: "[7021] Functional status" },
    { key: "7022", label: "[7022] Revision status" },
    { key: "7023", label: "[7023] Global Individual Asset Identifier (GIAI) of an assembly" },
    { key: "7030", label: "[7030] Number of processor with ISO Country Code" },
    { key: "7031", label: "[7031] Number of processor with ISO Country Code" },
    { key: "7032", label: "[7032] Number of processor with ISO Country Code" },
    { key: "7033", label: "[7033] Number of processor with ISO Country Code" },
    { key: "7034", label: "[7034] Number of processor with ISO Country Code" },
    { key: "7035", label: "[7035] Number of processor with ISO Country Code" },
    { key: "7036", label: "[7036] Number of processor with ISO Country Code" },
    { key: "7037", label: "[7037] Number of processor with ISO Country Code" },
    { key: "7038", label: "[7038] Number of processor with ISO Country Code" },
    { key: "7039", label: "[7039] Number of processor with ISO Country Code" },
    { key: "7340", label: "[7040] GS1 UIC with Extension 1 and Importer index" },
    { key: "710", label: "[710] National Healthcare Reimbursement Number (NHRN) - Germany PZN" },
    { key: "711", label: "[711] National Healthcare Reimbursement Number (NHRN) - France CIP" },
    { key: "712", label: "[712] National Healthcare Reimbursement Number (NHRN) - Spain CN" },
    { key: "713", label: "[713] National Healthcare Reimbursement Number (NHRN) - Brasil DRN" },
    { key: "714", label: "[714] National Healthcare Reimbursement Number (NHRN) - Portugal AIM" },
    { key: "7230", label: "[7230] Certification reference # 0" },
    { key: "7231", label: "[7231] Certification reference # 1" },
    { key: "7232", label: "[7232] Certification reference # 2" },
    { key: "7233", label: "[7233] Certification reference # 3" },
    { key: "7234", label: "[7234] Certification reference # 4" },
    { key: "7235", label: "[7235] Certification reference # 5" },
    { key: "7236", label: "[7236] Certification reference # 6" },
    { key: "7237", label: "[7237] Certification reference # 7" },
    { key: "7238", label: "[7238] Certification reference # 8" },
    { key: "7239", label: "[7239] Certification reference # 9" },
    { key: "8001", label: "[8001] Roll products (width, length, core diameter, direction, splices)" },
    { key: "8002", label: "[8002] Cellular mobile telephone identifier" },
    { key: "8005", label: "[8005] Price per unit of measure" },
    { key: "8007", label: "[8007] International Bank Account Number (IBAN)" },
    { key: "8008", label: "[8008] Date and time of production" },
    { key: "8009", label: "[8009] Optically Readable Sensor Indicator" },
    { key: "8012", label: "[8012] Software version" },
    { key: "8013", label: "[8013] Global Model Number (GMN)" },
    { key: "8020", label: "[8020] Payment slip reference number" },
    { key: "8026", label: "[8026] Identification of pieces of a trade item contained in a logistics unit" },
    { key: "8110", label: "[8110] Coupon code identification for use in North America" },
    { key: "8111", label: "[8111] Loyalty points of a coupon" },
    { key: "8112", label: "[8112] Paperless coupon code identification for use in North America" },
    { key: "8200", label: "[8200] Extended Packaging URL" },
    { key: "90", label: "[90] Information mutually agreed between trading partners" },
    { key: "91", label: "[91] Company internal information" },
    { key: "92", label: "[92] Company internal information" },
    { key: "93", label: "[93] Company internal information" },
    { key: "94", label: "[94] Company internal information" },
    { key: "95", label: "[95] Company internal information" },
    { key: "96", label: "[96] Company internal information" },
    { key: "97", label: "[97] Company internal information" },
    { key: "98", label: "[98] Company internal information" },
    { key: "99", label: "[99] Company internal information" },
]

export default dataAttributes