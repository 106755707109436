import React, {useEffect, useState} from "react"
import {Link, useLocation, useNavigate} from "react-router-dom"
// MUI
import {Box, Button, Container, Grid} from "@mui/material";
import {ArrowLeft, Save} from "@mui/icons-material";
// Components
import {AppHeader} from "@/components/DashboardComponents";
// Translation
import {useTranslation} from "react-i18next";

import {useData} from "@/context/PackagingDataProvider";
import AppEditor from "@/components/Apps/AppsEditor/AppEditor";

export default function AppBuilder(props: any) {

    const location = useLocation();
    const redirect = useNavigate()

    const {editing} = props
    const {editAny, getSingleApp} = useData()
    const {t} = useTranslation(["apps", "common"])

    // Data
    const [app, setApp] = useState<any>({
        id: "",
        brands_id: [],
        default: true,
        geo_ip_fallback: true,
        layout: {
        },
        translations: []
    })

    useEffect(() => {
        if (editing) {
            let editedApp = location.state
            getSingleApp(editedApp.id).then((res: any) => {
                setApp(res)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const handleSaveApp = () => {
        editAny(app, "app").then((res: any) => {
            if (res) redirect("/?tab=apps")
        })
    }

    return (
        <>
            <Container maxWidth={"xl"} sx={{ pt: "32px" }}>
                <AppHeader
                    paths={[{name: "common:packaging", path: "/"}]}
                    positionName={"app_builder"}
                    title={"app_builder"}
                />

                <Grid container justifyContent={"space-between"} rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12}>
                        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                            <Button
                                startIcon={<ArrowLeft />}
                                component={Link}
                                to={"/?tab=apps"}
                                color={"secondary"}
                                variant={"outlined"}
                            >
                                {t("common:back")}
                            </Button>
                            <Button
                                startIcon={<Save />}
                                color={"primary"}
                                variant={"contained"}
                                disableElevation
                                onClick={() => handleSaveApp()}
                            >
                                {t("common:save")}
                            </Button>
                        </Box>
                    </Grid>

                    <AppEditor{...{app, setApp, t}}/>
                </Grid>
            </Container>
        </>
    )
}