import React from 'react';
import {useNavigate} from "react-router-dom";
// MUI
import {
    Box,
    Breadcrumbs,
    Grid,
    Link as MaterialLink,
    Paper,
    Typography,
    Button,
    Tooltip,
    IconButton, Menu, MenuItem,
} from '@mui/material';
// TRANSLATION
import {useTranslation} from "react-i18next";
import {ViewModuleRounded} from "@mui/icons-material";
import {useModule} from "@/context/ModuleProvider";

const {REACT_APP_DASHBOARD_URL} = process.env

type Paths = {
    name: string;
    path: string;
}

type AppHeaderProps = {
    paths: Paths[];
    positionName: string; // exemple : Packaging_control_panel
    title: string;
    subTitle?: string;
    docLabel?: string;
    docLink?: string;
    docComingSoon?: boolean;
    blocking?: any
    onBlock?: any
    onUnblock?: any
}

function NaviagtionLink(props: any) {

    const {href, blocking, onBlock, type, label} = props
    const redirect = useNavigate()


    // navigation blocker
    const onNavigate = () => {
        if (blocking) onBlock()
        else {
            if (type === "inner") redirect(href)
            else {
                let a = document.createElement('a')
                a.href = href
                a.target = "_parent"
                a.click()
            }
        }
    }

    // return either a Link or <a>
    if (type === "inner") {
        return (
            <MaterialLink color="inherit" onClick={() => onNavigate()}>
                {label}
            </MaterialLink>
        )
    } else {
        return (
            <Typography onClick={() => onNavigate()} color={"inherit"}>
                {label}
            </Typography>
        )
    }

}

export default function AppHeader(props: AppHeaderProps) {

    const {t} = useTranslation(['common']);
    const { title, subTitle, docLabel, docLink, paths, docComingSoon, blocking, onBlock } = props;
    const {modules, setModules} = useModule()

    const styles = {
        headerWrapper: {
            justifyContent: {
                xs: 'center',
                md: 'space-between'
            },
            mb: 2,
            "& a": {
                textDecoration: "none"
            }
        },
        titleWrapper: { my: 3 },
        docButtonWrapper: { mt: 3 },
        docPaper: { py: 3, px: 5, textAlign: "center", borderRadius: 5  }
    };

    const [devMenuAnchor, setDevMenuAnchor] = React.useState<null | HTMLElement>(null);


    const renderModuleDevMenu = () => {
        const allowed = process.env.REACT_APP_ENV === "dev"

        if (!allowed) return <></>

        const getStyles = (active: any) => {
            return {
                backgroundColor: active ? "rgba(175,201,40,0.47)" : "initial",
                "&:hover": { backgroundColor: active ? "rgba(175,201,40,0.71)" : "rgba(0,0,0,0.04)" }
            }
        }

        const moduleDevMenu = () => (
            <Menu
                anchorEl={devMenuAnchor}
                open={Boolean(devMenuAnchor)}
                onClose={() => setDevMenuAnchor(null)}
                MenuListProps={{
                    dense: true
                }}
            >
                <MenuItem sx={getStyles(modules.geoloc)} onClick={() => setModules({...modules, geoloc: !modules.geoloc})}>
                    Geoloc
                </MenuItem>
                <MenuItem sx={getStyles(modules.gs1)} onClick={() => setModules({...modules, gs1: !modules.gs1})}>
                    GS1
                </MenuItem>
            </Menu>
        )

        return (
            <>
                <Tooltip title={"Manage modules (dev)"} placement={"top"} arrow>
                    <IconButton sx={{ color: "black" }} onClick={(e) => setDevMenuAnchor(e.currentTarget)}>
                        <ViewModuleRounded />
                    </IconButton>
                </Tooltip>
                {moduleDevMenu()}
            </>
        )
    }

    return (
        <>
            <Grid container sx={styles.headerWrapper}>
                <Grid item xs={12} sm={8} md={7} xl={9}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <NaviagtionLink
                            type={"outer"}
                            href={`${REACT_APP_DASHBOARD_URL}`}
                            label={t("unitag_console")}
                            onBlock={() => onBlock(`${REACT_APP_DASHBOARD_URL}`)}
                            {...{blocking}}
                        />
                        <NaviagtionLink
                            type={"outer"}
                            href={`${REACT_APP_DASHBOARD_URL}/applications`}
                            label={t("unitag_apps")}
                            onBlock={() => onBlock(`${REACT_APP_DASHBOARD_URL}/applications`)}
                            {...{blocking}}
                        />

                        {paths && paths.map((path, index) => {
                            return (
                                <NaviagtionLink
                                    key={index}
                                    type={"inner"}
                                    href={path.path}
                                    label={t(path.name)}
                                    onBlock={() => onBlock(path.path)}
                                    {...{blocking}}
                                />
                            );
                        })}
                        <Typography color="textPrimary">
                            {t(props.positionName)}
                        </Typography>
                    </Breadcrumbs>

                    <Box sx={styles.titleWrapper}>
                        <Typography component="h1" variant={"h1"}>
                            {t(title)}
                            {renderModuleDevMenu()}
                        </Typography>
                        {
                            subTitle &&
                            <Typography
                                component="h2"
                                variant={"h5"}
                                color={"textSecondary"}
                                fontWeight={"400"}
                            >
                                {t(subTitle)}
                            </Typography>
                        }
                    </Box>
                </Grid>

                { (docLabel && docLink) &&
                    <Grid item xs={11} sm={4} md={4} xl={3}>
                        <Paper variant={"outlined"} sx={styles.docPaper}>
                            <Typography variant="body2">
                                {t(docLabel)}
                            </Typography>
                            <Box sx={styles.docButtonWrapper}>
                                <Button
                                    variant={"contained"}
                                    size={"small"}
                                    href={docLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    disabled={docComingSoon}
                                >
                                    {docComingSoon ? t("coming_soon") : t("Documentation")}
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                }
            </Grid>
        </>
    )
}