import React from "react"
import GeneratorEyeColor from "./components/GeneratorEyeColor";
import {Box, Grid, Typography} from "@mui/material";


export default function GeneratorEyesPanel(props: any) {

    const {qrcode, setQRCode, t} = props

    const eyesExt = [
        { key: "color_ext_top_left", value: qrcode.settings.eyes.color_ext_top_left, x: 0 },
        { key: "color_ext_top_right", value: qrcode.settings.eyes.color_ext_top_right, x: "-100px" },
        { key: "color_ext_bottom_left", value: qrcode.settings.eyes.color_ext_bottom_left, x: "-200px" }
    ]

    const eyesInt = [
        { key: "color_int_top_left", value: qrcode.settings.eyes.color_int_top_left, x: "-50px" },
        { key: "color_int_top_right", value: qrcode.settings.eyes.color_int_top_right, x: "-150px" },
        { key: "color_int_bottom_left", value: qrcode.settings.eyes.color_int_bottom_left, x: "-250px" }
    ]

    const handleEnableEye = (key: any, value: any) => {
        const _qrcode = {...qrcode};
        if (value === "") _qrcode.settings.eyes = { ..._qrcode.settings.eyes, [key]: qrcode.settings.layout.color_one }
        else _qrcode.settings.eyes = { ..._qrcode.settings.eyes, [key]: "" }
        setQRCode(_qrcode);
    }

    const handleChangeEyeColor = (key: any, color: any) => {
        const _qrcode = {...qrcode};
        _qrcode.settings.eyes = { ..._qrcode.settings.eyes, [key]: color }
        setQRCode(_qrcode);
    }

    return (
        <Box>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={6}>
                    <Typography variant={"h6"} component={"p"} align={"center"}>{t("products:eyes_ext")}</Typography>
                    <Grid container spacing={3} sx={{ pt: 1 }}>
                        {eyesExt.map((eye: any, index: number) => (
                            <GeneratorEyeColor
                                key={index}
                                eye={eye}
                                initialColor={eye.value}
                                onChangeComplete={(color: any) => handleChangeEyeColor(eye.key, color)}
                                enabling={() => handleEnableEye(eye.key, eye.value)}
                                x={eye.x}
                                {...{t}}
                            />
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant={"h6"} component={"p"} align={"center"}>{t("products:eyes_int")}</Typography>
                    <Grid container spacing={3} sx={{ pt: 1 }}>
                        {eyesInt.map((eye: any, index: number) => (
                            <GeneratorEyeColor
                                key={index}
                                eye={eye}
                                initialColor={eye.value}
                                onChangeComplete={(color: any) => handleChangeEyeColor(eye.key, color)}
                                enabling={() => handleEnableEye(eye.key, eye.value)}
                                x={eye.x}
                                {...{t}}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}