import React from "react"
import {Box, TextField} from "@mui/material"
import {Autocomplete} from "@mui/material";
import {countriesEN} from "@/utils";
import ReactCountryFlag from "react-country-flag";

export default function CountrySelect(props: any) {

    const {country, setCountry, t} = props

    let options = countriesEN.sort(
        (a, b) =>
            (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)
    )

    return (
        <Autocomplete
            id="country-select"
            value={country}
            autoHighlight
            size={"small"}
            options={options}
            onChange={(event: any, val: any | null) => { if (!!val) setCountry(val) }}
            getOptionLabel={(option: any) => option.label || ""}
            renderOption={(renderProps, option: any) => (
                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...renderProps} key={option.code}>
                    <span>
                        <ReactCountryFlag style={{marginRight: "10px"}} countryCode={option.code} svg/>
                    </span>
                    {option.label}
                </Box>
            )}
            renderInput={(params: any) => (
                <TextField {...params} label={`${t("Country")}`} variant="outlined" inputProps={{...params.inputProps}}/>
            )}
        />
    )
}