import React from "react"
import {Grid, Typography, FormControl, Select, MenuItem} from "@mui/material";
import {Accordion, GeneratorColorPicker} from "@/components/UI";

export default function GeneratorColorsPanel(props: any) {

    const {qrcode, setQRCode, t} = props

    const styles = {
        pickerLabel: {
            fontWeight: 500,
            fontSize: "15px",
            color: "rgb(103,103,103)",
            mb: 1
        }
    }

    const changeLayoutType = (layout: string) => {
        if (layout !== qrcode.settings.layout.type) {
            let _qrcode = {...qrcode};
            _qrcode.settings.layout.type = layout;
            setQRCode(_qrcode);
        }
    }

    const changePrimaryColor = (color: string) => {
        if (color !== qrcode.settings.layout.color_one) {
            let _qrcode = {...qrcode};
            _qrcode.settings.layout.color_one = color;
            setQRCode(_qrcode);
        }
    }

    const changeSecondaryColor = (color: string) => {
        if (color !== qrcode.settings.layout.color_two) {
            let _qrcode = {...qrcode};
            _qrcode.settings.layout.color_two = color;
            setQRCode(_qrcode);
        }
    }

    const changeGradientType = (e: any) => {
        if (e.target.value !== qrcode.settings.layout.gradient_type) {
            let _qrcode = {...qrcode};
            _qrcode.settings.layout.gradient_type = e.target.value;
            setQRCode(_qrcode);
        }
    }

    const renderSingleColor = () => {
        return (
            <Accordion
                expanded={qrcode.settings.layout.type === "single_color"}
                onChange={() => changeLayoutType("single_color")}
                title={t("products:single_color")}
            >
                <Grid container justifyContent={"flex-start"}>
                    <Grid item xs={12}>
                        <Typography align="center" sx={styles.pickerLabel}>{t("products:primary_color")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <GeneratorColorPicker
                            initialColor={qrcode.settings.layout.color_one}
                            onChangeComplete={changePrimaryColor}
                        />
                    </Grid>
                </Grid>
            </Accordion>
        )
    }

    const renderGradientColor = () => {
        return (
            <Accordion
                expanded={qrcode.settings.layout.type === "gradient"}
                onChange={() => changeLayoutType("gradient")}
                title={t("products:gradient")}
            >
                <Grid container spacing={{ xs: 3, md: 1, lg: 3 }} justifyContent="center" alignContent="stretch">
                    <Grid item md={4}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography align="center" sx={styles.pickerLabel}>{t("products:primary_color")}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <GeneratorColorPicker
                                    initialColor={qrcode.settings.layout.color_one}
                                    onChangeComplete={changePrimaryColor}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={4}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography align="center" sx={styles.pickerLabel}>{t("products:secondary_color")}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <GeneratorColorPicker
                                    initialColor={qrcode.settings.layout.color_two}
                                    onChangeComplete={changeSecondaryColor}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={4}>
                         <Grid container>
                            <Grid item md={12}>
                                <Typography align="center" sx={styles.pickerLabel}>{t("products:gradient_type")}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" size="small" fullWidth>
                                    <Select
                                        id="gradient-select"
                                        value={qrcode.settings.layout.gradient_type}
                                        onChange={changeGradientType}
                                    >
                                        <MenuItem value={"horizontal"}>{t("products:horizontal")}</MenuItem>
                                        <MenuItem value={"vertical"}>{t("products:vertical")}</MenuItem>
                                        <MenuItem value={"diagonal"}>{t("products:diagonal")}</MenuItem>
                                        <MenuItem value={"diagonal_inverted"}>{t("products:diagonal_inv")}</MenuItem>
                                        <MenuItem value={"radial"}>{t("products:radial")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Accordion>
        )
    }



    return (
        <>
            {renderSingleColor()}
            {renderGradientColor()}
        </>
    )
}