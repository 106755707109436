function formatJSIso8601ToHumanString(dateRaw: string, gmt?: boolean): string {
    const date: Date = new Date(dateRaw);

    let days: number = date.getDate(),
        months: number = date.getMonth() + 1;

    let strDays: string = String(days);
    if (days < 10) {
        strDays = "0" + days;
    }

    let strMonths: string = String(months);
    if (months < 10) {
        strMonths = "0" + months;
    }

    let hours: number = date.getHours();
    let strHours: string = String(hours);
    if (hours < 10) {
        strHours = "0" + hours
    }

    let minutes: number = date.getMinutes()
    let strMinutes: string = String(minutes);
    if (minutes < 10) {
        strMinutes = "0" + minutes
    }

    if(gmt) {
        return `${strDays}/${strMonths}/${date.getFullYear()}, 00:00`;
    }

    return `${strDays}/${strMonths}/${date.getFullYear()} ${strHours}:${strMinutes}`;
}

export {formatJSIso8601ToHumanString}