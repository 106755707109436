import React from "react"
// MUI
import {Grid, Switch} from "@mui/material";
import {CropDin} from "@mui/icons-material"
// Components
import {GeneratorColorPicker} from "@/components/UI";
// Assets
// @ts-ignore
import StylesAssets from "@/assets/generator/qrcodes-styles/qrcodes-styles-sprites.png"

const EyeSelector = (props: any) => {

    const {
        initialColor, onChangeComplete,
        enabling,
        x, t,
    } = props;

    return (
        <>
            <Grid item md={12}>
                <Grid container spacing={3} justifyContent={"center"} alignItems={"center"}>
                    <Grid item>
                        <CropDin
                            sx={{
                                backgroundImage: `url(${StylesAssets})`,
                                backgroundPositionY: "0px",
                                backgroundPositionX: x,
                                fill: "white !important",
                                fillOpacity: "0.0 !important",
                                width: "47px !important",
                                height: "47px !important",
                            }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <GeneratorColorPicker
                            label={t("Color")}
                            {...{initialColor, onChangeComplete}}
                            disabled={initialColor === ""}
                        />
                    </Grid>
                    <Grid item>
                        <Switch
                            checked={initialColor !== ""}
                            color="primary"
                            onChange={enabling}
                            inputProps={{'aria-label': 'eye switch'}}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default EyeSelector