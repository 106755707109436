import React from "react"
// MUI
import {List, ListItemButton, ListItemText} from "@mui/material";
import {Check, Close} from "@mui/icons-material";
// Utils
import {checkIfEmptyArray} from "@/utils";
// UI
import {PaperLabelled} from "@/components/UI";

export default function BrandMultipleSelect(props: any) {

    const {app, setApp, brands, t} = props

    const styles = {
        brandsList: { maxHeight: 350, overflowY: "auto" },
        brandOffIcon: { mr: 1, fill: "#a9a9a9" },
        brandOnIcon: { mr: 1 },
    }

    if (!brands || !app) return <></>

    const handleBrandSelection = (id: any) => {
        if (!id) return

        let associatedBrands = app.brands_id
        let associatedBrand = app.brands_id ? app.brands_id.filter((brand: any) => brand === id) : null

        if (!associatedBrands || associatedBrands.length < 1) associatedBrands = [id]
        else if (!checkIfEmptyArray(associatedBrand)) associatedBrands.splice(associatedBrands.findIndex((brand: any) => brand === id), 1)
        else associatedBrands.push(id)

        setApp({...app, brands_id: associatedBrands})
    }

    return (
        <PaperLabelled label={t("associated_brands")} bold sx={{ mt: 2 }}>
            <List sx={styles.brandsList}>
                {!!brands && brands.map((brand: any, index: number) => {
                    let checked = app.brands_id ? app.brands_id.filter((assocBrand: any) => assocBrand === brand.id) : []
                    return (
                        <ListItemButton onClick={() => handleBrandSelection(brand.id)} key={index}>
                            {(!!app && app.brands_id && !checkIfEmptyArray(checked)) ?
                                <Check color={"primary"} sx={styles.brandOnIcon} />
                                : <Close sx={styles.brandOffIcon} />
                            }
                            <ListItemText primary={brand.name} />
                        </ListItemButton>
                    )
                })}
            </List>
        </PaperLabelled>
    )
}