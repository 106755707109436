import React from "react"
// MUI
import {Grid, Typography, Paper} from "@mui/material"
import {GeneratorEyesList, GeneratorModulesList} from "./components";
// Assets
// @ts-ignore
import StylesAssets from "@/assets/generator/qrcodes-styles/qrcodes-styles-sprites.png"

export default function GeneratorStylesPanel(props: any) {

    const {qrcode, setQRCode, t} = props

    const handleChangeModule = (event: React.MouseEvent<HTMLElement>) => {
        if (qrcode.settings.modules.type !== event.currentTarget.id) {
            const _qrcode = {...qrcode};
            _qrcode.settings.modules.type = event.currentTarget.id;
            setQRCode(_qrcode);
        }
    }

    const handleChangeEye = (event: React.MouseEvent<HTMLElement>) => {
        if (qrcode.settings.eyes.type !== event.currentTarget.id) {
            const _qrcode = {...qrcode};
            _qrcode.settings.eyes.type = event.currentTarget.id;
            setQRCode(_qrcode);
        }
    }

    return (
        <Grid container spacing={3} justifyContent={"center"}>
            <Grid item xs={12} md={6}>
                <Typography variant="h6" component="p" align="center" gutterBottom>{t("products:modules")}</Typography>
                <Paper variant={"outlined"}>
                    <GeneratorModulesList
                        activeModuleStyle={qrcode.settings.modules.type}
                        onChange={handleChangeModule}
                        {...{StylesAssets}}
                    />
                </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="h6" component="p" align="center" gutterBottom>{t("products:eyes")}</Typography>
                <Paper variant={"outlined"}>
                    <GeneratorEyesList
                        activeEyeStyle={qrcode.settings.eyes.type}
                        onChange={handleChangeEye}
                        {...{StylesAssets}}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}