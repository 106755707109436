async function getImageSize(image: any) {
    return new Promise((resolve) => {
        const img = new Image()

        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height
            })
        }

        img.src = URL.createObjectURL(image)
    })
}

export {
    getImageSize
}