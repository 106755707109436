import React, {useState, useEffect} from "react"
// MUI
import {Grid, Paper, Tab, Tabs} from "@mui/material";
// UI
import {a11yProps,TabPanel} from "@/components/UI";
// Components
import AppEditorDesignTab from "@/components/Apps/AppsEditor/AppsEditorTabs/AppEditorDesignTab";
import AppEditorTranslationsTab from "@/components/Apps/AppsEditor/AppsEditorTabs/AppEditorTranslationsTab";
import AppEditorOptionsTab from "@/components/Apps/AppsEditor/AppsEditorTabs/AppEditorOptionsTab";
import AppEditorPreview from "@/components/Apps/AppsEditor/AppEditorPreview/AppEditorPreview";

export default function AppEditor(props: any) {

    const {app, setApp, t} = props
    // Tabs
    const [selectedTab, setSelectedTab] = useState<number>(0)
    // Design sections
    // Translations
    const [previewTranslation, setPreviewTranslation] = useState<any>(null)

    const styles = {
        paper: { p: 2 },
        translationsList: {
            maxHeight: 250,
            overflowY: "auto"
        }
    }

    useEffect(() => {
        if (!!app && !!app.translations && !previewTranslation) {
            let defaultTranslation = app.translations.filter((trans: any) => trans.default)
            setPreviewTranslation(defaultTranslation[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => { setSelectedTab(newValue) };

    return (
        <>
            <Grid item xs={12} md={8}>
                <Paper variant={"outlined"} sx={{ borderRadius: 5 }}>
                    <Tabs value={selectedTab} onChange={handleTabChange} aria-label={"Packaging menu"}>
                        <Tab label={t("design")} sx={{ borderRadius: "20px 0 0 0"}} {...a11yProps(0, t("menu_templates"))} />
                        <Tab label={t("translations")} {...a11yProps(1, t("menu_templates"))} />
                        <Tab label={t("options")} {...a11yProps(2, t("menu_templates"))} />
                    </Tabs>

                    <TabPanel index={0} value={selectedTab}>
                        <AppEditorDesignTab {...{app, setApp, styles, t}} />
                    </TabPanel>
                    <TabPanel index={1} value={selectedTab}>
                        <AppEditorTranslationsTab {...{app, setApp, styles, previewTranslation, setPreviewTranslation, t}} />
                    </TabPanel>
                    <TabPanel index={2} value={selectedTab}>
                        <AppEditorOptionsTab {...{app, setApp, styles, t}} />
                    </TabPanel>
                </Paper>
            </Grid>

            <AppEditorPreview {...{app, previewTranslation, t}} />
        </>
    )
}