import React from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { CropDin } from "@mui/icons-material";

const GeneratorModulesList = (props: any) => {

    const { onChange, activeModuleStyle, StylesAssets } = props;

    const modules = [
        { name: "simple", x: "0px" },
        { name: "rounded_light", x: "-500px" },
        { name: "rounded", x: "-300px" },
        { name: "rounded_strong", x: "-100px" },
        { name: "sieve", x: "-450px" },
        { name: "angular", x: "-400px" },
        { name: "paint", x: "-250px" },
        { name: "dots", x: "-350px" },
        { name: "arrows", x: "-50px" },
        { name: "styled", x: "-150px" },
        { name: "rectangles", x: "-200px" },
        // { name: "tik_tak_toe", x: "-650px" },
        { name: "connections", x: "-600px" },
        { name: "shinny", x: "-550px" }
    ]

    const capitalize = (name: string) => {
        let label = name.replace(/_/g, " ")
        return label.charAt(0).toUpperCase() + label.slice(1);
    }

    return (
        <>
            <List className={"fancyScroll"} style={{maxHeight: '200px', overflow: 'auto'}}>
                {
                    modules.map((module, index) => {
                        return (
                            <ListItemButton
                                id={module.name}
                                selected={activeModuleStyle === module.name}
                                onClick={onChange}
                                key={index}
                            >
                                <ListItemIcon>
                                    <CropDin
                                        sx={{
                                            backgroundImage: `url(${StylesAssets})`,
                                            backgroundPositionX: module.x,
                                            backgroundPositionY: "-50px",
                                            fill: "white !important",
                                            fillOpacity: "0.0 !important",
                                            width: "47px !important",
                                            height: "47px !important",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={capitalize(module.name)} />
                            </ListItemButton>
                        )
                    })
                }
            </List>
        </>
    )
}

export default GeneratorModulesList