import React from "react"
// MUI
import {Button, Grid, TextField, Typography, Paper} from "@mui/material";
import {Delete} from "@mui/icons-material";
// Components
import {DropzoneImage} from "@/components/UI";
// Data
import {useData} from "@/context/PackagingDataProvider";

export default function BrandsEditor(props: any) {

    const {
        creating, editing,
        brand, setBrand,
        onCreate, onEdit,
        t
    } = props
    const {handleDropImage} = useData()

    const styles = {
        editorGrid: { mb: 1 },
        logoImagePaper: { p: 2 },
        alignCenter: { textAlign: "center" },
    }

    const logoSection = () => {
        const handleImage = (dataDrop: any) => {
            handleDropImage(dataDrop).then((res: any) => {
                setBrand({...brand, logo: res.image, logo_id: res.uuid})
            })
        }

        if (brand.logo_id) {
            return (
                <React.Fragment>
                    <Paper variant={"outlined"} sx={styles.logoImagePaper}>
                        <Grid container alignItems={"center"} spacing={3} justifyContent={{ xs: 'center', md: 'space-between'}}>
                            <Grid item>
                                <img height='60px' src={"#"} alt="brand-logo"/>
                            </Grid>
                            <Grid item>
                                <Button startIcon={<Delete />} onClick={() => setBrand({...brand, logo_id: ""})}>
                                    {t("remove_logo")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </React.Fragment>
            )
        } else return <DropzoneImage onDrop={handleImage} {...{t}} />

    }

    return (
        <>
            <Grid container spacing={3} sx={styles.editorGrid} justifyContent="center">
                <Grid item xs={12}>
                    <Typography variant="subtitle1"><b>{t("brand_name")}</b></Typography>
                    <TextField
                        error={brand.name === ""}
                        helperText={brand.name === "" ? t("brand_name_empty_error") : ""}
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={brand.name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setBrand({...brand, name: event.target.value})
                        }}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1"><b>{t("brand_description")}</b></Typography>
                    <Typography variant="body2" gutterBottom>{t("brand_description_label")}</Typography>
                    <TextField
                        size="small"
                        variant="outlined"
                        value={brand.description}
                        multiline
                        rows={4}
                        fullWidth
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setBrand({...brand, description: event.target.value})
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        <b> {t("brand_logo")} </b>
                    </Typography>
                    <Typography variant="body2" gutterBottom>{t("brand_logo_label")}</Typography>

                    {logoSection()}
                </Grid>
                <Grid item xs={12} pt={2} sx={styles.alignCenter}>
                    <Button
                        onClick={creating ? onCreate : editing ? onEdit : () => {return}}
                        disabled={brand.name === ""}
                        variant={"contained"}
                        disableElevation
                    >
                        {creating ? t("brand_create") : editing ? t("brand_edit") : t("common:loading")}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}