import React, {useEffect, useState} from "react"
import {Link, useLocation, useNavigate} from "react-router-dom"
// MUI
import {Button, Container, Grid, Paper} from "@mui/material";
import {ArrowLeft} from "@mui/icons-material";
// Components
import {RangesEditor} from "@/components/Ranges";
import {AppHeader} from "@/components/DashboardComponents";
// Translation
import {useTranslation} from "react-i18next";
// Data
import {useData} from "@/context/PackagingDataProvider";

export default function RangeBuilder(props: any) {

    const location = useLocation();
    const redirect = useNavigate()

    const {creating, editing} = props
    const {createAny, editAny, getLogo, brands, getBrands} = useData()
    const {t} = useTranslation(["ranges", "common"])

    // Data
    const [range, setRange] = useState<any>({
        brand: "",
        id: "",
        name: "",
        description: "",
        logo: "",
        logo_id: "",
    })

    useEffect(() => {
        // Fetch brands if not already done
        if (!brands) getBrands()

        if (editing) {
            let editedRange = location.state
            let editedRangeLogo: any = undefined

            if (!!editedRange.logo_id) {
                getLogo(editedRange.logo_id).then((logo: any) => editedRangeLogo = logo)
            }

            setRange({
                brand: editedRange?.brand.id,
                id: editedRange?.id,
                name: editedRange?.name,
                description: editedRange?.description,
                logo: !!editedRangeLogo ? editedRangeLogo : "",
                logo_id: editedRange?.logo_id,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const handleCreateRange = () => {
        createAny(range, "range").then((created: any) => {
            if (created) redirect("/?tab=ranges")
            else return
        })
    }

    const handleEditRange = () => {
        editAny(range, "range").then((updated: any) => {
            if (updated) redirect("/?tab=ranges")
            else return
        })
    }

    return (
        <>
            <Container maxWidth={"md"} sx={{ pt: "32px" }}>
                <AppHeader
                    paths={[{name: "common:packaging", path: "/"}]}
                    positionName={editing ? "range_editor" : "range_creation"}
                    title={editing ? "range_editor" : "range_creation"}
                    subTitle={editing ? range.name : ""}
                />

                <Grid container justifyContent={"center"} rowSpacing={3}>
                    <Grid item xs={12}>
                        <Button
                            startIcon={<ArrowLeft />}
                            component={Link}
                            to={"/?tab=ranges"}
                            color={"secondary"}
                            variant={"outlined"}
                        >
                            {t("common:back")}
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper variant={"outlined"} sx={{ p: 2, borderRadius: 5 }}>
                            <RangesEditor
                                onCreate={() => handleCreateRange()}
                                onEdit={() => handleEditRange()}
                                {...{
                                    creating, editing,
                                    range, setRange,
                                    brands,
                                    t
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}