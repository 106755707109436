import React, {useState} from "react"
// MUI
import {Box, Button, Grid, IconButton, List, ListItem, Stack, Tooltip, Typography} from "@mui/material";
import {Add, Delete, Edit, Star, StarBorder, Visibility} from "@mui/icons-material";
// Misc
import {languageCodeToName} from "@/utils";
// UI
import {PaperLabelled} from "@/components/UI";
import AppEditorTranslationsDialog
    from "@/components/Apps/AppsEditor/AppEditorTranslationsDialog/AppEditorTranslationsDialog";

export default function AppEditorTranslationsTab(props: any) {

    const {
        app, setApp,
        previewTranslation, setPreviewTranslation,
        styles, t
    } = props

    const [edition, setEdition] = useState<any>(null)

    const handleCreateRedirection = () => {
        setEdition({
            content: {
                accept: "",
                disclaimer: "",
                refuse: "",
                title: ""
            },
            default: false,
            language: "en"
        })
    }

    const handleEditTranslation = (translation: any) => {
        setEdition(translation)
    }

    const handleDeleteTranslation = (translation: any) => {
        if (!app.translations) return

        let appTranslations = app.translations
        let toDelete = app.translations.findIndex((trans: any) => trans === translation)

        appTranslations.splice(toDelete, 1)
        setApp({...app, translations: appTranslations})
    }

    const handleDefaultTranslation = (translation: any) => {
        if (!app.translations) return

        let appTranslations = app.translations
        let defaultTranslation = app.translations.findIndex((trans: any) => trans.default)
        let newDefaultTranslation = app.translations.findIndex((trans: any) => trans.language === translation.language)

        appTranslations[defaultTranslation] = {...appTranslations[defaultTranslation], default: false}
        appTranslations[newDefaultTranslation] = {...appTranslations[newDefaultTranslation], default: true}

        setApp({...app, translations: appTranslations})
    }

    const returnTranslationsList = () => {
        if (!app.translations || app.translations.length < 1) return <></>

        return (
            <List sx={styles.translationsList}>
                {app.translations && app.translations.map((translation: any, index: any) => (
                    <ListItem
                        key={index}
                        sx={{
                            backgroundColor: translation.default ? "rgba(175,201,40,0.37)" : "initial",
                            transition: "0.1s ease-in-out",
                            "&:hover": {
                                backgroundColor: translation.default ? "rgba(175,201,40,0.5)" : "#efefef"
                            }
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography variant={"body1"} fontWeight={500}>
                                    {languageCodeToName(translation.language)}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                                    <div>
                                        <Tooltip title={t("common:edit")} placement={"top"} arrow>
                                            <IconButton
                                                size={"small"}
                                                onClick={() => handleEditTranslation(translation)}
                                                aria-label={t("common:edit")}
                                            >
                                                <Edit fontSize={"inherit"} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Tooltip title={t("preview_translation")} placement={"top"} arrow>
                                            <span>
                                                <IconButton
                                                    size={"small"}
                                                    onClick={() => setPreviewTranslation(translation)}
                                                    aria-label={t("preview_translation")}
                                                    disabled={previewTranslation && previewTranslation.id === translation.id}
                                                >
                                                <Visibility fontSize={"inherit"} />
                                            </IconButton>
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Tooltip title={t("make_translation_default")} placement={"top"} arrow>
                                            <span>
                                                <IconButton
                                                    size={"small"}
                                                    onClick={() => handleDefaultTranslation(translation)}
                                                    aria-label={t("make_translation_default")}
                                                    disabled={translation.default}
                                                >
                                                {translation.default ?
                                                    <Star fontSize={"inherit"} /> :
                                                    <StarBorder fontSize={"inherit"} />
                                                }
                                            </IconButton>
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Tooltip title={t("common:delete")} placement={"top"} arrow>
                                            <span>
                                                <IconButton
                                                    size={"small"}
                                                    onClick={handleDeleteTranslation}
                                                    aria-label={t("common:delete")}
                                                    disabled={translation.default}
                                                >
                                                <Delete fontSize={"inherit"} />
                                            </IconButton>
                                            </span>
                                        </Tooltip>
                                    </div>
                                </Stack>
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
        )
    }

    return (
        <Box sx={styles.paper}>
            <Button
                variant={"contained"}
                disableElevation
                onClick={() => handleCreateRedirection()}
                startIcon={<Add />}
            >
                {t("create_new_translation")}
            </Button>

            <PaperLabelled label={t("translations")} paperPadding={"16px 0"} sx={{ my: 3 }}>
                {returnTranslationsList()}
            </PaperLabelled>

            <AppEditorTranslationsDialog
                open={Boolean(edition)}
                onClose={() => setEdition(null)}
                translation={edition}
                {...{app, setApp, t}}
            />
        </Box>
    )
}