import i18n from 'i18next'
// import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
// Translations
import common_fr from "./locales/fr/common.json"
import brands_fr from "./locales/fr/brands.json"
import ranges_fr from "./locales/fr/ranges.json"
import products_fr from "./locales/fr/products.json"
import apps_fr from "./locales/fr/apps.json"
import download_fr from "./locales/fr/download.json"
import healthchecks_fr from "./locales/fr/healthchecks.json"

import common_en from "./locales/en/common.json"
import brands_en from "./locales/en/brands.json"
import ranges_en from "./locales/en/ranges.json"
import products_en from "./locales/en/products.json"
import apps_en from "./locales/en/apps.json"
import download_en from "./locales/en/download.json"
import healthchecks_en from "./locales/en/healthchecks.json"

import common_es from "./locales/es/common.json"
import brands_es from "./locales/es/brands.json"
import ranges_es from "./locales/es/ranges.json"
import products_es from "./locales/es/products.json"
import apps_es from "./locales/es/apps.json"
import download_es from "./locales/es/download.json"
import healthchecks_es from "./locales/es/healthchecks.json"



// Namespaces linking with imported JSON files
const resources = {
    en: {
        common: common_en,
        brands: brands_en,
        ranges: ranges_en,
        products: products_en,
        apps: apps_en,
        download: download_en,
        healthchecks: healthchecks_en
    },
    fr: {
        common: common_fr,
        brands: brands_fr,
        ranges: ranges_fr,
        products: products_fr,
        apps: apps_fr,
        download: download_fr,
        healthchecks: healthchecks_fr
    },
    es: {
        common: common_es,
        brands: brands_es,
        ranges: ranges_es,
        products: products_es,
        apps: apps_es,
        download: download_es,
        healthchecks: healthchecks_es
    }
}


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: {
            'en-EN': ['en'],
            'fr-FR': ['en'],
            'es-ES': ['en'],
            '*': ['en']
        },
        debug: false,
        interpolation: {
            // may not be useful with React as it escapes by default
            escapeValue: false,
        },
        resources,
        defaultNS: 'common',
        fallbackNS: 'common',
    }).then();