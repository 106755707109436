import React, {useState, useEffect} from "react"
import {useLocation} from "react-router-dom"
// MUI
import {Tabs, Tab, Container, Grid, Paper} from "@mui/material";
import {Download, PhoneAndroid, MonitorHeart} from "@mui/icons-material";
// Assets - TODO: solve "unresolved path" bug that isn't one... ?
// @ts-ignore
import {ReactComponent as BrandsLogo} from "@/assets/svg/brands.svg"
// @ts-ignore
import {ReactComponent as RangesLogo} from "@/assets/svg/ranges.svg"
// @ts-ignore
import {ReactComponent as ProductsLogo} from "@/assets/svg/qrcode.svg"
// Components
import {AppHeader} from "@/components/DashboardComponents";
import {a11yProps, TabPanel} from "@/components/UI";
// Tabs
import {BrandsTab} from "@/components/Brands";
import {RangesTab} from "@/components/Ranges";
import {ProductsTab} from "@/components/Products";
import {AppsTab} from "@/components/Apps";
// Translation
import {useTranslation} from "react-i18next";
// CSS
import "./Main.css"
import IntroductionStepper from "@/components/IntroductionStepper/IntroductionStepper";
import {useData} from "@/context/PackagingDataProvider";
import {useModule} from "@/context/ModuleProvider";
import {DownloadTab} from "@/components/Download";
import HealthchecksTab from "@/components/Healthchecks/HealthchecksTab";

// - This page checks user data and displays tabs accordingly
// - Will redirect to IntroStepper if first visit/no assets created
// - Gives common props
export default function Main() {

    const {t} = useTranslation(["common"])
    const location = useLocation()
    const {modules} = useModule()
    const {firstVisit, setFirstVisit} = useData()

    // tabs
    const [selectedTab, setSelectedTab] = useState<number>(0)

    // check routes, states and localstorages
    useEffect(() => {
        if (location.search.includes("tab")) redirectToGivenTab()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    const redirectToGivenTab = () => {
        let query = new URLSearchParams(location.search)
        let tab = query.get("tab")
        switch (tab) {
            case "brands": return;
            case "ranges": setSelectedTab(1); break;
            case "products": setSelectedTab(2); break;
            case "download": setSelectedTab(3); break;
            case "healthchecks": setSelectedTab(4); break;
            case "apps": if (modules.geoloc) setSelectedTab(5); break;
            default: return;
        }
    }

    // display
    const renderTabs = () => {

        const handleTabChange = (event: React.SyntheticEvent, newValue: number) => { setSelectedTab(newValue) };

        return (
            <>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label={"Packaging menu"}>
                    <Tab
                        label={t("brands")}
                        icon={<BrandsLogo className={"mainTabIcon"} />}
                        sx={{ borderRadius: "20px 0 0 0"}}
                        {...a11yProps(0, t("menu_templates"))}
                    />
                    <Tab
                        label={t("ranges")}
                        icon={<RangesLogo className={"mainTabIcon"} />}
                        {...a11yProps(1, t("menu_templates"))}
                    />
                    <Tab
                        label={t("products")}
                        icon={<ProductsLogo className={"mainTabIcon"} />}
                        {...a11yProps(2, t("menu_templates"))}
                    />
                    <Tab
                        label={t("download")}
                        icon={<Download className={"mainTabIcon"} />}
                        {...a11yProps(3, t("menu_templates"))}
                    />
                    <Tab
                        label={t("health")}
                        icon={<MonitorHeart className={"mainTabIcon"} />}
                        {...a11yProps(4, t("menu_templates"))}
                    />
                    {modules.geoloc &&
                        <Tab
                            label={t("apps")}
                            icon={<PhoneAndroid className={"mainTabIcon"} />}
                            {...a11yProps(5, t("menu_templates"))}
                        />
                    }
                </Tabs>

                <TabPanel index={0} value={selectedTab}>
                    <BrandsTab />
                </TabPanel>
                <TabPanel index={1} value={selectedTab}>
                    <RangesTab />
                </TabPanel>
                <TabPanel index={2} value={selectedTab}>
                    <ProductsTab />
                </TabPanel>
                <TabPanel index={3} value={selectedTab}>
                    <DownloadTab />
                </TabPanel>
                <TabPanel index={4} value={selectedTab}>
                    <HealthchecksTab />
                </TabPanel>
                {modules.geoloc &&
                    <TabPanel index={5} value={selectedTab}>
                        <AppsTab />
                    </TabPanel>
                }
            </>
        )
    }

    const renderAppOrStepper = () => {

        const renderAppWrapper = () => {
            return (
                <>
                    <Container maxWidth={"xl"} className={"mainWrapper"}>
                        <AppHeader
                            paths={[]}
                            positionName={"Packaging"}
                            title={"Packaging"}
                            subTitle={"application_menu"}
                            docLabel={"packaging_main_doc_label"}
                            docLink={`${process.env.REACT_APP_DOC_URL}/packaging/introduction`}
                        />

                        <Grid container justifyContent={"center"}>
                            <Grid item xs={12}>
                                <Paper variant={"outlined"} sx={{ borderRadius: 5 }}>
                                    {renderTabs()}
                                </Paper>
                            </Grid>
                        </Grid>

                    </Container>
                </>
            )
        }

        if (firstVisit) return <IntroductionStepper {...{firstVisit, setFirstVisit}} />
        else return renderAppWrapper()
    }

    return renderAppOrStepper()
}