const languageCodeToNameDict: Record<string, string> = {
    'aa': 'Afar',
    'ab': 'Abkhazian',
    'ae': 'Avestan',
    'af': 'Afrikaans',
    'ak': 'Akan',
    'am': 'Amharic',
    'an': 'Aragonese',
    'ar': 'Arabic',
    'as': 'Assamese',
    'av': 'Avaric',
    'ay': 'Aymara',
    'az': 'Azerbaijani',
    'ba': 'Bashkir',
    'be': 'Belarusian',
    'bg': 'Bulgarian',
    'bh': 'Bihari languages',
    'bi': 'Bislama',
    'bm': 'Bambara',
    'bn': 'Bengali',
    'bo': 'Tibetan',
    'br': 'Breton',
    'bs': 'Bosnian',
    'ca': 'Catalan',
    'ce': 'Chechen',
    'ch': 'Chamorro',
    'co': 'Corsican',
    'cr': 'Cree',
    'cs': 'Czech',
    'cu': 'Church Slavic',
    'cv': 'Chuvash',
    'cy': 'Welsh',
    'da': 'Danish',
    'de': 'German',
    'dv': 'Maldivian',
    'dz': 'Dzongkha',
    'ee': 'Ewe',
    'el': 'Greek (modern)',
    'en': 'English',
    'eo': 'Esperanto',
    'es': 'Spanish',
    'et': 'Estonian',
    'eu': 'Basque',
    'fa': 'Persian',
    'ff': 'Fulah',
    'fi': 'Finnish',
    'fj': 'Fijian',
    'fo': 'Faroese',
    'fr': 'French',
    'fy': 'Western Frisian',
    'ga': 'Irish',
    'gd': 'Gaelic',
    'gl': 'Galician',
    'gn': 'Guaraní',
    'gu': 'Gujarati',
    'gv': 'Manx',
    'ha': 'Hausa',
    'he': 'Hebrew (modern)',
    'hi': 'Hindi',
    'ho': 'Hiri Motu',
    'hr': 'Croatian',
    'ht': 'Haitian Creole',
    'hu': 'Hungarian',
    'hy': 'Armenian',
    'hz': 'Herero',
    'ia': 'Interlingua',
    'id': 'Indonesian',
    'ie': 'Interlingue',
    'ig': 'Igbo',
    'ii': 'Nuosu',
    'ik': 'Inupiaq',
    'io': 'Ido',
    'is': 'Icelandic',
    'it': 'Italian',
    'iu': 'Inuktitut',
    'ja': 'Japanese',
    'jv': 'Javanese',
    'ka': 'Georgian',
    'kg': 'Kongo',
    'ki': 'Kikuyu',
    'kj': 'Kwanyama',
    'kk': 'Kazakh',
    'kl': 'Greenlandic',
    'km': 'Central Khmer',
    'kn': 'Kannada',
    'ko': 'Korean',
    'kr': 'Kanuri',
    'ks': 'Kashmiri',
    'ku': 'Kurdish',
    'kv': 'Komi',
    'kw': 'Cornish',
    'ky': 'Kyrgyz',
    'la': 'Latin',
    'lb': 'Luxembourgish',
    'lg': 'Ganda',
    'li': 'Limburgish',
    'ln': 'Lingala',
    'lo': 'Lao',
    'lt': 'Lithuanian',
    'lu': 'Luba-Katanga',
    'lv': 'Latvian',
    'mg': 'Malagasy',
    'mh': 'Marshallese',
    'mi': 'Maori',
    'mk': 'Macedonian',
    'ml': 'Malayalam',
    'mn': 'Mongolian',
    'mr': 'Marathi',
    'ms': 'Malay',
    'mt': 'Maltese',
    'my': 'Burmese',
    'na': 'Nauru',
    'nb': 'Bokmål',
    'nd': 'North Ndebele',
    'ne': 'Nepali',
    'ng': 'Ndonga',
    'nl': 'Dutch',
    'nn': 'Norwegian Nynorsk',
    'no': 'Norwegian',
    'nr': 'South Ndebele',
    'nv': 'Navajo',
    'ny': 'Nyanja',
    'oc': 'Occitan',
    'oj': 'Ojibwa',
    'om': 'Oromo',
    'or': 'Oriya',
    'os': 'Ossetian',
    'pa': 'Punjabi',
    'pi': 'Pali',
    'pl': 'Polish',
    'ps': 'Pashto',
    'pt': 'Portuguese',
    'qu': 'Quechua',
    'rm': 'Romansh',
    'rn': 'Rundi',
    'ro': 'Romanian',
    'ru': 'Russian',
    'rw': 'Kinyarwanda',
    'sa': 'Sanskrit',
    'sc': 'Sardinian',
    'sd': 'Sindhi',
    'se': 'Northern Sami',
    'sg': 'Sango',
    'si': 'Sinhalese',
    'sk': 'Slovak',
    'sl': 'Slovenian',
    'sm': 'Samoan',
    'sn': 'Shona',
    'so': 'Somali',
    'sq': 'Albanian',
    'sr': 'Serbian',
    'ss': 'Swati',
    'st': 'Southern Sotho',
    'su': 'Sundanese',
    'sv': 'Swedish',
    'sw': 'Swahili',
    'ta': 'Tamil',
    'te': 'Telugu',
    'tg': 'Tajik',
    'th': 'Thai',
    'ti': 'Tigrinya',
    'tk': 'Turkmen',
    'tl': 'Tagalog',
    'tn': 'Tswana',
    'to': 'Tonga',
    'tr': 'Turkish',
    'ts': 'Tsonga',
    'tt': 'Tatar',
    'tw': 'Twi',
    'ty': 'Tahitian',
    'ug': 'Uyghur',
    'uk': 'Ukrainian',
    'ur': 'Urdu',
    'uz': 'Uzbek',
    've': 'Venda',
    'vi': 'Vietnamese',
    'vo': 'Volapük',
    'wa': 'Walloon',
    'wo': 'Wolof',
    'xh': 'Xhosa',
    'yi': 'Yiddish',
    'yo': 'Yoruba',
    'za': 'Zhuang',
    'zh': 'Chinese',
    'zu': 'Zulu'
}

// https://raw.githubusercontent.com/cospired/i18n-iso-languages/master/langs/en.json

const langsEN = [
    {code: "aa", name: "Afar"},
    {code: "ab", name: "Abkhazian"},
    {code: "ae", name: "Avestan"},
    {code: "af", name: "Afrikaans"},
    {code: "ak", name: "Akan"},
    {code: "am", name: "Amharic"},
    {code: "an", name: "Aragonese"},
    {code: "ar", name: "Arabic"},
    {code: "as", name: "Assamese"},
    {code: "av", name: "Avaric"},
    {code: "ay", name: "Aymara"},
    {code: "az", name: "Azerbaijani"},
    {code: "ba", name: "Bashkir"},
    {code: "be", name: "Belarusian"},
    {code: "bg", name: "Bulgarian"},
    {code: "bh", name: "Bihari languages"},
    {code: "bi", name: "Bislama"},
    {code: "bm", name: "Bambara"},
    {code: "bn", name: "Bengali"},
    {code: "bo", name: "Tibetan"},
    {code: "br", name: "Breton"},
    {code: "bs", name: "Bosnian"},
    {code: "ca", name: "Catalan"},
    {code: "ce", name: "Chechen"},
    {code: "ch", name: "Chamorro"},
    {code: "co", name: "Corsican"},
    {code: "cr", name: "Cree"},
    {code: "cs", name: "Czech"},
    {code: "cu", name: "Church Slavic"},
    {code: "cv", name: "Chuvash"},
    {code: "cy", name: "Welsh"},
    {code: "da", name: "Danish"},
    {code: "de", name: "German"},
    {code: "dv", name: "Maldivian"},
    {code: "dz", name: "Dzongkha"},
    {code: "ee", name: "Ewe"},
    {code: "el", name: "Greek (modern)"},
    {code: "en", name: "English"},
    {code: "eo", name: "Esperanto"},
    {code: "es", name: "Spanish"},
    {code: "et", name: "Estonian"},
    {code: "eu", name: "Basque"},
    {code: "fa", name: "Persian"},
    {code: "ff", name: "Fulah"},
    {code: "fi", name: "Finnish"},
    {code: "fj", name: "Fijian"},
    {code: "fo", name: "Faroese"},
    {code: "fr", name: "French"},
    {code: "fy", name: "Western Frisian"},
    {code: "ga", name: "Irish"},
    {code: "gd", name: "Gaelic"},
    {code: "gl", name: "Galician"},
    {code: "gn", name: "Guaraní"},
    {code: "gu", name: "Gujarati"},
    {code: "gv", name: "Manx"},
    {code: "ha", name: "Hausa"},
    {code: "he", name: "Hebrew (modern)"},
    {code: "hi", name: "Hindi"},
    {code: "ho", name: "Hiri Motu"},
    {code: "hr", name: "Croatian"},
    {code: "ht", name: "Haitian Creole"},
    {code: "hu", name: "Hungarian"},
    {code: "hy", name: "Armenian"},
    {code: "hz", name: "Herero"},
    {code: "ia", name: "Interlingua"},
    {code: "id", name: "Indonesian"},
    {code: "ie", name: "Interlingue"},
    {code: "ig", name: "Igbo"},
    {code: "ii", name: "Nuosu"},
    {code: "ik", name: "Inupiaq"},
    {code: "io", name: "Ido"},
    {code: "is", name: "Icelandic"},
    {code: "it", name: "Italian"},
    {code: "iu", name: "Inuktitut"},
    {code: "ja", name: "Japanese"},
    {code: "jv", name: "Javanese"},
    {code: "ka", name: "Georgian"},
    {code: "kg", name: "Kongo"},
    {code: "ki", name: "Kikuyu"},
    {code: "kj", name: "Kwanyama"},
    {code: "kk", name: "Kazakh"},
    {code: "kl", name: "Greenlandic"},
    {code: "km", name: "Central Khmer"},
    {code: "kn", name: "Kannada"},
    {code: "ko", name: "Korean"},
    {code: "kr", name: "Kanuri"},
    {code: "ks", name: "Kashmiri"},
    {code: "ku", name: "Kurdish"},
    {code: "kv", name: "Komi"},
    {code: "kw", name: "Cornish"},
    {code: "ky", name: "Kyrgyz"},
    {code: "la", name: "Latin"},
    {code: "lb", name: "Luxembourgish"},
    {code: "lg", name: "Ganda"},
    {code: "li", name: "Limburgish"},
    {code: "ln", name: "Lingala"},
    {code: "lo", name: "Lao"},
    {code: "lt", name: "Lithuanian"},
    {code: "lu", name: "Luba-Katanga"},
    {code: "lv", name: "Latvian"},
    {code: "mg", name: "Malagasy"},
    {code: "mh", name: "Marshallese"},
    {code: "mi", name: "Maori"},
    {code: "mk", name: "Macedonian"},
    {code: "ml", name: "Malayalam"},
    {code: "mn", name: "Mongolian"},
    {code: "mr", name: "Marathi"},
    {code: "ms", name: "Malay"},
    {code: "mt", name: "Maltese"},
    {code: "my", name: "Burmese"},
    {code: "na", name: "Nauru"},
    {code: "nb", name: "Bokmål"},
    {code: "nd", name: "North Ndebele"},
    {code: "ne", name: "Nepali"},
    {code: "ng", name: "Ndonga"},
    {code: "nl", name: "Dutch"},
    {code: "nn", name: "Norwegian Nynorsk"},
    {code: "no", name: "Norwegian"},
    {code: "nr", name: "South Ndebele"},
    {code: "nv", name: "Navajo"},
    {code: "ny", name: "Nyanja"},
    {code: "oc", name: "Occitan"},
    {code: "oj", name: "Ojibwa"},
    {code: "om", name: "Oromo"},
    {code: "or", name: "Oriya"},
    {code: "os", name: "Ossetian"},
    {code: "pa", name: "Punjabi"},
    {code: "pi", name: "Pali"},
    {code: "pl", name: "Polish"},
    {code: "ps", name: "Pashto"},
    {code: "pt", name: "Portuguese"},
    {code: "qu", name: "Quechua"},
    {code: "rm", name: "Romansh"},
    {code: "rn", name: "Rundi"},
    {code: "ro", name: "Romanian"},
    {code: "ru", name: "Russian"},
    {code: "rw", name: "Kinyarwanda"},
    {code: "sa", name: "Sanskrit"},
    {code: "sc", name: "Sardinian"},
    {code: "sd", name: "Sindhi"},
    {code: "se", name: "Northern Sami"},
    {code: "sg", name: "Sango"},
    {code: "si", name: "Sinhalese"},
    {code: "sk", name: "Slovak"},
    {code: "sl", name: "Slovenian"},
    {code: "sm", name: "Samoan"},
    {code: "sn", name: "Shona"},
    {code: "so", name: "Somali"},
    {code: "sq", name: "Albanian"},
    {code: "sr", name: "Serbian"},
    {code: "ss", name: "Swati"},
    {code: "st", name: "Southern Sotho"},
    {code: "su", name: "Sundanese"},
    {code: "sv", name: "Swedish"},
    {code: "sw", name: "Swahili"},
    {code: "ta", name: "Tamil"},
    {code: "te", name: "Telugu"},
    {code: "tg", name: "Tajik"},
    {code: "th", name: "Thai"},
    {code: "ti", name: "Tigrinya"},
    {code: "tk", name: "Turkmen"},
    {code: "tl", name: "Tagalog"},
    {code: "tn", name: "Tswana"},
    {code: "to", name: "Tonga"},
    {code: "tr", name: "Turkish"},
    {code: "ts", name: "Tsonga"},
    {code: "tt", name: "Tatar"},
    {code: "tw", name: "Twi"},
    {code: "ty", name: "Tahitian"},
    {code: "ug", name: "Uyghur"},
    {code: "uk", name: "Ukrainian"},
    {code: "ur", name: "Urdu"},
    {code: "uz", name: "Uzbek"},
    {code: "ve", name: "Venda"},
    {code: "vi", name: "Vietnamese"},
    {code: "vo", name: "Volapük"},
    {code: "wa", name: "Walloon"},
    {code: "wo", name: "Wolof"},
    {code: "xh", name: "Xhosa"},
    {code: "yi", name: "Yiddish"},
    {code: "yo", name: "Yoruba"},
    {code: "za", name: "Zhuang"},
    {code: "zh", name: "Chinese"},
    {code: "zu", name: "Zulu"}
];


export {languageCodeToNameDict, langsEN}