import React, {useState, useEffect} from "react"
// MUI
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
// Generator
import QRCodeGenerator from "@/components/QRCodeGenerator/QRCodeGenerator";
// Data
import {useData} from "@/context/PackagingDataProvider";
// Assets
// @ts-ignore
import QRCodePlaceholder from "@/assets/png/qrcode-preview.png";

export default function QRCodeGeneratorDialog(props: any) {

    const {open, onClose, product, setProduct, usedModule, verifiedGtin, t} = props

    const {createQRCode, getWIPQRCodePreview, domains, getDomains, qrcodeTemplates, getTemplates} = useData()
    const shortUrlPrefix = "https://eqrco.de"

    const [qrcode, setQRCode] = useState<any>({
        settings: {
            frame: null,
            template_id: '',
            redundancy: "M",
            eyes: {
                type: "leaf",
                color_ext_top_left: '',
                color_ext_top_right: '',
                color_ext_bottom_left: '',
                color_int_bottom_left: '',
                color_int_top_right: '',
                color_int_top_left: ''
            },
            layout: {
                gradient_type: "horizontal",
                type: "single_color",
                color_background: "#ffffff",
                color_one: "#000000",
                color_two: "#afc928",
                color_shadow: "#bdbdbd",
                force_shadow: "" // L, M, S
            },
            logo: {asset_uuid: '', excavate: false, url: '', width: 0, height: 0, x: 0, x_norm: 0, y: 0, y_norm: 0},
            background: {url: '', contrast: 0.0, brightness: 0.0},
            modules: {type: "simple"}
        },
        data: {
            type: "URL",
            resolution: "dynamic",
            url: "",
            label: "",
            preferred_short_url: ""
        }
    })
    const [preview, setPreview] = useState<any>(QRCodePlaceholder)
    const [previewWarning, setPreviewWarning] = useState<boolean>(false)
    // QR Code data management
    // True/False for UI management - undefined for loading
    const [isDataSubmitted, setIsDataSubmitted] = useState<boolean | undefined>(false)
    // Logo data
    const [logo, setLogo] = useState<any>({
        replacing: false,
        position: "auto",
        x: 190,
        y: 190,
        coordX: 0,
        coordY: 0,
        startX: 190,
        startY: 190,
        width: 120,
        height: 120,
        file: null
    })

    // check for domains on open
    useEffect(() => {
        if (open) {
            getDomains().then()
            getTemplates().then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    // Auto setup name and/or data url when entering the dialog
    // Remove to allow QR Codes types integration
    useEffect(() => {
        if (open && product && !qrcode.data.url) {
            if (usedModule === "GS1") {
                setQRCode({
                    ...qrcode, data: {
                        ...qrcode.data,
                        label: product.name,
                        url: product.default_url,
                        short_url: !!verifiedGtin ? verifiedGtin : ""
                }})
            } else {
                setQRCode({
                    ...qrcode, data: {
                        ...qrcode.data,
                        type: "packaging",
                        label: product.name,
                        url: product.default_url
                }})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, product, usedModule])

    // Save
    const handleSaveQRCode = async () => {
        if (usedModule === "GS1") {
            createQRCode(qrcode, "serial").then((res: any) => {
                if (res) {
                    setProduct({...product, qrcode: res.qrcode_id})
                    onClose()
                }
            })
        } else {
            createQRCode(qrcode, "geolocation").then((res: any) => {
                if (res) {
                    setProduct({...product, qrcode: res.qrcode_id})
                    onClose()
                }
            })
        }
    }

    // Preview management - previously known as fetchPReview()
    const handleSubmitQRCodeData = async () => {
        // Packaging-specific logic
        let previewType = verifiedGtin ? "serial" : ""

        await getWIPQRCodePreview(qrcode, previewType).then((res: any) => {
            if (!!res) {
                setPreview(res.preview)

                if (res.header !== null && res.header === "CONTRAST_WARNING") setPreviewWarning(true)
                else setPreviewWarning(false)

                if (!isDataSubmitted) setIsDataSubmitted(true)
            } else setIsDataSubmitted(undefined)
        })

    }

    return (
        <>
            <Dialog open={open} maxWidth={usedModule === "GS1" ? "md" : "xl"} fullWidth>
                <DialogTitle>{t("products:qrcode_generator")}</DialogTitle>
                <DialogContent sx={{ pb: 4 }}>
                    <QRCodeGenerator
                        onCreate={() => handleSaveQRCode()}
                        onSubmit={() => handleSubmitQRCodeData()}
                        onUnsubmit={() => setIsDataSubmitted(false)}
                        submitted={isDataSubmitted}
                        {...{
                            usedModule,
                            // standard qrcode data
                            qrcode, setQRCode,
                            preview, previewWarning,
                            shortUrlPrefix,
                            // logo data
                            logo, setLogo,
                            // dynamic-pro
                            domains, qrcodeTemplates,
                            // misc
                            t
                    }}/>
                </DialogContent>
            </Dialog>
        </>
    )
}