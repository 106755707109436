import React from "react"
import {hexToRgb} from "@/utils";
import {Box, Button, Grid, Paper, Typography} from "@mui/material";

export default function AppEditorPreview(props: any) {

    const {app, previewTranslation, t} = props

    let backgroundRgba = hexToRgb(app?.layout.background?.color, app?.layout.background?.alpha);

    const previewStyles = {
        wrapper: {
            display: "flex",
            justifyContent: "center",
            maxWidth: 344,
        },
        grid: {
            paddingTop: 5,
            minHeight: '100%',
            backgroundColor: (app?.layout?.background?.url) ? "transparent" : backgroundRgba.rgba,
            ...(app?.layout.background?.url !== undefined ? {
                backgroundImage: `url(${app?.layout?.background?.url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            } : undefined),
        },
        logoWrapper: {
            textAlign: "center",
            display: "flex",
            my: 5,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        },
        logoImage: {
            maxWidth: '80%',
            maxHeight: '30%',
        },
        panelWrapper: { p: 4 },
        panelPaper: {
            padding: 3,
            borderTop: app?.layout.panel?.border_color !== undefined ? '5px solid ' + app?.layout.panel?.border_color : undefined,
            borderRadius: app?.layout.panel?.rounded,
            backgroundColor: app?.layout.panel?.background_color,
            color: app?.layout.panel?.text_color,
        },
        panelText: {
            fontSize: '0.8rem',
            color: app?.layout.panel?.text_color
        },
        acceptButton: {
            fontSize: '0.75rem',
            borderRadius: !!app?.layout.panel?.button_primary?.rounded ? app?.layout.panel?.button_primary?.rounded : app?.layout.panel?.buttons?.rounded,
            backgroundColor: !!app?.layout.panel?.button_primary?.color ? app?.layout.panel?.button_primary?.color : app?.layout.panel?.buttons?.color,
            color: !!app?.layout.panel?.button_primary?.text_color ? app?.layout.panel?.button_primary?.text_color : app?.layout.panel?.buttons?.text_color,
            border: "1px solid",
            borderColor: (!!app?.layout.panel?.button_primary?.text_color && app?.layout.panel?.button_primary?.outline) ? app?.layout.panel?.button_primary?.text_color : "transparent",
        },
        refuseButton: {
            fontSize: '0.75rem',
            borderRadius: !!app?.layout.panel?.button_secondary?.rounded ? app?.layout.panel?.button_secondary?.rounded : app?.layout.panel?.buttons?.rounded,
            backgroundColor: !!app?.layout.panel?.button_secondary?.color ? app?.layout.panel?.button_secondary?.color : app?.layout.panel?.buttons?.color,
            color: !!app?.layout.panel?.button_secondary?.text_color ? app?.layout.panel?.button_secondary?.text_color : app?.layout.panel?.buttons?.text_color,
            border: "1px solid",
            borderColor: (!!app?.layout.panel?.button_secondary?.text_color && app?.layout.panel?.button_secondary?.outline) ? app?.layout.panel?.button_secondary?.text_color : "transparent",
        },
        previewOuter: {
            display: 'flex',
            "@media screen and (max-width: 360px)": {
                transform: 'scale(0.8)',
            }
        },
    }

    return (
        <>
            <Grid item xs={12} md={"auto"}>
                <Box sx={{ p: 1, width: "100%", bgcolor: "#efefef", mb: 1, borderRadius: "6px" }}>
                    <Typography align={"center"} fontWeight={500} textTransform={"uppercase"} fontSize={14}>
                        {t("preview")}
                    </Typography>
                </Box>
                <Paper variant={"outlined"} sx={{ justifyContent: "center", display: "flex"}}>
                    <Box position="relative" sx={previewStyles.wrapper}>
                        <Grid container justifyContent={"center"} alignItems={"flex-start"} sx={previewStyles.grid}>
                            {/* Logo */}
                            <Grid item>
                                <Box sx={previewStyles.logoWrapper}>
                                    <img src={app?.layout.logo_url} alt={"brand-logo"} style={previewStyles.logoImage}/>
                                </Box>
                            </Grid>

                            {/* Panel */}
                            <Grid item sx={previewStyles.panelWrapper}>
                                <Paper sx={previewStyles.panelPaper}>
                                    <Typography sx={previewStyles.panelText} align={'center'}>
                                        {previewTranslation?.content.disclaimer}
                                    </Typography>
                                    <br/>
                                    <br/>

                                    <Grid container spacing={3} justifyContent={"center"}>
                                        <Grid item>
                                            <Button sx={previewStyles.refuseButton}>
                                                {previewTranslation?.content.refuse}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button sx={previewStyles.acceptButton}>
                                                {previewTranslation?.content.accept}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </>
    )
}