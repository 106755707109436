import React from "react"
import {Button, Collapse, Grid, Paper, Stack, TextField, Typography, Switch} from "@mui/material";
import {useData} from "@/context/PackagingDataProvider";
import {getImageSize} from "@/utils";
import PanToolIcon from '@mui/icons-material/PanTool';
import {Delete} from "@mui/icons-material";
// @ts-ignore
import InfoValid from "@/assets/generator/logo-information/valid.png"
// @ts-ignore
import InfoTooBig from "@/assets/generator/logo-information/logo-too-big.png"
// @ts-ignore
import InfoEye from "@/assets/generator/logo-information/eye-hidden.png"

export default function GeneratorLogoPanel(props: any) {

    const {qrcode, setQRCode, logo, setLogo, t} = props
    const {handleDropQRCodeImage} = useData()

    const styles = {
        logoTypePaper: {
            p: 2,
        },
    }

    const usingLogo = (qrcode.settings.logo.asset_uuid !== "" || qrcode.settings.logo.url !== "")

    const handleSetLogoUrl = (e: any) => {
        let imageRegex = /(https?:\/\/.*\.(?:png|jpg))/i

        if (imageRegex.test(e.target.value)) {
            const _qrcode = {...qrcode}
            _qrcode.settings.logo.url = e.target.value
            _qrcode.settings.redundancy = "H"
            setQRCode(_qrcode)
        }
    }

    const handleImportLogo = async (data: any) => {

        const file = data.target.files[0]
        const size: any = await getImageSize(file)

        const squarifyImage = async () => {
            let width: any;
            let height: any;

            if (size.width < 50) {
                width = 50;
                height = 50 * (size.height / size.width);
            } else if (size.width > 80) {
                width = 80;
                height = 80 * (size.height / size.width);
            } else {
                height = size.height;
                width = size.width;
            }

            return {...{height, width}}
        }

        let {height, width} = await squarifyImage()

        setLogo({ ...logo, width: width, height: height, file: file, ogWidth: size.width, ogHeight: size.height })

        await handleDropQRCodeImage("logo", data).then((res: any) => {
            if (!!res) {
                const _qrcode = {...qrcode}
                _qrcode.settings.logo.asset_uuid = res
                // _qrcode.settings.logo.width = 120/1.6
                _qrcode.settings.logo.width = width
                _qrcode.settings.logo.height = height
                _qrcode.settings.redundancy = "H"
                setQRCode(_qrcode)
            }
            // empty input
        }).finally(() => data.target.value = null)
    }

    const handleDeleteLogo = () => {
        const _qrcode = {...qrcode}
        _qrcode.settings.logo = {
            asset_uuid: '',
            excavate: false,
            url: '',
            width: 0,
            height: 0,
            x: 0,
            x_norm: 0,
            y: 0,
            y_norm: 0
        }
        setQRCode(_qrcode)
        setLogo({
            ...logo,
            position: "auto",
            x: 190,
            y: 190,
            coordX: 0,
            coordY: 0,
            startX: 190,
            startY: 190,
            width: 120,
            height: 120,
        })
    }

    const handleLogoCrop = () => {
        const _qrcode = {...qrcode}
        _qrcode.settings.logo.excavate = !_qrcode.settings.logo.excavate
        setQRCode(_qrcode)
    }

    const handleSwitchLogoPosition = () => {
        if (logo.position === "manual") {
            setLogo({...logo, position: "auto" })
        } else {
            let x = (300 - logo.width) / 2;
            let y = (300 - logo.height) / 2;
            setLogo({...logo, x: x, y: y, replacing: true, position: "manual"})
        }
    }

    // render
    const renderLogoTypeSelection = () => {
        return (
            <Collapse in={!usingLogo && !logo.replacing}>
                <Grid container justifyContent={"center"} spacing={3}>
                    <Grid item xs={11} md={6}>
                        <Paper variant={"outlined"} sx={styles.logoTypePaper}>
                            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={2}>
                                <Typography variant={"body2"}>{t("products:logo_use_url")}</Typography>
                                <Stack direction={"row"} spacing={2} sx={{ width: "100%" }}>
                                    <TextField
                                        size={"small"}
                                        placeholder={t("products:paste_valid_image_url")}
                                        fullWidth
                                        value={qrcode.settings.logo.url}
                                        onChange={(e) => handleSetLogoUrl(e)}
                                    />
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>

                    <Grid item xs={11} md={6}>
                        <label htmlFor={"upload-logo-input"} style={{ cursor: "pointer" }}>
                            <Paper variant={"outlined"} sx={styles.logoTypePaper}>
                                <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={2}>
                                    <Typography variant={"body2"}>{t("products:logo_use_import")}</Typography>
                                    <Button sx={{ pointerEvents: "none" }}>
                                        {t("products:import_logo")}
                                    </Button>
                                </Stack>
                            </Paper>
                        </label>
                        <input
                            accept="image/*"
                            id="upload-logo-input"
                            type="file"
                            onChange={handleImportLogo}
                            style={{display: 'none'}}
                        />
                    </Grid>
                </Grid>
            </Collapse>
        )
    }

    const renderLogoSettings = () => {
        return (
            <Collapse in={usingLogo && !logo.replacing} sx={{ pt: 2 }}>
                <Grid container justifyContent={"center"} spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" component="p" align="center" gutterBottom>{t("products:logo_options")}</Typography>
                    </Grid>
                    <Grid item xs={'auto'} sm={6} md={5} lg={3}>
                        <Typography variant={"body1"}>{t("products:logo_position")}</Typography>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <Typography variant={"body2"}>{t("products:auto")}</Typography>
                            <Switch
                                checked={logo.position === "manual"}
                                onChange={handleSwitchLogoPosition}
                            />
                            <Typography variant={"body2"}>{t("products:manual")}</Typography>
                        </Stack>
                    </Grid>

                    <Grid item xs={'auto'} sm={6} md={5} lg={3}>
                        <Typography variant={"body1"}>{t("products:logo_excavate")}</Typography>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <Typography variant={"body2"}>{t("products:disabled")}</Typography>
                            <Switch
                                checked={qrcode.settings.logo.excavate}
                                onChange={() => handleLogoCrop()}
                            />
                            <Typography variant={"body2"}>{t("products:enabled")}</Typography>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} sm={'auto'} className={"text-center"}>
                        <Button
                            color={"secondary"}
                            variant={"outlined"}
                            onClick={() => setLogo({...logo, replacing: true})}
                            disableElevation
                            size={"small"}
                            disabled={logo.position === "auto"}
                            startIcon={<PanToolIcon />}
                            sx={{ mt: 3 }}
                        >
                            {t("products:logo_reposition")}
                        </Button>

                    </Grid>

                    <Grid item xs={6} sm={'auto'} className={"text-center"}>
                        <Button
                            color={"error"}
                            variant="contained"
                            disableElevation
                            size="small"
                            onClick={() => handleDeleteLogo()}
                            startIcon={<Delete />}
                            sx={{ mt: 3 }}
                        >
                            {t("products:logo_clear")}
                        </Button>
                    </Grid>
                </Grid>
            </Collapse>
        )
    }

    const renderLogoPositionAlerts = () => {
        return (
            <Collapse in={logo.replacing}>
                <Grid container justifyContent={"center"} spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6" component="p" align="center" gutterBottom>
                            {t("products:information")}
                        </Typography>
                    </Grid>
                    {
                        [
                            { key: "size", image: InfoTooBig },
                            { key: "eye", image: InfoEye },
                            { key: "valid", image: InfoValid }
                        ].map((info: any, index: any) => (
                            <Grid item xs={11} md={4} key={index}>
                                <Stack direction={"column"} spacing={2} alignItems={"center"}>
                                    <img
                                        draggable={false}
                                        src={info.image}
                                        height={150} width={150}
                                        alt={"logo information"}
                                    />
                                    <Typography variant={"body2"}>{t(`products:logo_placement_info_${info.key}`)}</Typography>
                                </Stack>
                            </Grid>
                        ))
                    }
                </Grid>
            </Collapse>
        )
    }

    return (
        <>
            <Grid container justifyContent={"center"} alignItems={"center"} sx={{ height: "100%" }}>
                <Grid item xs={12}>{renderLogoTypeSelection()}</Grid>
                <Grid item xs={12}>{renderLogoSettings()}</Grid>
                <Grid item xs={12}>{renderLogoPositionAlerts()}</Grid>
            </Grid>
        </>
    )
}