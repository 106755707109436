import React from "react"
// MUI
import {Grid} from "@mui/material";
// Misc
import GeneratorPreviewSection from "./GeneratorPreviewSection/GeneratorPreviewSection";
import GeneratorContentSection from "./GeneratorContentSection/GeneratorContentSection";
import GeneratorCustomisationSection from "./GeneratorOptionsSection/GeneratorCustomisationSection";

export default function QRCodeGenerator(props: any) {

    const {
        usedModule,
        // standard data
        qrcode, setQRCode,
        preview, previewWarning,
        shortUrlPrefix,
        // submission management
        submitted,
        onSubmit, onUnsubmit,
        // creation management
        onCreate,
        // logo management
        logo, setLogo,
        // dynamic-pro
        domains, qrcodeTemplates,
        // misc
        t
    } = props

    let canCreateQRCode = Boolean(!!qrcode.data.label && !!qrcode.data.url && submitted)
    let gs1 = usedModule === "GS1"

    return (
        <Grid container justifyContent={"center"} spacing={2} sx={{ mt: 1 }}>
            <GeneratorContentSection {...{
                gs1,
                qrcode, setQRCode,
                shortUrlPrefix,
                submitted, onSubmit, onUnsubmit,
                logo,
                domains,
                t
            }} />
            <GeneratorPreviewSection {...{
                gs1,
                qrcode, setQRCode,
                usedModule,
                onCreate, canCreateQRCode,
                logo, setLogo,
                preview, previewWarning,
                t
            }} />
            <GeneratorCustomisationSection {...{
                gs1,
                qrcode, setQRCode,
                onSubmit, submitted,
                logo, setLogo,
                qrcodeTemplates,
                t
            }}
            />
        </Grid>
    )
}