import React from "react"
import {ThemeProvider} from "@mui/material";
import {ThemeUnitag} from "@/theme/theme"
// Primereact
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

export default function PackagingUiProvider(props: any) {
    return (
        <ThemeProvider theme={ThemeUnitag}>
            {props.children}
        </ThemeProvider>
    )
}