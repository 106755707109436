import React from "react"
import {Box, Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function NotFound() {

    const {t} = useTranslation(["common"])

    return (
        <Container maxWidth={false}>
            <Box sx={{
                width: "100%",
                height: "80vh",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                verticalAlign: "center"
            }}>
                <Box sx={{ margin: "auto", width: 200, textAlign: "center" }}>
                    <Typography variant={"h3"} fontWeight={700} gutterBottom>{t("error_404")}</Typography>
                    <Typography>{t("error_404_text")}</Typography>
                </Box>
            </Box>
        </Container>
    )
}