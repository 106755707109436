import React, {useEffect} from "react"
// MUI
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Typography,
    Box,
    IconButton,
    FormControl, InputLabel, Select, MenuItem
} from "@mui/material";
import {Close, Download} from "@mui/icons-material";
// Translation
import {useTranslation} from "react-i18next";
// API
import {useData} from "@/context/PackagingDataProvider";


export default function DownloadSpreadsheetDialog(props: any) {

    const {open, onClose} = props
    const {brands, getBrands, ranges, getRanges, downloadSpreadsheet} = useData()

    const {t} = useTranslation(["packaging", "common"])
    // Filters
    const [batchType, setBatchType] = React.useState<string>("all")
    const [selectedBrands, setSelectedBrands] = React.useState<any>([])
    const [selectedRanges, setSelectedRanges] = React.useState<any>([])

    useEffect(() => {
        if (!brands) getBrands()
        if (!ranges) getRanges()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const downloadBatch = () => {
        let filters = {
            brands: selectedBrands,
            ranges: selectedRanges
        }
        downloadSpreadsheet(filters)
    }

    const renderTypeSelector = () => {

        const handleChangeType = (type: string) => {
            setBatchType(type)
            setSelectedRanges([])
            setSelectedBrands([])
        }

        return (
            <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id={"typeSelector"}>{t("batch_type")}</InputLabel>
                <Select
                    label={t("batch_type")}
                    labelId={"typeSelector"}
                    value={batchType}
                    onChange={(e: any) => handleChangeType(e.target.value)}
                >
                    <MenuItem value={"all"}>{t("all_assets")}</MenuItem>
                    <MenuItem value={"brands"}>{t("filter_by_brand")}</MenuItem>
                    <MenuItem value={"ranges"}>{t("filter_by_range")}</MenuItem>
                </Select>
            </FormControl>
        )
    }

    const renderRanges = () => {
        if (ranges) {
            return (
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id={"range"}>
                        {t("filter_by_range")}
                    </InputLabel>
                    <Select
                        label={t("filter_by_range")}
                        labelId={"range"}
                        value={selectedRanges}
                        onChange={(e) => {setSelectedRanges(e.target.value)}}
                        multiple
                        disabled={selectedBrands.length > 0}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 200,
                                },
                            },
                        }}
                    >
                        {
                            ranges && ranges.map((range: any, index: number) => (
                                <MenuItem value={range.id} key={index}>{range.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            )
        }
        return
    }

    const renderBrands = () => {
        if (brands) {
            return (
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id={"brand"}>
                        {t("filter_by_brand")}
                    </InputLabel>
                    <Select
                        label={t("filter_by_brand")}
                        labelId={"brand"}
                        value={selectedBrands}
                        onChange={(e) => {setSelectedBrands(e.target.value)}}
                        multiple
                        disabled={selectedRanges.length > 0}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 200,
                                },
                            },
                        }}
                    >
                        {
                            brands && brands.map((brand: any, index: number) => (
                                <MenuItem value={brand.id} key={index}>{brand.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            )
        }
        return
    }

    return (
        <Dialog
            open={open}
            maxWidth={"sm"}
            fullWidth
            onClose={onClose}
            PaperProps={{
                sx: { position: "absolute", top: 80 }
            }}
        >
            <DialogTitle>
                <Box display={"flex"}>
                    <Box flexGrow={1}>
                        {t("download_packaging_spreadsheet")}
                    </Box>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography gutterBottom>
                    {t("download_packaging_spreadsheet_intro")}
                </Typography>

                {renderTypeSelector()}

                {
                    batchType === "brands" ? renderBrands() :
                    batchType === "ranges" ? renderRanges() :
                    null
                }

                <Box sx={{ mt: 1, display: batchType === "all" ? "none" : "block" }}>
                    <Button
                        className={"defaultButton"}
                        size={"small"}
                        variant={"outlined"}
                        onClick={() => { setSelectedBrands([]); setSelectedRanges([]); setBatchType("all") }}
                    >
                        {t("clear_filters")}
                    </Button>
                </Box>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 3}}>
                <Button className={"defaultButton"} onClick={onClose}>
                    {t("back")}
                </Button>
                <Button variant={"contained"} disableElevation startIcon={<Download />} onClick={() => downloadBatch()}>
                    {t("download_spreadsheet")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}