import React from "react"
import {
    Autocomplete,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Paper,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {dataAttributes, primaryKeys} from "@/utils";
import {Add, Delete} from "@mui/icons-material";

export default function ProductsGS1Form(props: any) {

    const {usedModule, GS1Product, setGS1Product, handleGS1LookUp, verifiedGtin, t} = props

    // primaryKeys
    const renderPrimaryKeySelector = () => {
        if (!primaryKeys) return <></>
        return (
            <>
                <Grid item xs={12}>
                    <Typography variant={"body1"} fontWeight={600}>
                        {t("products:primary_key")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        value={GS1Product.primaryKey}
                        onChange={(e, value: any) => setGS1Product({...GS1Product, primaryKey: !!value ? value.key : "" })}
                        renderInput={(params) => (<TextField {...params} label={t("products:gs1_primary_key")} />)}
                        options={primaryKeys}
                        noOptionsText={t("products:no_options")}
                        getOptionLabel={(option) => {
                            if (!option) return ""
                            if (typeof option === "string") {
                                let selectedPrimaryKey = primaryKeys.filter((key: any) => key.key === option)
                                if (!!selectedPrimaryKey) return selectedPrimaryKey[0].label
                                else return ""
                            }
                            return option.label
                        }}
                        renderOption={(props, option) => (<Box component={"li"} {...props}>{option.label}</Box>)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        size={"small"}
                        label={t("products:value")}
                        value={GS1Product.primaryKeyValue}
                        onChange={(e) => setGS1Product({...GS1Product, primaryKeyValue: e.target.value})}
                        fullWidth
                    />
                </Grid>
            </>
        )
    }

    // keyQualifiers
    const renderKeyQualifiersSelector = () => {

        const renderKeyQualifiersOptions = () => {

            const handleChangeKeyQualifiers = (key: any, value: any) => {

                let keyQualifiers = GS1Product.key_identifiers
                let keyQualifier = keyQualifiers.filter((kq: any) => kq.key === key)

                if (!!keyQualifier[0]) {
                    let index = keyQualifiers.findIndex((kq: any) => kq.key === key)
                    keyQualifier[0] = { key: key, value: value }
                    keyQualifiers[index] = keyQualifier[0]
                } else {
                    keyQualifiers.push({ key: key, value: value })
                }

                setGS1Product({...GS1Product, key_identifiers: keyQualifiers})
            }

            const returnKeyQualifierValue = (key: any) => {
                let keyQualifiers = GS1Product.key_identifiers
                let keyQualifier = keyQualifiers.filter((kq: any) => kq.key === key)

                if (!!keyQualifier[0]) return keyQualifier[0].value
                else return ""
            }

            const KQType0 = () => {
                return (
                    <Grid item xs={12}>
                        <Typography>{t("products:no_key_qualifiers")}</Typography>
                    </Grid>
                )
            }

            const KQTypeGTIN = () => {
                return (
                    <>
                        <Grid item xs={12}>
                            <TextField
                                size={"small"}
                                label={t("products:22")}
                                value={returnKeyQualifierValue("22")}
                                onChange={(e) => handleChangeKeyQualifiers("22", e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                size={"small"}
                                label={t("products:10")}
                                value={returnKeyQualifierValue("10")}
                                onChange={(e) => handleChangeKeyQualifiers("10", e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                size={"small"}
                                label={t("products:21")}
                                value={returnKeyQualifierValue("21")}
                                onChange={(e) => handleChangeKeyQualifiers("21", e.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </>
                )
            }

            const KQType254 = () => {
                return (
                    <Grid item xs={12}>
                        <TextField
                            size={"small"}
                            label={t("products:254")}
                            value={returnKeyQualifierValue("254")}
                            onChange={(e) => handleChangeKeyQualifiers("254", e.target.value)}
                            fullWidth
                        />
                    </Grid>
                )
            }

            const KQType8011 = () => {
                return (
                    <Grid item xs={12}>
                        <TextField
                            size={"small"}
                            label={t("products:8011")}
                            value={returnKeyQualifierValue("8011")}
                            onChange={(e) => handleChangeKeyQualifiers("8011", e.target.value)}
                            fullWidth
                        />
                    </Grid>
                )
            }

            const KQType8019 = () => {
                return (
                    <Grid item xs={12}>
                        <TextField
                            size={"small"}
                            label={t("products:8019")}
                            value={returnKeyQualifierValue("8019")}
                            onChange={(e) => handleChangeKeyQualifiers("8019", e.target.value)}
                            fullWidth
                        />
                    </Grid>
                )
            }

            switch (GS1Product.primaryKey) {
                case "01":
                case "8006": return KQTypeGTIN()
                case "414": return KQType254()
                case "8010": return KQType8011()
                case "8017":
                case "8018": return KQType8019()
                default: return KQType0()
            }
        }

        return (
            <>
                <Grid item xs={12}>
                    <Typography variant={"body1"} fontWeight={600}>
                        {t("products:key_qualifiers")} {t("products:optional")}
                    </Typography>
                </Grid>
                {renderKeyQualifiersOptions()}
            </>
        )
    }

    // dataAttributes
    const renderDataAttributesSelector = () => {

        const addDataAttribute = () => {
            let _dataAttributes = [...GS1Product.dataAttributes]
            _dataAttributes.push({ key: "", value: "" })
            setGS1Product({...GS1Product, dataAttributes: _dataAttributes})
        }

        const deleteDataAttribute = (index: any) => {
            let _dataAttributes = [...GS1Product.dataAttributes]
            _dataAttributes.splice(index, 1)
            setGS1Product({...GS1Product, dataAttributes: _dataAttributes})
        }

        const onChangeDataAttributeKey = (dataAttribute: any, index: number) => {
            let _dataAttributes = [...GS1Product.dataAttributes]
            _dataAttributes[index] = { key: !!dataAttribute ? dataAttribute.key : "", value: "" }
            setGS1Product({...GS1Product, dataAttributes: _dataAttributes})
        }

        const onChangeDataAttributeValue = (value: any, index: number) => {
            let _dataAttributes = [...GS1Product.dataAttributes]
            _dataAttributes[index] = { key: _dataAttributes[index].key, value: value }
            setGS1Product({...GS1Product, dataAttributes: _dataAttributes})
        }

        const renderDataAttributeForm = (dataAttributeObject: any, index: number) => {
            return (
                <>
                    <Grid item xs={12}>
                        <Autocomplete
                            size={"small"}
                            ListboxProps={{
                                sx: {
                                    maxHeight: 300,
                                    "& li": {
                                        padding: "2px",
                                        fontSize: "15px"
                                    }
                                }
                            }}
                            value={GS1Product.dataAttributes[index].key || ""}
                            onChange={(e, value: any) => onChangeDataAttributeKey(value, index)}
                            renderInput={(params) => (<TextField {...params} label={t("products:gs1_data_attribute")} />)}
                            options={dataAttributes}
                            noOptionsText={t("products:no_options")}
                            getOptionLabel={(option) => {
                                if (!option) return ""
                                if (typeof option === "string") {
                                    let selectedDataAttribute = dataAttributes.filter((key: any) => key.key === option)
                                    if (!!selectedDataAttribute) return selectedDataAttribute[0].label
                                    else return ""
                                }
                                return option.label
                            }}
                            renderOption={(props, option) => (<Box component={"li"} {...props}>{option.label}</Box>)}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            size={"small"}
                            label={t("products:value")}
                            value={GS1Product.dataAttributes[index].value || ""}
                            onChange={(e) => onChangeDataAttributeValue(e.target.value, index)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={1} sx={{ textAlign: "center" }}>
                        <Tooltip  title={t("products:delete")} placement={"top"} arrow>
                            <span>
                                <IconButton onClick={() => deleteDataAttribute(index)} disabled={GS1Product.dataAttributes.length === 1}>
                                    <Delete />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                </>
            )
        }

        if (!dataAttributes) return <></>

        return (
            <>
                <Grid item xs={12}>
                    <Typography variant={"body1"} fontWeight={600}>
                        {t("products:data_attributes")} {t("products:optional")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                        <Button
                            size={"small"}
                            color={"secondary"}
                            variant={"contained"}
                            disableElevation
                            startIcon={<Add />}
                            onClick={() => addDataAttribute()}
                            disabled={GS1Product.dataAttributes.length === 5}
                        >
                            {t("products:add_data_attribute")}
                        </Button>
                        <Typography
                            sx={{ pt: "4px", color: GS1Product.dataAttributes.length === 5 ? "red" : "initial" }}
                            variant={"body2"}
                        >
                            <i>{t("products:data_attributes_number")} {GS1Product.dataAttributes.length || 0}/5</i>
                        </Typography>
                    </Stack>
                </Grid>
                {!!GS1Product.dataAttributes && GS1Product.dataAttributes.map((da: any, index: number) => {
                    return (
                        <Grid item xs={12} key={index}>
                            <Paper sx={{ p: 2, backgroundColor: "#f6f6f6" }} variant={"outlined"}>
                                <Grid container justifyContent={"space-between"} rowSpacing={2}>
                                    {renderDataAttributeForm(da, index)}
                                </Grid>
                            </Paper>
                        </Grid>
                    )
                })}
            </>
        )
    }

    // action button
    const renderLookUp = () => {
        return (
            <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button
                    variant={verifiedGtin ? "outlined" : "contained"}
                    color={"primary"}
                    sx={{ px: 10 }}
                    onClick={handleGS1LookUp}
                    disabled={!GS1Product.primaryKeyValue}
                >
                    {!GS1Product.primaryKeyValue ? t("products:enter_primary_key_value") : t("products:look_up")}
                </Button>
            </Grid>
        )
    }

    if (!usedModule || usedModule !== "GS1") return <></>

    return (
        <>
            {renderPrimaryKeySelector()}
            <Grid item xs={12}><Divider sx={{ mt: 2 }} /></Grid>
            {renderKeyQualifiersSelector()}
            <Grid item xs={12}><Divider sx={{ mt: 2 }} /></Grid>
            {renderDataAttributesSelector()}
            <Grid item xs={12}><Divider sx={{ mt: 2 }} /></Grid>
            {renderLookUp()}
        </>
    )
}