import React from "react"
// Templates
// @ts-ignore
import TemplateClassic from '@/assets/generator/templates/template_classic.png';
// @ts-ignore
import TemplateFacebook from '@/assets/generator/templates/template_facebook.png';
// @ts-ignore
import TemplateTwitter from '@/assets/generator/templates/template_twitter.png';
// @ts-ignore
import TemplatePinterest from '@/assets/generator/templates/template_pinterest.png';
// @ts-ignore
import TemplateWikipedia from '@/assets/generator/templates/template_wikipedia.png';
// @ts-ignore
import TemplateLinkedin from '@/assets/generator/templates/template_linkedin.png';
// @ts-ignore
import TemplateViadeo from '@/assets/generator/templates/template_leaf.png';
// @ts-ignore
import TemplatePlaystore from '@/assets/generator/templates/template_playstore.png';
// @ts-ignore
import TemplateInstagram from '@/assets/generator/templates/template_instagram.png';
// @ts-ignore
import TemplateYoutube from '@/assets/generator/templates/template_youtube.png';
// @ts-ignore
import TemplateMaps from '@/assets/generator/templates/template_maps.png';
// @ts-ignore
import TemplateWifi from '@/assets/generator/templates/template_wifi.png';
import {
    // Box,
    ButtonBase,
    Grid,
    // Typography
} from "@mui/material";
// import {Add} from "@mui/icons-material";

export default function GeneratorTemplatesPanel(props: any) {

    const {
        qrcode, setQRCode,
        logo, setLogo,
        qrcodeTemplates,
        // t
    } = props

    const TemplateButton = (props: any) => {

        const styles = {
            button: {
                border: "1px solid transparent",
                transition: "0.2s ease-in-out",
                "&:hover": {
                    borderColor: "grey!important"
                }
            }
        }

        return (
            <ButtonBase id={props.name} sx={styles.button} onClick={handleTemplateClick}>
                <img
                    src={props.template}
                    height={150} width={150}
                    alt={`${props.name} QR Code template`}
                    draggable={false}
                />
            </ButtonBase>
        )
    }

    const templatesList = [
        { name: "Classic", image: TemplateClassic },
        { name: "Facebook", image: TemplateFacebook },
        { name: "Twitter", image: TemplateTwitter },
        { name: "Pinterest", image: TemplatePinterest },
        { name: "Wikipedia", image: TemplateWikipedia },
        { name: "Linkedin", image: TemplateLinkedin },
        { name: "Viadeo", image: TemplateViadeo },
        { name: "Playstore", image: TemplatePlaystore },
        { name: "Instagram", image: TemplateInstagram },
        { name: "Youtube", image: TemplateYoutube },
        { name: "Maps", image: TemplateMaps },
        { name: "Wifi", image: TemplateWifi }
    ]

    const handleTemplateClick = (event: React.MouseEvent<HTMLElement>) => {

        let _qrcode = {...qrcode};

        setLogo({
            ...logo,
            x: 0,
            y: 0,
            startX: 0,
            startY: 0,
            width: 70,
            height: 70,
            position: "auto",
            file: null,
        })

        _qrcode.settings.logo = {
            excavate: false,
            x: 0,
            y: 0,
            x_norm: 0,
            y_norm: 0,
            width: 70,
            height: 70,
            url: '',
            asset_uuid: ''
        }

        _qrcode.settings.eyes.color_ext_top_left = "";
        _qrcode.settings.eyes.color_ext_top_right = "";
        _qrcode.settings.eyes.color_ext_bottom_left = "";
        _qrcode.settings.eyes.color_int_top_right = "";
        _qrcode.settings.eyes.color_int_top_left = "";
        _qrcode.settings.eyes.color_int_bottom_left = ""

        _qrcode.settings.template_id = ""

        switch (event.currentTarget.id) {
            case 'Classic':
                _qrcode.settings.logo.url = "";
                _qrcode.settings.logo.excavate = false;
                _qrcode.settings.eyes.type = "simple";
                _qrcode.settings.modules.type = "square";
                _qrcode.settings.layout.type = "single_color";
                _qrcode.settings.layout.color_background = "#ffffff";
                _qrcode.settings.layout.color_one = "#000"
                break;
            case 'Facebook':
                _qrcode.settings.logo.url = "https://cdn-public.unitag.io/img/facebook_logo.png";
                _qrcode.settings.logo.excavate = false;
                _qrcode.settings.modules.type = "dots";
                _qrcode.settings.eyes.type = "rounded";
                _qrcode.settings.layout.type = "single_color";
                _qrcode.settings.layout.color_background = "#ffffff";
                _qrcode.settings.redundancy = 'H';
                _qrcode.settings.layout.color_one = "#3d5b96"
                break;
            case 'Twitter':
                _qrcode.settings.logo.url = "https://cdn-public.unitag.io/img/twitter_logo.png";
                _qrcode.settings.logo.excavate = false;
                _qrcode.settings.modules.type = "dots";
                _qrcode.settings.eyes.type = "rounded";
                _qrcode.settings.layout.type = "single_color";
                _qrcode.settings.layout.color_background = "#ffffff";
                _qrcode.settings.redundancy = 'H';
                _qrcode.settings.layout.color_one = "#000000"
                break;
            case 'Pinterest':
                _qrcode.settings.logo.url = "https://cdn-public.unitag.io/img/pinterest_logo.jpg";
                _qrcode.settings.logo.excavate = false;
                _qrcode.settings.modules.type = "dots";
                _qrcode.settings.eyes.type = "rounded";
                _qrcode.settings.layout.type = "single_color";
                _qrcode.settings.layout.color_background = "#ffffff";
                _qrcode.settings.redundancy = 'H';
                _qrcode.settings.layout.color_one = "#cd3039"
                break;
            case 'Wikipedia':
                _qrcode.settings.logo.url = "https://cdn-public.unitag.io/img/wikipedia_logo.jpg";
                _qrcode.settings.logo.excavate = false;
                _qrcode.settings.modules.type = "dots";
                _qrcode.settings.eyes.type = "rounded";
                _qrcode.settings.layout.type = "single_color";
                _qrcode.settings.layout.color_background = "#ffffff";
                _qrcode.settings.redundancy = 'H';
                _qrcode.settings.layout.color_one = "#070707"
                break;
            case 'Wifi':
                _qrcode.settings.logo.url = "https://cdn-public.unitag.io/img/wifi_logo.png";
                _qrcode.settings.logo.excavate = false
                _qrcode.settings.modules.type = "dots";
                _qrcode.settings.eyes.type = "rounded";
                _qrcode.settings.layout.type = "single_color";
                _qrcode.settings.layout.color_background = "#ffffff";
                _qrcode.settings.redundancy = 'H';
                _qrcode.settings.layout.color_one = "#070707"
                break;
            case 'Instagram':
                _qrcode.settings.logo.url = "https://cdn-public.unitag.io/img/instagram_logo.png";
                _qrcode.settings.logo.excavate = false;
                _qrcode.settings.modules.type = "dots";
                _qrcode.settings.eyes.type = "rounded";
                _qrcode.settings.layout.type = "gradient";
                _qrcode.settings.layout.gradient_type = "vertical"
                _qrcode.settings.layout.color_background = "#ffffff";
                _qrcode.settings.eyes.color_ext_top_left = "#696969";
                _qrcode.settings.eyes.color_ext_top_right = "#696969";
                _qrcode.settings.eyes.color_ext_bottom_left = "#696969";
                _qrcode.settings.redundancy = 'H';
                _qrcode.settings.layout.color_one = "#E95E2F"
                _qrcode.settings.layout.color_two = "#8080D9"
                break;
            case 'Linkedin':
                _qrcode.settings.logo.url = "https://cdn-public.unitag.io/img/linkedin_logo.jpg";
                _qrcode.settings.logo.excavate = false;
                _qrcode.settings.modules.type = "dots";
                _qrcode.settings.eyes.type = "rounded";
                _qrcode.settings.layout.type = "single_color";
                _qrcode.settings.layout.color_background = "#ffffff";
                _qrcode.settings.redundancy = 'H';
                _qrcode.settings.layout.color_one = "#1681b1"
                break;
            case "Youtube":
                _qrcode.settings.logo.url = "https://cdn-public.unitag.io/img/youtube_logo.png";
                _qrcode.settings.logo.excavate = false;
                _qrcode.settings.modules.type = "dots";
                _qrcode.settings.eyes.type = "rounded";
                _qrcode.settings.layout.type = "single_color";
                _qrcode.settings.layout.color_background = "#ffffff";
                _qrcode.settings.eyes.color_ext_top_left = "#414141";
                _qrcode.settings.eyes.color_ext_top_right = "#414141";
                _qrcode.settings.eyes.color_ext_bottom_left = "#414141";
                _qrcode.settings.eyes.color_int_top_right = "#414141";
                _qrcode.settings.eyes.color_int_top_left = "#414141";
                _qrcode.settings.eyes.color_int_bottom_left = "#414141"
                _qrcode.settings.redundancy = 'H';
                _qrcode.settings.layout.color_one = "#b31217"
                break;
            case "Maps":
                _qrcode.settings.logo.url = "https://cdn-public.unitag.io/img/maps_logo.png";
                _qrcode.settings.logo.excavate = false;
                _qrcode.settings.modules.type = "dots";
                _qrcode.settings.eyes.type = "rounded";
                _qrcode.settings.layout.type = "single_color";
                _qrcode.settings.layout.color_background = "#ffffff";
                _qrcode.settings.eyes.color_ext_top_left = "#434343";
                _qrcode.settings.eyes.color_ext_top_right = "#434343";
                _qrcode.settings.eyes.color_ext_bottom_left = "#434343";
                _qrcode.settings.eyes.color_int_top_right = "#d82323";
                _qrcode.settings.eyes.color_int_top_left = "#d82323";
                _qrcode.settings.eyes.color_int_bottom_left = "#d82323"
                _qrcode.settings.redundancy = 'H';
                _qrcode.settings.layout.color_one = "#7f7f7f"
                break;
            case 'Viadeo':
                _qrcode.settings.logo.url = "https://cdn-public.unitag.io/img/viadeo_logo.jpg";
                _qrcode.settings.logo.excavate = false;
                _qrcode.settings.modules.type = "dots";
                _qrcode.settings.eyes.type = "rounded";
                _qrcode.settings.layout.type = "single_color";
                _qrcode.settings.layout.color_background = "#ffffff";
                _qrcode.settings.redundancy = 'H';
                _qrcode.settings.layout.color_one = "#df8644"
                break;
            case 'Playstore':
                _qrcode.settings.logo.url = "https://cdn-public.unitag.io/img/playstore_logo.png";
                _qrcode.settings.logo.excavate = false;
                _qrcode.settings.eyes.type = "rounded";
                _qrcode.settings.modules.type = "dots";
                _qrcode.settings.layout.type = "gradient";
                _qrcode.settings.layout.gradient_type = "diagonal"
                _qrcode.settings.redundancy = 'H';
                _qrcode.settings.layout.color_one = "#2c9fd9"
                _qrcode.settings.layout.color_two = "#29cc5e"
                break;
            default:
                if (qrcodeTemplates !== null) {
                    for (const tmpl of qrcodeTemplates) {
                        if (tmpl.uuid === event.currentTarget.id) {
                            _qrcode.settings = JSON.parse(JSON.stringify(tmpl.design.Design));
                            if (tmpl.uuid.length > 32) _qrcode.settings.template_id = event.currentTarget.id
                            else _qrcode.settings.template_id = ''
                            break;
                        }
                    }
                }
        }

        setQRCode(_qrcode);
    }

    // const renderAddTemplateButton = () => {
    //
    //     const handleAddTemplate = () => {
    //
    //     }
    //
    //     return (
    //         <Grid item xs={10} sm={4} md={3} lg={3} xl={2} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
    //             <Box sx={{
    //                 width: "100%",
    //                 height: "100%",
    //                 justifyContent: "center",
    //                 alignItems: "center",
    //                 display: "flex"
    //             }}>
    //                 <ButtonBase
    //                     sx={{
    //                         backgroundColor: "#efefef",
    //                         height: 150, width: 150,
    //                         display: "flex",
    //                         flexDirection: "column",
    //                         alignItems: "center",
    //                         justifyContent: "center",
    //                         transition: "0.2s ease-in-out",
    //                         border: "1px solid transparent",
    //                         "&:hover": {
    //                             borderColor: "grey!important"
    //                         }
    //                     }}
    //                     onClick={handleAddTemplate}
    //                 >
    //                     <Typography variant={"body2"}>
    //                         {t("create_template_from_qr")}
    //                     </Typography>
    //                     <Add />
    //                 </ButtonBase>
    //             </Box>
    //         </Grid>
    //     )
    // }

    const renderPresetsList = () => {
        return (
            <>
                {
                    templatesList.map((template: any, index: any) => {
                        return (
                            <Grid item xs={10} sm={4} md={3} lg={3} xl={2} key={index} textAlign={"center"}>
                                <TemplateButton template={template.image} name={template.name} />
                            </Grid>
                        )
                    })
                }
            </>
        )
    }

    const renderTemplatesList = () => {
        if (!qrcodeTemplates || qrcodeTemplates.length <1) return <></>
        return (
            <>
                {
                    qrcodeTemplates.map((template: any, index: any) => {
                        return (
                            <Grid item xs={10} sm={4} md={3} lg={3} xl={2} key={index} textAlign={"center"}>
                                <TemplateButton template={template.imgURLObject} name={template.uuid} />
                            </Grid>
                        )
                    })
                }
            </>
        )
    }



    return (
        <>
            <Grid container justifyContent="center">
                {/*{renderAddTemplateButton()}*/}
                {renderTemplatesList()}
                {renderPresetsList()}
            </Grid></>
    )
}