import React, {useState} from "react"
import {Accordion, GeneratorColorPicker} from "@/components/UI";
import {Alert, Button, Grid, Stack, Switch, Typography} from "@mui/material";

export default function GeneratorOptionsPanel(props: any) {

    const {qrcode, setQRCode, t} = props

    const [optionAccordion, setOptionAccordion] = useState<number | null>(0)

    const renderShadowSection = () => {

        const changeShadowIntensity = (e: any) => {
            let _qrcode = {...qrcode};
            if (_qrcode.settings.layout.color_background !== "#ffffff" && _qrcode.settings.layout.color_background !== "#transparent") {
                _qrcode.settings.layout.color_background = "#ffffff"
            }
            _qrcode.settings.layout.force_shadow = e.target.value;
            if (e.target.value === "") _qrcode.settings.layout.color_shadow = "#bdbdbd"
            setQRCode(_qrcode);
        }

        const changeShadowColor = (color: string) => {
            if (color !== qrcode.settings.layout.color_shadow) {
                let _qrcode = {...qrcode};
                if (_qrcode.settings.layout.color_background !== "#ffffff" && _qrcode.settings.layout.color_background !== "#transparent") {
                    _qrcode.settings.layout.color_background = "#ffffff"
                }
                _qrcode.settings.layout.color_shadow = color;
                setQRCode(_qrcode);
            }
        }

        return (
            <Grid container spacing={{ xs: 3, md: 1, lg: 3 }} justifyContent="center" alignContent="stretch">
                <Grid
                    item xs={12}
                    sx={{
                        display:
                            (qrcode.settings.layout.color_background !== "#ffffff" &&
                            qrcode.settings.layout.color_background !== "#transparent") ? "block" : "none"
                    }}
                >
                    <Alert severity={"info"}>
                        {t("products:drop_shadow_info")}
                    </Alert>
                </Grid>
                <Grid item md={4}>
                    <Typography variant={"body1"} align={"center"}>{t("products:drop_shadow")}</Typography>
                    <Stack direction={"row"} spacing={2}>
                        <>
                            {[
                                { intensity: "L", label: "weak" },
                                { intensity: "M", label: "medium" },
                                { intensity: "S", label: "strong" },
                                { intensity: "", label: "none" },
                            ].map((shadow: any, index: any) => (
                                <Button
                                    key={index}
                                    value={shadow.intensity}
                                    onClick={changeShadowIntensity}
                                    disableElevation
                                    variant={qrcode.settings.layout.force_shadow === shadow.intensity ? "contained" : "text"}
                                    sx={{ color: qrcode.settings.layout.force_shadow === shadow.intensity ? "white!important" : "black!important"}}
                                >
                                    {t(`products:${shadow.label}`)}
                                </Button>
                            ))}

                        </>
                    </Stack>
                </Grid>
                <Grid item md={4}>
                    <Typography variant={"body1"} align={"center"}>{t("products:drop_shadow_color")}</Typography>
                    <GeneratorColorPicker
                        disabled={qrcode.settings.layout.force_shadow === ""}
                        initialColor={qrcode.settings.layout.color_shadow}
                        onChangeComplete={changeShadowColor}
                    />
                </Grid>
            </Grid>
        )
    }

    const renderRedundancySection = () => {

        const isRedundancyDisabled = (qrcode.settings.logo.asset_uuid || qrcode.settings.logo.url)

        const changeRedundancy = (e: any) => {
            let _qrcode = {...qrcode};
            _qrcode.settings.redundancy = e.target.value;
            setQRCode(_qrcode);
        }

        const returnRedundancyContent = () => {
            if (isRedundancyDisabled) {
                return (
                    <Typography variant={"body1"} align={"center"}>
                        {t("products:redundancy_disabled")}
                    </Typography>
                )
            } else {
                return (
                    <>
                        <Typography variant={"body1"} align={"center"}>
                            {t("products:redundancy")}
                        </Typography>
                        <Stack direction={"row"} spacing={2}>
                            <>
                                {[
                                    { intensity: "L", label: "weak" },
                                    { intensity: "M", label: "medium" },
                                    { intensity: "H", label: "strong" },
                                    { intensity: "Q", label: "quality" },
                                ].map((redundancy: any, index: any) => (
                                    <Button
                                        key={index}
                                        value={redundancy.intensity}
                                        onClick={changeRedundancy}
                                        disableElevation
                                        variant={qrcode.settings.redundancy === redundancy.intensity ? "contained" : "text"}
                                        sx={{ color: qrcode.settings.redundancy === redundancy.intensity ? "white!important" : "black!important"}}
                                    >
                                        {t(`products:${redundancy.label}`)}
                                    </Button>
                                ))}
                            </>
                        </Stack>
                    </>
                )
            }
        }

        return (
            <Grid container spacing={{ xs: 3, md: 1, lg: 3 }} justifyContent="center" alignContent="stretch">
                <Grid item md={4}>
                    {returnRedundancyContent()}
                </Grid>
            </Grid>
        )
    }

    const renderBackgroundSection = () => {

        const handleSwitchTransparency = () => {
            let _qrcode = {...qrcode};

            if (qrcode.settings.layout.color_background === "#transparent") _qrcode.settings.layout.color_background = "#ffffff"
            else _qrcode.settings.layout.color_background = "#transparent"

            setQRCode(_qrcode);
        }

        const changeBackgroundColor = (color: any) => {
            if (color !== qrcode.settings.layout.color_background) {
                let _qrcode = {...qrcode};
                _qrcode.settings.layout.force_shadow = ""
                _qrcode.settings.layout.color_background = color;
                setQRCode(_qrcode);
            }
        }

        return (
            <Grid container spacing={{ xs: 3, md: 1, lg: 3 }} justifyContent="center" alignContent="stretch">
                <Grid item xs={12} sx={{ display: qrcode.settings.layout.force_shadow !== "" ? "block" : "none" }}>
                    <Alert severity={"info"}>
                        {t("products:background_info")}
                    </Alert>
                </Grid>
                <Grid item md={4}>
                    <Typography variant={"body1"} align={"center"}>{t("products:transparency")}</Typography>
                    <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant={"body2"}>{t("products:color")}</Typography>
                        <Switch
                            checked={qrcode.settings.layout.color_background === "#transparent"}
                            onChange={handleSwitchTransparency}
                        />
                        <Typography variant={"body2"}>{t("products:transparent")}</Typography>
                    </Stack>
                </Grid>
                <Grid item md={4}>
                    <Typography variant={"body1"} align={"center"}>{t("products:background_color")}</Typography>
                    <GeneratorColorPicker
                        disabled={qrcode.settings.layout.color_background === "#transparent"}
                        initialColor={qrcode.settings.layout.color_background}
                        onChangeComplete={changeBackgroundColor}
                    />
                </Grid>
            </Grid>
        )
    }


    const options = [
        { label: "products:drop_shadow", content: renderShadowSection() },
        { label: "products:redundancy", content: renderRedundancySection() },
        { label: "products:background", content: renderBackgroundSection() },
    ]

    return (
        <>
            {
                options.map((option: any, index: number) => (
                    <Accordion
                        expanded={optionAccordion === index}
                        onChange={() => setOptionAccordion(index)}
                        title={t(option.label)}
                        key={index}
                    >
                        {option.content}
                    </Accordion>
                ))
            }
        </>
    )
}