import React from "react"
// MUI
import {
    Button,
    Grid,
    TextField,
    Typography,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText, Stack
} from "@mui/material";
import {Delete} from "@mui/icons-material";
// Components
import {DropzoneImage} from "@/components/UI";
// Data
import {useData} from "@/context/PackagingDataProvider";
// Assets
// @ts-ignore
import {ReactComponent as BrandsLogo} from "@/assets/svg/brands.svg"

export default function RangesEditor(props: any) {

    const {
        creating, editing,
        range, setRange,
        brands,
        onCreate, onEdit,
        t
    } = props
    const {handleDropImage} = useData()

    const styles = {
        editorGrid: { mb: 1 },
        logoImagePaper: { p: 2 },
        alignCenter: { textAlign: "center" },
    }

    const logoSection = () => {
        const handleImage = (dataDrop: any) => {
            handleDropImage(dataDrop).then((res: any) => {
                setRange({...range, logo: res.image, logo_id: res.uuid})
            })
        }

        if (range.logo_id) {
            return (
                <Paper variant={"outlined"} sx={styles.logoImagePaper}>
                    <Grid container alignItems={"center"} spacing={3} justifyContent={{ xs: 'center', md: 'space-between'}}>
                        <Grid item>
                            <img height='60px' src={"#"} alt="range-logo"/>
                        </Grid>
                        <Grid item>
                            <Button startIcon={<Delete />} onClick={() => setRange({...range, logo_id: ""})}>
                                {t("Remove_logo")}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            )
        } else return <DropzoneImage onDrop={handleImage} {...{t}} />

    }

    return (
        <>
            <Grid container spacing={3} sx={styles.editorGrid} justifyContent="center">
                <Grid item xs={12}>
                    <Paper variant={"outlined"} sx={{ backgroundColor: "#efefef", p: 2 }}>
                        <Stack direction={"row"}>
                            <BrandsLogo className={"preSelectIcon"} />
                            <FormControl fullWidth>
                                <InputLabel id={"brand-selector"}>{t("ranges:parent_brand")}</InputLabel>
                                <Select
                                    fullWidth
                                    label={t("ranges:parent_brand")}
                                    labelId={"brand-selector"}
                                    onChange={(e) => setRange({...range, brand: e.target.value})}
                                    value={range.brand}
                                    error={!range.brand}
                                >
                                    {!!brands && brands.map((brand: any) => {
                                        return (
                                            <MenuItem key={brand.id} value={brand.id}>{brand.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                                <FormHelperText sx={{ display: range.brand ? "none" : "flex" }}>{t("ranges:select_brand_mandatory")}</FormHelperText>
                            </FormControl>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1"><b>{t("ranges:range_name")}</b></Typography>
                    <TextField
                        error={range.name === ""}
                        helperText={range.name === "" ? t("ranges:range_name_empty_error") : ""}
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={range.name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setRange({...range, name: event.target.value})
                        }}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1"><b>{t("ranges:range_description")}</b></Typography>
                    <Typography variant="body2" gutterBottom>{t("ranges:range_description_label")}</Typography>
                    <TextField
                        size="small"
                        variant="outlined"
                        value={range.description}
                        multiline
                        rows={4}
                        fullWidth
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setRange({...range, description: event.target.value})
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        <b> {t("ranges:range_logo")} </b>
                    </Typography>
                    <Typography variant="body2" gutterBottom>{t("ranges:range_logo_label")}</Typography>

                    {logoSection()}
                </Grid>
                <Grid item xs={12} pt={2} sx={styles.alignCenter}>
                    <Button
                        onClick={creating ? onCreate : editing ? onEdit : () => {return}}
                        disabled={range.name === "" || !brands || !range.brand}
                        variant={"contained"}
                        disableElevation
                    >
                        {creating ? t("ranges:range_create") : editing ? t("ranges:range_edit") : t("common:loading")}
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}