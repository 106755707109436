import React, {createContext, useContext, useState, useEffect} from "react"
// API
import {APIGet} from "@api";
// JWT
import jwtDecode from "jwt-decode";
// Misc
import {enqueueSnackbar} from "notistack";
// Translation
import {useTranslation} from "react-i18next";
// Loading
import Loading from "@/components/Loading/Loading";

const {REACT_APP_API_URL, REACT_APP_DASHBOARD_URL} = process.env

const UserContext = createContext<any>(null)
export const useUser = () => useContext(UserContext)

export default function UserProvider({children}: any) {

    const {t} = useTranslation(["common"])

    const [loading, setLoading] = useState<boolean>(true)
    // token
    const [token, setToken] = useState<any>("")
    const [parsedToken, setParsedToken] = useState<any>(null)
    // data
    const [user, setUser] = useState<any>(null)
    const [fullOrganisation, setFullOrganisation] = useState<any>(null)
    const [organisation, setOrganisation] = useState<any>(null)
    const [orgMembers, setOrganisationMembers] = useState<any>(null)
    const [credits, setCredits] = useState<any>(null)
    const [gs1Credits, setGs1Credits] = useState<any>(null)

    const [canCreate, setCanCreate] = useState<boolean>(false)

    const userRoles = {
        admin: "ADMIN",
        owner: "OWNER",
        member: "MEMBER"
    }

    // Error hooks
    const handleError = (message: string) => enqueueSnackbar(message, { variant: "error" })

    useEffect(() => {
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (token !== "") {
            getUser()
            getOrganisation()
            setParsedToken(jwtDecode(token))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    useEffect(() => {
        if (!!parsedToken) {
            // convert dates to unix timestamp format
            let unixStartDate = Date.now() / 1000
            let unixDuration = parsedToken.exp - unixStartDate
            // convert total unix time to vanilla
            let millisecondsDuration = unixDuration * 1000
            setTimeout(refreshToken, millisecondsDuration + 10)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsedToken])

    useEffect(() => {
        if (organisation && organisation.organisation_id) getOrgMembers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation])

    useEffect(() => {
        if (!!user && !!token && loading) setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, token])

    // Token management
    const refreshToken = () => {
        // Refresh message from dashboard
        window.top?.postMessage("refreshToken", `${REACT_APP_DASHBOARD_URL}`)
        // Treat message from dashboard
        window.addEventListener("message", messageHandler)
    }

    const seeAnalyticsForProducts = (products: any) => {
        let payload = JSON.stringify(products)
        window.top?.postMessage(payload, `${REACT_APP_DASHBOARD_URL}`)
    }

    const messageHandler = (event: any) => {
        // we only want messages from dashboard
        if (event.origin !== `${REACT_APP_DASHBOARD_URL}`) return
        if (typeof event.data === 'string' && !event.data.includes("range")) setToken(event.data)
    }

    const getUser = () => {
        if (!token) return
        APIGet(REACT_APP_API_URL + "/user", token).then((data) => {
            if (data.parsedBody) {
                setUser(data.parsedBody)
                // Trigger subsequent calls
                getOrganisation()
                getCredits()
            }
        }, () => handleError(t("error_get_user")))
    }

    const getFullOrganisation = () => {
        if (!token) return
        APIGet(REACT_APP_API_URL + "/orgs", token).then((data: any) => {
            if (data.parsedBody) { setFullOrganisation(data.parsedBody) }
        }, () => handleError(t("error_get_organisation")))
    }

    const getOrganisation = () => {
        if (!token) return
        APIGet(REACT_APP_API_URL + "/org", token).then((data: any) => {
            if (data.parsedBody) {
                setOrganisation(data.parsedBody)
                setCanCreate(data.parsedBody.can_user_consume_credits)
            }
        }, () => handleError(t("error_get_organisation")))
    }

    const getOrgMembers = () => {
        if (!token) return
        APIGet(REACT_APP_API_URL + "/org/"+ organisation.organisation_id + "/users", token).then((data: any) => {
            setOrganisationMembers(data.parsedBody)
        }, () => handleError(t("error_get_org_members")))
    }


    const getCredits = () => {
        if (!token) return
        APIGet(REACT_APP_API_URL + "/billing/credits", token).then((data: any) => {
            if (data.parsedBody) {
                const _credits = data.parsedBody.find((app: any) => app.service_name === "packaging")
                const _gs1 = data.parsedBody.find((app: any) => app.service_name === "packaging_gs1")
                setCredits(_credits)
                setGs1Credits(_gs1)
            }
        }, () => handleError(t("error_get_credits")))
    }

    if (loading) return <Loading type={"user-data"} />

    return (
        <UserContext.Provider
            value={{
                user, getUser,
                organisation, getOrganisation,
                fullOrganisation, getFullOrganisation,
                orgMembers, getOrgMembers,
                credits, gs1Credits, getCredits,
                userRoles,
                token,
                canCreate,
                // message handlers
                seeAnalyticsForProducts
            }}
        >
            {children}
        </UserContext.Provider>
    )
}