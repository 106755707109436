import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom";
// mui
import {
    Box,
    Button, CircularProgress,
    Divider,
    Grid, Paper,
    Typography
} from "@mui/material";
import {Refresh} from "@mui/icons-material";
// translation
import {useTranslation} from "react-i18next";
// data
import {useData} from "@/context/PackagingDataProvider";
// primereact
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

export default function HealthchecksTab() {

    const {t} = useTranslation(["healthchecks", "common"])
    const navigate = useNavigate()
    const {getHealthchecks} = useData()

    const [healthchecks, setHealthchecks] = useState<any[] | null>(null)
    const [selectedHC, setSelectedHC] = useState<any>(null)

    useEffect(() => {
        refreshHealthchecks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const refreshHealthchecks = () => {
        getHealthchecks().then((res: any) => {
            if (!!res) setHealthchecks(res.parsedBody)
            else setHealthchecks([])
        })
    }

    const renderHCDataTable = () => {

        const onSelect = (event: any) => {
            navigate(`/?tab=products&HCname=${event.data.product_name}&HCrange=${event.data.product_range}`)
        }

        const returnRowStyles = (rowData: any) => { return { "HWarning": rowData.level === "high"} }

        const renderHCName = (rowData: any) => { return rowData.product_name }

        const renderHCSeverity = (rowData: any) => { return t(rowData.level) }

        const renderHCStatus = (rowData: any) => {

            let status: number = Number(rowData.status)
            let severityMessage = "-"

            if (!!status) {
                if (status >= 300 && status < 400) severityMessage = "bad_url"
                else if (status >= 400 && status < 500) severityMessage = "url_weird"
                else if (status >= 500) severityMessage = "url_broken"
            }

            return t(severityMessage)
        }

        const renderHCURL = (rowData: any) => {
            return rowData.destination_urls_affected
        }

        return (
            <Paper variant={"outlined"} sx={{ borderRadius: 5, overflow: "hidden" }}>
                <DataTable
                    value={!!healthchecks ? healthchecks : []}
                    rowHover
                    emptyMessage={healthchecks !== null && t("everythings_fine")}
                    selection={selectedHC}
                    onSelectionChange={(e: any) => setSelectedHC(e.value)}
                    onRowSelect={onSelect}
                    selectionMode={"single"}
                    rowClassName={returnRowStyles}
                >
                    <Column header={t("label")} body={renderHCName} />
                    <Column header={t("severity")} body={renderHCSeverity} />
                    <Column header={t("status")} body={renderHCStatus} />
                    <Column header={t("destination_urls")} body={renderHCURL} />
                </DataTable>
            </Paper>
        )
    }

    const renderLoader = () => {
        return (
            <>
                <Box sx={{ margin: "auto", textAlign: "center", maxWidth: 300 }}>
                    <Typography>
                        {t("loading")}
                    </Typography>
                    <br/>
                    <CircularProgress />
                </Box>
            </>
        )
    }

    return (
        <>
            <Box sx={{ p: 2 }}>
                <Grid container justifyContent={{ xs: "flex-start" }} rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"h4"} component={"p"}>
                            {t("healthchecks")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}><Divider /></Grid>

                    <Grid item xs={12} md={8}>
                        <Button
                            color={"primary"}
                            variant={"contained"}
                            disableElevation
                            onClick={() => refreshHealthchecks()}
                            startIcon={<Refresh />}
                            disabled={healthchecks === null}
                        >
                            {t("refresh")}
                        </Button>
                    </Grid>

                    <Grid item xs={12}>{healthchecks === null ? renderLoader() : renderHCDataTable()}</Grid>
                </Grid>
            </Box>
        </>
    )
}