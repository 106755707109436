import React from "react"
// MUI
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export default function BrandRangeSelect(props: any) {

    const {data, value, setValue, label, type, small, error, t} = props

    return (
        <>
            <FormControl fullWidth size={small ? "small" : "medium"}>
                <InputLabel id={`${type}-selector`}>
                    {label}
                </InputLabel>
                <Select
                    size={small ? "small" : "medium"}
                    label={label}
                    labelId={`${type}-selector`}
                    id={`packaging-${type}-selector`}
                    value={value}
                    onChange={setValue}
                    error={error}
                >
                    <MenuItem value={""} disabled>{t("no_filter")}</MenuItem>
                    {
                        !!data && data.map((element: any, index: number) => (
                            <MenuItem value={element.id} key={index}>{element.name}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </>
    )
}