const primaryKeys: any[] = [
    { key: "00", label: "[00] Serial Shipping Container Code (SSCC)" },
    { key: "01", label: "[01] Global Trade Item Number (GTIN)" },
    { key: "253", label: "[253] Global Document Type Identifier (GDTI)" },
    { key: "255", label: "[255] Global Coupon Number (GCN)" },
    { key: "401", label: "[401] Global Identification Number for Consignment (GINC)" },
    { key: "402", label: "[402] Global Shipment Identification Number (GSIN)" },
    { key: "414", label: "[414] Identification of a physical location - Global Location Number" },
    { key: "415", label: "[415] Global Location Number of the invoicing party" },
    { key: "417", label: "[417] Party Global Location Number (PGLN)" },
    { key: "7240", label: "[7240] Protocol ID" },
    { key: "8003", label: "[8003] Global Returnable Asset Identifier (GRAI)" },
    { key: "8004", label: "[8004] Global Individual Asset Identifier (GIAI)" },
    { key: "8006", label: "[8006] Identification of an individual trade item piece" },
    { key: "8010", label: "[8010] Component/Part Identifier (CPID)" },
    { key: "8017", label: "[8017] Global Service Relation Number - Provider" },
    { key: "8018", label: "[8018] Global Service Relation Number - Recipient" },
]

export default primaryKeys