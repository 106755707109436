import React from "react"
// MUI
import {Box, CircularProgress, Container, Typography} from "@mui/material";
// Translation
import {useTranslation} from "react-i18next";

export default function Loading(props: any) {

    const {type} = props
    const {t} = useTranslation(["common"])

    return (
        <Container maxWidth={false}>
            <Box sx={{
                width: "100%",
                height: "80vh",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                verticalAlign: "center"
            }}>
                <Box sx={{ margin: "auto", width: 200, textAlign: "center" }}>
                    <CircularProgress sx={{ mb: 2 }}  />
                    <Typography fontWeight={700}>
                        {
                            type === "packaging-data" ? t("loading_packaging_data")
                            : type === "user-data" ? t("loading_user_data")
                            : t("loading")
                        }
                    </Typography>
                </Box>
            </Box>
        </Container>
    )
}