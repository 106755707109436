import React from "react"
// MUI
import {Paper, Typography, Box, Stack} from "@mui/material"

export default function PaperLabelled(props: any) {
    const {children, label, background, bold, sx, paperPadding, paperStyle, adornment, paperMaxHeight} = props

    const styles = {
        wrapper: {
            ...sx,
            position: "relative"
        },
        paper: {
            ...paperStyle,
            p: 0,
            position: "relative",
            backgroundColor: background ? background : "#fff",
            transition: "0.25s ease-in-out",
        },
        label: {
            position: "absolute",
            left: 15,
            top: -12,
            backgroundColor: "#fff",
            px: 1,
            borderRadius: 3,
            "&::before": {
                content: "''",
                position: "absolute",
                height: 15
            }
        }
    }

    return (
        <Box sx={styles.wrapper}>
            <Paper variant={"outlined"} sx={styles.paper}>
                <Stack direction={"row"} sx={styles.label} spacing={1}>
                    <Typography variant={"body2"}  fontWeight={bold ? 800 : 400}>
                        {label}
                    </Typography>
                    {adornment && <div>{adornment}</div>}
                </Stack>
                <Box className={"fancyScroll"} sx={{ overflowY: "auto", p: paperPadding ? paperPadding : 2, maxHeight: paperMaxHeight ? paperMaxHeight : 9999 }}>
                    {children}
                </Box>
            </Paper>
        </Box>
    )
}