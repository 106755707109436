import React, {useEffect} from "react"
// MUI
import {Box, FormControlLabel, Grid, Switch} from "@mui/material";
// UI
import {BrandMultipleSelect, PaperLabelled} from "@/components/UI";
// Data
import {useData} from "@/context/PackagingDataProvider";

export default function AppEditorOptionsTab(props: any) {

    const {app, setApp, styles, t} = props
    const {brands, getBrands} = useData()

    // Lifecycle
    useEffect(() => {
        if (!brands) getBrands()
    }, [brands, getBrands])

    // Render
    const renderGeneralOptions = () => {

        const handleChangeGeoIp = () => {
            setApp({...app, geo_ip_fallback: !app.geo_ip_fallback})
        }

        return (
            <PaperLabelled label={t("general_options")} bold>
                <FormControlLabel
                    label={t("geo_ip_fallback_label")}
                    control={
                        <Switch checked={app.geo_ip_fallback} onChange={() => handleChangeGeoIp()} />
                    }
                />
            </PaperLabelled>
        )
    }

    return (
        <Box sx={styles.paper}>
            <Grid container rowSpacing={3}>
                <Grid item xs={12} md={8} lg={7}>
                    {renderGeneralOptions()}
                </Grid>
                <Grid item xs={12} md={8} lg={7}>
                    <BrandMultipleSelect{...{app, setApp, brands, t}} />
                </Grid>
            </Grid>
        </Box>
    )
}